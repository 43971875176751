const itemTypes: String[] = ["Claws","Daggers","Wands","One Hand Swords","Thrusting One Hand Swords","One Hand Axes","One Hand Maces","Sceptres","Rune Daggers","Bows","Staves","Two Hand Swords","Two Hand Axes","Two Hand Maces","Warstaves","Fishing Rods","Amulets","Rings","Belts","Shields (str)","Shields (dex)","Shields (int)","Shields (str, dex)","Shields (str, int)","Shields (dex, int)","Quivers","Gloves (str)","Gloves (dex)","Gloves (int)","Gloves (str, dex)","Gloves (str, int)","Gloves (dex, int)","Boots (str)","Boots (dex)","Boots (int)","Boots (str, dex)","Boots (str, int)","Boots (dex, int)","Body Armours (str)","Body Armours (dex)","Body Armours (int)","Body Armours (str, dex)","Body Armours (str, int)","Body Armours (dex, int)","Helmets (str)","Helmets (dex)","Helmets (int)","Helmets (str, dex)","Helmets (str, int)","Helmets (dex, int)","Tinctures"];

export interface ItemAffixRegex {
  regex: string
  isPrefix: boolean
  name: string
  description: string
  ilevel: number
  family: string
}

export const problemBases : { [key: string]: string[] } = {
  "Claws": ["Awl","Blinder","Gouger","Great White Claw"],
  "Daggers": ["Stiletto","Poignard","Trisula","Ambusher","Sai","Skean"],
  "Wands": [],
  "One Hand Swords": ["Sabre","Cutlass","Baselard","Grappler","Gladius","Smallsword","Estoc","Pecoraro"],
  "Thrusting One Hand Swords": ["Smallsword","Estoc","Pecoraro"],
  "One Hand Axes": ["Cleaver","Tomahawk"],
  "One Hand Maces": ["Tenderizer","Gavel","Pernach"],
  "Sceptres": ["Sekhem"],
  "Rune Daggers": ["Skean"],
  "Bows": [],
  "Staves": ["Quarterstaff","Lathi"],
  "Two Hand Swords": ["Longsword"],
  "Two Hand Axes": ["Woodsplitter","Poleaxe","Labrys","Fleshripper"],
  "Two Hand Maces": ["Mallet","Sledgehammer","Steelhead","Piledriver","Meatgrinder","Blunt Force Condenser","Crushing Force Magnifier","Impact Force Propagator"],
  "Warstaves": [],
  "Fishing Rods": [],
  "Amulets": ["Blue Pearl Amulet"],
  "Rings": [],
  "Belts": [],
  "Shields (str)": ["Splintered Tower Shield","Corroded Tower Shield","Rawhide Tower Shield","Cedar Tower Shield","Copper Tower Shield","Reinforced Tower Shield","Painted Tower Shield","Buckskin Tower Shield","Mahogany Tower Shield","Bronze Tower Shield","Girded Tower Shield","Crested Tower Shield","Shagreen Tower Shield","Ebony Tower Shield","Ezomyte Tower Shield","Colossal Tower Shield","Pinnacle Tower Shield","Rotted Round Shield","Fir Round Shield","Studded Round Shield","Scarlet Round Shield","Splendid Round Shield","Maple Round Shield","Spiked Round Shield","Crimson Round Shield","Baroque Round Shield","Teak Round Shield","Spiny Round Shield","Cardinal Round Shield","Elegant Round Shield","Plank Kite Shield","Linden Kite Shield","Reinforced Kite Shield","Layered Kite Shield","Ceremonial Kite Shield","Etched Kite Shield","Steel Kite Shield","Laminated Kite Shield","Angelic Kite Shield","Branded Kite Shield","Champion Kite Shield","Mosaic Kite Shield","Archon Kite Shield","Exothermic Tower Shield","Magmatic Tower Shield","Heat-attuned Tower Shield"],
  "Shields (dex)": ["Rotted Round Shield","Fir Round Shield","Studded Round Shield","Scarlet Round Shield","Splendid Round Shield","Maple Round Shield","Spiked Round Shield","Crimson Round Shield","Baroque Round Shield","Teak Round Shield","Spiny Round Shield","Cardinal Round Shield","Elegant Round Shield","Driftwood Spiked Shield","Alloyed Spiked Shield","Burnished Spiked Shield","Ornate Spiked Shield","Redwood Spiked Shield","Compound Spiked Shield","Polished Spiked Shield","Sovereign Spiked Shield","Alder Spiked Shield","Ezomyte Spiked Shield","Mirrored Spiked Shield","Supreme Spiked Shield"],
  "Shields (int)": ["Twig Spirit Shield","Yew Spirit Shield","Bone Spirit Shield","Tarnished Spirit Shield","Jingling Spirit Shield","Brass Spirit Shield","Walnut Spirit Shield","Ivory Spirit Shield","Ancient Spirit Shield","Chiming Spirit Shield","Thorium Spirit Shield","Lacewood Spirit Shield","Fossilised Spirit Shield","Vaal Spirit Shield","Harmonic Spirit Shield","Titanium Spirit Shield","Plank Kite Shield","Linden Kite Shield","Reinforced Kite Shield","Layered Kite Shield","Ceremonial Kite Shield","Etched Kite Shield","Steel Kite Shield","Laminated Kite Shield","Angelic Kite Shield","Branded Kite Shield","Champion Kite Shield","Mosaic Kite Shield","Archon Kite Shield","Driftwood Spiked Shield","Alloyed Spiked Shield","Burnished Spiked Shield","Ornate Spiked Shield","Redwood Spiked Shield","Compound Spiked Shield","Polished Spiked Shield","Sovereign Spiked Shield","Alder Spiked Shield","Ezomyte Spiked Shield","Mirrored Spiked Shield","Supreme Spiked Shield","Exhausting Spirit Shield","Subsuming Spirit Shield","Transfer-attuned Spirit Shield"],
  "Shields (str, dex)": ["Rotted Round Shield","Fir Round Shield","Studded Round Shield","Scarlet Round Shield","Splendid Round Shield","Maple Round Shield","Spiked Round Shield","Crimson Round Shield","Baroque Round Shield","Teak Round Shield","Spiny Round Shield","Cardinal Round Shield","Elegant Round Shield"],
  "Shields (str, int)": ["Plank Kite Shield","Linden Kite Shield","Reinforced Kite Shield","Layered Kite Shield","Ceremonial Kite Shield","Etched Kite Shield","Steel Kite Shield","Laminated Kite Shield","Angelic Kite Shield","Branded Kite Shield","Champion Kite Shield","Mosaic Kite Shield","Archon Kite Shield"],
  "Shields (dex, int)": ["Driftwood Spiked Shield","Alloyed Spiked Shield","Burnished Spiked Shield","Ornate Spiked Shield","Redwood Spiked Shield","Compound Spiked Shield","Polished Spiked Shield","Sovereign Spiked Shield","Alder Spiked Shield","Ezomyte Spiked Shield","Mirrored Spiked Shield","Supreme Spiked Shield"],
  "Quivers": ["Serrated Arrow Quiver","Two-Point Arrow Quiver","Sharktooth Arrow Quiver","Blunt Arrow Quiver","Fire Arrow Quiver","Broadhead Arrow Quiver","Penetrating Arrow Quiver","Spike-Point Arrow Quiver","Feathered Arrow Quiver","Blazing Arrow Quiver","Vile Arrow Quiver","Heavy Arrow Quiver","Primal Arrow Quiver"],
  "Gloves (str)": [],
  "Gloves (dex)": [],
  "Gloves (int)": ["Fingerless Silk Gloves"],
  "Gloves (str, dex)": [],
  "Gloves (str, int)": [],
  "Gloves (dex, int)": [],
  "Boots (str)": [],
  "Boots (dex)": [],
  "Boots (int)": [],
  "Boots (str, dex)": [],
  "Boots (str, int)": [],
  "Boots (dex, int)": [],
  "Body Armours (str)": ["Chestplate","Full Scale Armour"],
  "Body Armours (dex)": ["Full Scale Armour"],
  "Body Armours (int)": [],
  "Body Armours (str, dex)": ["Full Scale Armour"],
  "Body Armours (str, int)": [],
  "Body Armours (dex, int)": [],
  "Helmets (str)": ["Sallet"],
  "Helmets (dex)": ["Tricorne","Sallet"],
  "Helmets (int)": [],
  "Helmets (str, dex)": ["Sallet"],
  "Helmets (str, int)": [],
  "Helmets (dex, int)": [],
  "Tinctures": ["Blood Sap Tincture"]
}

export const magicItemGroups: { [key: string]: ItemAffixRegex[] } = {
  "Claws" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "gla", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "igi", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "wa", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "nd$", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "rd", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "^inc", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sso", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "com", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(140-159) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(130-139) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(120-129) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(110-119) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(100-109) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(90-99) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(80-89) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(70-79) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(60-69) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(50-59) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(40-49) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(30-39) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "lys", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "rest", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "cra", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^mal", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "rv", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "blis", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "oy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "eam", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"}
  ],
  "Daggers" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ree", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nui", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "teg", isPrefix: false, name: "of Disintegrating", description: "+(34-38)% to Chaos Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "atr", isPrefix: false, name: "of Atrophying", description: "+(29-33)% to Chaos Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "det", isPrefix: false, name: "of Deteriorating", description: "+(24-28)% to Chaos Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "was", isPrefix: false, name: "of Wasting", description: "+(19-23)% to Chaos Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "wan", isPrefix: false, name: "of Waning", description: "+(14-18)% to Chaos Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "ind", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ngo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "arb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "^inc", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "lami", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sso", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^ana", isPrefix: true, name: "Anarchist's", description: "+2 to Level of Socketed Chaos Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "erb", isPrefix: true, name: "Winterbringer's", description: "+2 to Level of Socketed Cold Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "lav", isPrefix: true, name: "Lava Caller's", description: "+2 to Level of Socketed Fire Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "g'", isPrefix: true, name: "Tempest King's", description: "+2 to Level of Socketed Lightning Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "com", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^n", isPrefix: true, name: "Nihilist's", description: "+1 to Level of Socketed Chaos Gems", ilevel: 4, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "weav", isPrefix: true, name: "Frost Weaver's", description: "+1 to Level of Socketed Cold Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "spi", isPrefix: true, name: "Flame Spinner's", description: "+1 to Level of Socketed Fire Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "d'", isPrefix: true, name: "Thunder Lord's", description: "+1 to Level of Socketed Lightning Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(140-159) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(130-139) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(120-129) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(110-119) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(100-109) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(90-99) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(80-89) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(70-79) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(60-69) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(50-59) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(40-49) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(30-39) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "rest", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "mpr", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "est$", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wo", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eup", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "joy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "ere", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "nea", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "amm", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"}
  ],
  "Wands" : [
    {regex: "hyp", isPrefix: false, name: "of the Hyperboreal", description: "20% chance to Freeze", ilevel: 75, family: "ChanceToFreeze"},
    {regex: "kn", isPrefix: false, name: "of Bleakness", description: "15% chance to Freeze", ilevel: 45, family: "ChanceToFreeze"},
    {regex: "f fr", isPrefix: false, name: "of Freezing", description: "10% chance to Freeze", ilevel: 15, family: "ChanceToFreeze"},
    {regex: "onf", isPrefix: false, name: "of Conflagration", description: "20% chance to Ignite", ilevel: 75, family: "ChanceToIgnite"},
    {regex: "com", isPrefix: false, name: "of Combustion", description: "15% chance to Ignite", ilevel: 45, family: "ChanceToIgnite"},
    {regex: "niti", isPrefix: false, name: "of Ignition", description: "10% chance to Ignite", ilevel: 15, family: "ChanceToIgnite"},
    {regex: "cutio", isPrefix: false, name: "of Electrocution", description: "20% chance to Shock", ilevel: 75, family: "ChanceToShock"},
    {regex: "zap", isPrefix: false, name: "of Zapping", description: "15% chance to Shock", ilevel: 45, family: "ChanceToShock"},
    {regex: "f sho", isPrefix: false, name: "of Shocking", description: "10% chance to Shock", ilevel: 15, family: "ChanceToShock"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "tali", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "iati", isPrefix: false, name: "of Glaciation", description: "(27-30)% increased Cold Damage", ilevel: 76, family: "ColdDamagePercentage"},
    {regex: "oe", isPrefix: false, name: "of Floe", description: "(23-26)% increased Cold Damage", ilevel: 64, family: "ColdDamagePercentage"},
    {regex: "me$", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "w$", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "seal", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "teg", isPrefix: false, name: "of Disintegrating", description: "+(34-38)% to Chaos Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "rts", isPrefix: false, name: "of Heartstopping", description: "+(34-38)% to Cold Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "fana", isPrefix: false, name: "of the Fanatical", description: "+(34-38)% to Fire Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "xs", isPrefix: false, name: "of Exsanguinating", description: "+(34-38)% to Physical Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "yi", isPrefix: false, name: "of Atrophying", description: "+(29-33)% to Chaos Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "gel", isPrefix: false, name: "of the Gelid", description: "+(29-33)% to Cold Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "zea", isPrefix: false, name: "of the Zealous", description: "+(29-33)% to Fire Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "hem", isPrefix: false, name: "of Hemorrhaging", description: "+(29-33)% to Physical Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "det", isPrefix: false, name: "of Deteriorating", description: "+(24-28)% to Chaos Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "e bo", isPrefix: false, name: "of the Boreal", description: "+(24-28)% to Cold Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "arde", isPrefix: false, name: "of the Ardent", description: "+(24-28)% to Fire Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "hl", isPrefix: false, name: "of Phlebotomising", description: "+(24-28)% to Physical Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "was", isPrefix: false, name: "of Wasting", description: "+(19-23)% to Chaos Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "ak$", isPrefix: false, name: "of the Bleak", description: "+(19-23)% to Cold Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "erv", isPrefix: false, name: "of the Fervid", description: "+(19-23)% to Fire Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "spil", isPrefix: false, name: "of Spilling", description: "+(19-23)% to Physical Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "wani", isPrefix: false, name: "of Waning", description: "+(14-18)% to Chaos Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "ncl", isPrefix: false, name: "of the Inclement", description: "+(14-18)% to Cold Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "arn", isPrefix: false, name: "of the Earnest", description: "+(14-18)% to Fire Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "see", isPrefix: false, name: "of Seeping", description: "+(14-18)% to Physical Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "arb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eate", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "hes", isPrefix: false, name: "of Ashes", description: "(27-30)% increased Fire Damage", ilevel: 76, family: "FireDamagePercentage"},
    {regex: "imm", isPrefix: false, name: "of Immolation", description: "(23-26)% increased Fire Damage", ilevel: 64, family: "FireDamagePercentage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "ind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "coa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "mbe", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "zt", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "ma$", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "ano", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dra", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sol", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "mel", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "gis", isPrefix: true, name: "Magister's", description: "+1 to Level of all Spell Skill Gems", ilevel: 55, family: "IncreaseSocketedGemLevel"},
    {regex: "mad", isPrefix: true, name: "Mad Lord's", description: "+1 to Level of all Chaos Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "inge", isPrefix: true, name: "Frost Singer's", description: "+1 to Level of all Cold Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "hap", isPrefix: true, name: "Flame Shaper's", description: "+1 to Level of all Fire Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "nd'", isPrefix: true, name: "Thunderhand's", description: "+1 to Level of all Lightning Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tho", isPrefix: true, name: "Lithomancer's", description: "+1 to Level of all Physical Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "lio", isPrefix: false, name: "of Lioneye", description: "+(625-780) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "mas", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "fin", isPrefix: false, name: "of Finesse", description: "(29-32)% increased Cast Speed", ilevel: 83, family: "IncreasedCastSpeed"},
    {regex: "sort", isPrefix: false, name: "of Sortilege", description: "(25-28)% increased Cast Speed", ilevel: 72, family: "IncreasedCastSpeed"},
    {regex: "tid", isPrefix: false, name: "of Prestidigitation", description: "(21-24)% increased Cast Speed", ilevel: 55, family: "IncreasedCastSpeed"},
    {regex: "erd", isPrefix: false, name: "of Legerdemain", description: "(17-20)% increased Cast Speed", ilevel: 40, family: "IncreasedCastSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(13-16)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "nim", isPrefix: false, name: "of Nimbleness", description: "(9-12)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(5-8)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(140-159) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(130-139) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(120-129) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(110-119) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(100-109) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(90-99) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(80-89) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(70-79) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(60-69) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(50-59) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(40-49) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(30-39) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "inf", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sage$", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "f lig", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "^va", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "f ar", isPrefix: false, name: "of Arcing", description: "(27-30)% increased Lightning Damage", ilevel: 76, family: "LightningDamagePercentage"},
    {regex: "rge$", isPrefix: false, name: "of Discharge", description: "(23-26)% increased Lightning Damage", ilevel: 64, family: "LightningDamagePercentage"},
    {regex: "lta", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "tatic", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "ks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rhe", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wor", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "y'", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "ser", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "liss", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "oy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "gal", isPrefix: false, name: "of the Gale", description: "(42-46)% increased Projectile Speed", ilevel: 82, family: "ProjectileSpeed"},
    {regex: "zep", isPrefix: false, name: "of the Zephyr", description: "(34-41)% increased Projectile Speed", ilevel: 55, family: "ProjectileSpeed"},
    {regex: "opu", isPrefix: false, name: "of Propulsion", description: "(26-33)% increased Projectile Speed", ilevel: 41, family: "ProjectileSpeed"},
    {regex: "fli", isPrefix: false, name: "of Flight", description: "(18-25)% increased Projectile Speed", ilevel: 27, family: "ProjectileSpeed"},
    {regex: "dar", isPrefix: false, name: "of Darting", description: "(10-17)% increased Projectile Speed", ilevel: 14, family: "ProjectileSpeed"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (4-14) to (170-179) Lightning Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (41-54) to (81-93) Cold Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (49-66) to (98-115) Fire Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (33-43) to (64-75) Cold Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (4-11) to (136-144) Lightning Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (40-52) to (79-91) Fire Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (2-9) to (109-115) Lightning Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (26-35) to (51-60) Cold Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (31-42) to (64-73) Fire Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (2-7) to (84-88) Lightning Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (20-26) to (40-46) Cold Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (24-33) to (48-57) Fire Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (16-20) to (30-36) Cold Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (19-25) to (37-44) Fire Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (2-5) to (64-68) Lightning Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-4) to (46-48) Lightning Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (11-15) to (22-25) Cold Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (13-18) to (27-31) Fire Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (33-35) Lightning Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (10-12) to (19-23) Fire Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (8-10) to (16-18) Cold Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds (1-2) to (21-22) Lightning Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (5-7) to (10-12) Cold Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (6-8) to (12-14) Fire Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "eate", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds 1 to (2-3) Cold Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (4-5) Lightning Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "nm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "cala", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "isa", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ich", isPrefix: true, name: "Lich's", description: "(35-39)% increased Spell Damage|+(42-45) to maximum Mana", ilevel: 80, family: "SpellDamageAndMana"},
    {regex: "chm", isPrefix: true, name: "Archmage's", description: "(30-34)% increased Spell Damage|+(38-41) to maximum Mana", ilevel: 58, family: "SpellDamageAndMana"},
    {regex: "^mage", isPrefix: true, name: "Mage's", description: "(25-29)% increased Spell Damage|+(34-37) to maximum Mana", ilevel: 46, family: "SpellDamageAndMana"},
    {regex: "rl", isPrefix: true, name: "Warlock's", description: "(20-24)% increased Spell Damage|+(29-33) to maximum Mana", ilevel: 35, family: "SpellDamageAndMana"},
    {regex: "wiz", isPrefix: true, name: "Wizard's", description: "(15-19)% increased Spell Damage|+(25-28) to maximum Mana", ilevel: 23, family: "SpellDamageAndMana"},
    {regex: "nj", isPrefix: true, name: "Conjuror's", description: "(10-14)% increased Spell Damage|+(21-24) to maximum Mana", ilevel: 11, family: "SpellDamageAndMana"},
    {regex: "^cas", isPrefix: true, name: "Caster's", description: "(5-9)% increased Spell Damage|+(17-20) to maximum Mana", ilevel: 2, family: "SpellDamageAndMana"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sla", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "l'", isPrefix: true, name: "Tul's", description: "(100-109)% increased Cold Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^x", isPrefix: true, name: "Xoph's", description: "(100-109)% increased Fire Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^es", isPrefix: true, name: "Esh's", description: "(100-109)% increased Lightning Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^ru", isPrefix: true, name: "Runic", description: "(100-109)% increased Spell Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "yo", isPrefix: true, name: "Cryomancer's", description: "(85-99)% increased Cold Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "py", isPrefix: true, name: "Pyroclastic", description: "(85-99)% increased Fire Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "^io", isPrefix: true, name: "Ionising", description: "(85-99)% increased Lightning Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "gly", isPrefix: true, name: "Glyphic", description: "(85-99)% increased Spell Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "tall", isPrefix: true, name: "Crystalline", description: "(70-84)% increased Cold Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "gmat", isPrefix: true, name: "Magmatic", description: "(70-84)% increased Fire Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "smi", isPrefix: true, name: "Smiting", description: "(70-84)% increased Lightning Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "nca", isPrefix: true, name: "Incanter's", description: "(70-84)% increased Spell Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^ha", isPrefix: true, name: "Hailing", description: "(55-69)% increased Cold Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^vo", isPrefix: true, name: "Volcanic", description: "(55-69)% increased Fire Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^st", isPrefix: true, name: "Striking", description: "(55-69)% increased Lightning Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^oc", isPrefix: true, name: "Occultist's", description: "(55-69)% increased Spell Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "wy", isPrefix: true, name: "Snowy", description: "(40-54)% increased Cold Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cau", isPrefix: true, name: "Cauterising", description: "(40-54)% increased Fire Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cou", isPrefix: true, name: "Coursing", description: "(40-54)% increased Lightning Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "ofe", isPrefix: true, name: "Professor's", description: "(40-54)% increased Spell Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "^al", isPrefix: true, name: "Alpine", description: "(30-39)% increased Cold Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bli", isPrefix: true, name: "Blistering", description: "(30-39)% increased Fire Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bo", isPrefix: true, name: "Bolting", description: "(30-39)% increased Lightning Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "cho", isPrefix: true, name: "Scholar's", description: "(30-39)% increased Spell Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "biti", isPrefix: true, name: "Biting", description: "(20-29)% increased Cold Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "zl", isPrefix: true, name: "Sizzling", description: "(20-29)% increased Fire Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "^hi", isPrefix: true, name: "Hissing", description: "(20-29)% increased Lightning Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "ade", isPrefix: true, name: "Adept's", description: "(20-29)% increased Spell Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "itt", isPrefix: true, name: "Bitter", description: "(10-19)% increased Cold Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^sea", isPrefix: true, name: "Searing", description: "(10-19)% increased Fire Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "rged", isPrefix: true, name: "Charged", description: "(10-19)% increased Lightning Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^ap", isPrefix: true, name: "Apprentice's", description: "(10-19)% increased Spell Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"}
  ],
  "One Hand Swords" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "ierc", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "liq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "cel", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "tora", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "cra", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "orm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "^burnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "lia", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "or$", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "xe", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Thrusting One Hand Swords" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "wa", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "ierc", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "liq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "cel", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "tora", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "orm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "^burnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "xe", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "One Hand Axes" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage$", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "jag", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "orm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wo", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "tl", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "box", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "One Hand Maces" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "gu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "car", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sa", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "iss", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rks", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "se$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "ly", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "orm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rt", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pio", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "lari", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "ct$", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "or$", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "ox", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Sceptres" : [
    {regex: "hyp", isPrefix: false, name: "of the Hyperboreal", description: "20% chance to Freeze", ilevel: 75, family: "ChanceToFreeze"},
    {regex: "kn", isPrefix: false, name: "of Bleakness", description: "15% chance to Freeze", ilevel: 45, family: "ChanceToFreeze"},
    {regex: "f fr", isPrefix: false, name: "of Freezing", description: "10% chance to Freeze", ilevel: 15, family: "ChanceToFreeze"},
    {regex: "onf", isPrefix: false, name: "of Conflagration", description: "20% chance to Ignite", ilevel: 75, family: "ChanceToIgnite"},
    {regex: "mbu", isPrefix: false, name: "of Combustion", description: "15% chance to Ignite", ilevel: 45, family: "ChanceToIgnite"},
    {regex: "niti", isPrefix: false, name: "of Ignition", description: "10% chance to Ignite", ilevel: 15, family: "ChanceToIgnite"},
    {regex: "cutio", isPrefix: false, name: "of Electrocution", description: "20% chance to Shock", ilevel: 75, family: "ChanceToShock"},
    {regex: "zap", isPrefix: false, name: "of Zapping", description: "15% chance to Shock", ilevel: 45, family: "ChanceToShock"},
    {regex: "f sho", isPrefix: false, name: "of Shocking", description: "10% chance to Shock", ilevel: 15, family: "ChanceToShock"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "tali", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "iati", isPrefix: false, name: "of Glaciation", description: "(27-30)% increased Cold Damage", ilevel: 76, family: "ColdDamagePercentage"},
    {regex: "oe", isPrefix: false, name: "of Floe", description: "(23-26)% increased Cold Damage", ilevel: 64, family: "ColdDamagePercentage"},
    {regex: "rime", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "w$", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "seal", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nui", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "trati", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "rts", isPrefix: false, name: "of Heartstopping", description: "+(34-38)% to Cold Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "fan", isPrefix: false, name: "of the Fanatical", description: "+(34-38)% to Fire Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "gel", isPrefix: false, name: "of the Gelid", description: "+(29-33)% to Cold Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "zea", isPrefix: false, name: "of the Zealous", description: "+(29-33)% to Fire Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "e bor", isPrefix: false, name: "of the Boreal", description: "+(24-28)% to Cold Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "arde", isPrefix: false, name: "of the Ardent", description: "+(24-28)% to Fire Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "ak$", isPrefix: false, name: "of the Bleak", description: "+(19-23)% to Cold Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "erv", isPrefix: false, name: "of the Fervid", description: "+(19-23)% to Fire Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "ncl", isPrefix: false, name: "of the Inclement", description: "+(14-18)% to Cold Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "earn", isPrefix: false, name: "of the Earnest", description: "+(14-18)% to Fire Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "arb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "hes", isPrefix: false, name: "of Ashes", description: "(27-30)% increased Fire Damage", ilevel: 76, family: "FireDamagePercentage"},
    {regex: "imm", isPrefix: false, name: "of Immolation", description: "(23-26)% increased Fire Damage", ilevel: 64, family: "FireDamagePercentage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "ind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "coa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "emb", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "zt", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "ma$", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "ano", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dra", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sol", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "gis", isPrefix: true, name: "Magister's", description: "+1 to Level of all Spell Skill Gems", ilevel: 55, family: "IncreaseSocketedGemLevel"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^com", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "inge", isPrefix: true, name: "Frost Singer's", description: "+1 to Level of all Cold Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "hap", isPrefix: true, name: "Flame Shaper's", description: "+1 to Level of all Fire Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "rha", isPrefix: true, name: "Thunderhand's", description: "+1 to Level of all Lightning Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tho", isPrefix: true, name: "Lithomancer's", description: "+1 to Level of all Physical Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "fame", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "fin", isPrefix: false, name: "of Finesse", description: "(29-32)% increased Cast Speed", ilevel: 83, family: "IncreasedCastSpeed"},
    {regex: "til", isPrefix: false, name: "of Sortilege", description: "(25-28)% increased Cast Speed", ilevel: 72, family: "IncreasedCastSpeed"},
    {regex: "tid", isPrefix: false, name: "of Prestidigitation", description: "(21-24)% increased Cast Speed", ilevel: 55, family: "IncreasedCastSpeed"},
    {regex: "erd", isPrefix: false, name: "of Legerdemain", description: "(17-20)% increased Cast Speed", ilevel: 40, family: "IncreasedCastSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(13-16)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "nim", isPrefix: false, name: "of Nimbleness", description: "(9-12)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(5-8)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(140-159) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(130-139) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(120-129) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(110-119) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(100-109) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(90-99) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(80-89) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(70-79) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(60-69) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(50-59) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(40-49) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(30-39) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "mph", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "f ar", isPrefix: false, name: "of Arcing", description: "(27-30)% increased Lightning Damage", ilevel: 76, family: "LightningDamagePercentage"},
    {regex: "rge$", isPrefix: false, name: "of Discharge", description: "(23-26)% increased Lightning Damage", ilevel: 64, family: "LightningDamagePercentage"},
    {regex: "lta", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "tatic", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "ks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rhe", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wor", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rcil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "liss", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "joy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (4-14) to (170-179) Lightning Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (41-54) to (81-93) Cold Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (49-66) to (98-115) Fire Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (33-43) to (64-75) Cold Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (4-11) to (136-144) Lightning Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (40-52) to (79-91) Fire Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (2-9) to (109-115) Lightning Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (26-35) to (51-60) Cold Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (31-42) to (64-73) Fire Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (2-7) to (84-88) Lightning Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (20-26) to (40-46) Cold Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (24-33) to (48-57) Fire Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (16-20) to (30-36) Cold Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (19-25) to (37-44) Fire Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (2-5) to (64-68) Lightning Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-4) to (46-48) Lightning Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (11-15) to (22-25) Cold Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (13-18) to (27-31) Fire Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (33-35) Lightning Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (10-12) to (19-23) Fire Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (8-10) to (16-18) Cold Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds (1-2) to (21-22) Lightning Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (5-7) to (10-12) Cold Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (6-8) to (12-14) Fire Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds 1 to (2-3) Cold Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (4-5) Lightning Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "unm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ruin", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "cala", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "isa", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ich", isPrefix: true, name: "Lich's", description: "(35-39)% increased Spell Damage|+(42-45) to maximum Mana", ilevel: 80, family: "SpellDamageAndMana"},
    {regex: "chm", isPrefix: true, name: "Archmage's", description: "(30-34)% increased Spell Damage|+(38-41) to maximum Mana", ilevel: 58, family: "SpellDamageAndMana"},
    {regex: "^mage", isPrefix: true, name: "Mage's", description: "(25-29)% increased Spell Damage|+(34-37) to maximum Mana", ilevel: 46, family: "SpellDamageAndMana"},
    {regex: "k'", isPrefix: true, name: "Warlock's", description: "(20-24)% increased Spell Damage|+(29-33) to maximum Mana", ilevel: 35, family: "SpellDamageAndMana"},
    {regex: "wiz", isPrefix: true, name: "Wizard's", description: "(15-19)% increased Spell Damage|+(25-28) to maximum Mana", ilevel: 23, family: "SpellDamageAndMana"},
    {regex: "nj", isPrefix: true, name: "Conjuror's", description: "(10-14)% increased Spell Damage|+(21-24) to maximum Mana", ilevel: 11, family: "SpellDamageAndMana"},
    {regex: "^cas", isPrefix: true, name: "Caster's", description: "(5-9)% increased Spell Damage|+(17-20) to maximum Mana", ilevel: 2, family: "SpellDamageAndMana"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sla", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "tant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "ox", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"},
    {regex: "l'", isPrefix: true, name: "Tul's", description: "(100-109)% increased Cold Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^x", isPrefix: true, name: "Xoph's", description: "(100-109)% increased Fire Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^es", isPrefix: true, name: "Esh's", description: "(100-109)% increased Lightning Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^ru", isPrefix: true, name: "Runic", description: "(100-109)% increased Spell Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "yo", isPrefix: true, name: "Cryomancer's", description: "(85-99)% increased Cold Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "py", isPrefix: true, name: "Pyroclastic", description: "(85-99)% increased Fire Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "^io", isPrefix: true, name: "Ionising", description: "(85-99)% increased Lightning Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "gly", isPrefix: true, name: "Glyphic", description: "(85-99)% increased Spell Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "tall", isPrefix: true, name: "Crystalline", description: "(70-84)% increased Cold Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "gmat", isPrefix: true, name: "Magmatic", description: "(70-84)% increased Fire Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "smi", isPrefix: true, name: "Smiting", description: "(70-84)% increased Lightning Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "nca", isPrefix: true, name: "Incanter's", description: "(70-84)% increased Spell Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^ha", isPrefix: true, name: "Hailing", description: "(55-69)% increased Cold Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^vo", isPrefix: true, name: "Volcanic", description: "(55-69)% increased Fire Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^st", isPrefix: true, name: "Striking", description: "(55-69)% increased Lightning Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^oc", isPrefix: true, name: "Occultist's", description: "(55-69)% increased Spell Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "wy", isPrefix: true, name: "Snowy", description: "(40-54)% increased Cold Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cau", isPrefix: true, name: "Cauterising", description: "(40-54)% increased Fire Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cou", isPrefix: true, name: "Coursing", description: "(40-54)% increased Lightning Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "rof", isPrefix: true, name: "Professor's", description: "(40-54)% increased Spell Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "^al", isPrefix: true, name: "Alpine", description: "(30-39)% increased Cold Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bli", isPrefix: true, name: "Blistering", description: "(30-39)% increased Fire Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bo", isPrefix: true, name: "Bolting", description: "(30-39)% increased Lightning Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "cho", isPrefix: true, name: "Scholar's", description: "(30-39)% increased Spell Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "biti", isPrefix: true, name: "Biting", description: "(20-29)% increased Cold Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "zl", isPrefix: true, name: "Sizzling", description: "(20-29)% increased Fire Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "^hi", isPrefix: true, name: "Hissing", description: "(20-29)% increased Lightning Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "ade", isPrefix: true, name: "Adept's", description: "(20-29)% increased Spell Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "itt", isPrefix: true, name: "Bitter", description: "(10-19)% increased Cold Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^sea", isPrefix: true, name: "Searing", description: "(10-19)% increased Fire Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "rged", isPrefix: true, name: "Charged", description: "(10-19)% increased Lightning Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^ap", isPrefix: true, name: "Apprentice's", description: "(10-19)% increased Spell Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"}
  ],
  "Rune Daggers" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (81-111) to (163-189) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (68-92) to (136-157) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (54-74) to (108-126) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "gla", isPrefix: true, name: "Glaciated", description: "Adds (41-57) to (83-97) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (31-42) to (62-71) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ree", isPrefix: true, name: "Freezing", description: "Adds (22-30) to (44-51) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (16-21) to (31-37) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (11-15) to (23-26) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (7-9) to (14-16) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nui", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "teg", isPrefix: false, name: "of Disintegrating", description: "+(34-38)% to Chaos Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "art", isPrefix: false, name: "of Heartstopping", description: "+(34-38)% to Cold Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "yi", isPrefix: false, name: "of Atrophying", description: "+(29-33)% to Chaos Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "gel", isPrefix: false, name: "of the Gelid", description: "+(29-33)% to Cold Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "det", isPrefix: false, name: "of Deteriorating", description: "+(24-28)% to Chaos Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "bor", isPrefix: false, name: "of the Boreal", description: "+(24-28)% to Cold Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "was", isPrefix: false, name: "of Wasting", description: "+(19-23)% to Chaos Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "k$", isPrefix: false, name: "of the Bleak", description: "+(19-23)% to Cold Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "wan", isPrefix: false, name: "of Waning", description: "+(14-18)% to Chaos Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "ncl", isPrefix: false, name: "of the Inclement", description: "+(14-18)% to Cold Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (89-121) to (180-210) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "mat", isPrefix: true, name: "Cremating", description: "Adds (74-101) to (150-175) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (59-81) to (120-140) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (46-62) to (93-107) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (34-46) to (68-80) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (24-33) to (49-57) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (17-24) to (35-41) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (12-17) to (25-29) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-10) to (15-18) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sol", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "gis", isPrefix: true, name: "Magister's", description: "+1 to Level of all Spell Skill Gems", ilevel: 55, family: "IncreaseSocketedGemLevel"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "com", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mad", isPrefix: true, name: "Mad Lord's", description: "+1 to Level of all Chaos Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "inge", isPrefix: true, name: "Frost Singer's", description: "+1 to Level of all Cold Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "hap", isPrefix: true, name: "Flame Shaper's", description: "+1 to Level of all Fire Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "rha", isPrefix: true, name: "Thunderhand's", description: "+1 to Level of all Lightning Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tho", isPrefix: true, name: "Lithomancer's", description: "+1 to Level of all Physical Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "fin", isPrefix: false, name: "of Finesse", description: "(29-32)% increased Cast Speed", ilevel: 83, family: "IncreasedCastSpeed"},
    {regex: "til", isPrefix: false, name: "of Sortilege", description: "(25-28)% increased Cast Speed", ilevel: 72, family: "IncreasedCastSpeed"},
    {regex: "tid", isPrefix: false, name: "of Prestidigitation", description: "(21-24)% increased Cast Speed", ilevel: 55, family: "IncreasedCastSpeed"},
    {regex: "erd", isPrefix: false, name: "of Legerdemain", description: "(17-20)% increased Cast Speed", ilevel: 40, family: "IncreasedCastSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(13-16)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "nim", isPrefix: false, name: "of Nimbleness", description: "(9-12)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(5-8)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(140-159) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(130-139) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(120-129) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(110-119) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(100-109) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(90-99) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(80-89) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(70-79) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(60-69) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(50-59) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(40-49) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(30-39) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(51-59)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (15-21) to (296-344) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (13-17) to (247-286) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (10-14) to (197-229) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (8-10) to (152-176) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "arci", isPrefix: true, name: "Arcing", description: "Adds (5-8) to (112-131) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (4-5) to (80-94) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds 3 to (57-67) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds 2 to (41-48) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (25-29) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (5-6) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rhe", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wo", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (56-87) to (105-160) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "joy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (22-29) to (45-52) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (19-25) to (39-45) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (16-21) to (32-38) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (13-18) to (27-31) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (11-14) to (21-25) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (8-12) to (17-20) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (6-9) to (13-15) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (4-5) to (8-9) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to (2-3) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (4-14) to (170-179) Lightning Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (41-54) to (81-93) Cold Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "mat", isPrefix: true, name: "Cremating", description: "Adds (49-66) to (98-115) Fire Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (33-43) to (64-75) Cold Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (4-11) to (136-144) Lightning Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (40-52) to (79-91) Fire Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (2-9) to (109-115) Lightning Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "gla", isPrefix: true, name: "Glaciated", description: "Adds (26-35) to (51-60) Cold Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (31-42) to (64-73) Fire Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "arci", isPrefix: true, name: "Arcing", description: "Adds (2-7) to (84-88) Lightning Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (20-26) to (40-46) Cold Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (24-33) to (48-57) Fire Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "ree", isPrefix: true, name: "Freezing", description: "Adds (16-20) to (30-36) Cold Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (19-25) to (37-44) Fire Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (2-5) to (64-68) Lightning Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-4) to (46-48) Lightning Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (11-15) to (22-25) Cold Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (13-18) to (27-31) Fire Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (33-35) Lightning Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (10-12) to (19-23) Fire Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (8-10) to (16-18) Cold Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds (1-2) to (21-22) Lightning Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (5-7) to (10-12) Cold Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (6-8) to (12-14) Fire Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (3-4) Fire Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds 1 to (2-3) Cold Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (4-5) Lightning Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "unm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mit", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "isa", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "h'", isPrefix: true, name: "Lich's", description: "(35-39)% increased Spell Damage|+(42-45) to maximum Mana", ilevel: 80, family: "SpellDamageAndMana"},
    {regex: "chm", isPrefix: true, name: "Archmage's", description: "(30-34)% increased Spell Damage|+(38-41) to maximum Mana", ilevel: 58, family: "SpellDamageAndMana"},
    {regex: "^mage", isPrefix: true, name: "Mage's", description: "(25-29)% increased Spell Damage|+(34-37) to maximum Mana", ilevel: 46, family: "SpellDamageAndMana"},
    {regex: "rl", isPrefix: true, name: "Warlock's", description: "(20-24)% increased Spell Damage|+(29-33) to maximum Mana", ilevel: 35, family: "SpellDamageAndMana"},
    {regex: "iz", isPrefix: true, name: "Wizard's", description: "(15-19)% increased Spell Damage|+(25-28) to maximum Mana", ilevel: 23, family: "SpellDamageAndMana"},
    {regex: "nj", isPrefix: true, name: "Conjuror's", description: "(10-14)% increased Spell Damage|+(21-24) to maximum Mana", ilevel: 11, family: "SpellDamageAndMana"},
    {regex: "^cas", isPrefix: true, name: "Caster's", description: "(5-9)% increased Spell Damage|+(17-20) to maximum Mana", ilevel: 2, family: "SpellDamageAndMana"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "amm", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "uni", isPrefix: true, name: "Runic", description: "(100-109)% increased Spell Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "yp", isPrefix: true, name: "Glyphic", description: "(85-99)% increased Spell Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "nca", isPrefix: true, name: "Incanter's", description: "(70-84)% increased Spell Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^oc", isPrefix: true, name: "Occultist's", description: "(55-69)% increased Spell Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "rof", isPrefix: true, name: "Professor's", description: "(40-54)% increased Spell Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cho", isPrefix: true, name: "Scholar's", description: "(30-39)% increased Spell Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "dep", isPrefix: true, name: "Adept's", description: "(20-29)% increased Spell Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "^ap", isPrefix: true, name: "Apprentice's", description: "(10-19)% increased Spell Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"}
  ],
  "Bows" : [
    {regex: "any", isPrefix: false, name: "of Many", description: "Bow Attacks fire 2 additional Arrows", ilevel: 86, family: "AdditionalArrows"},
    {regex: "spl", isPrefix: false, name: "of Splintering", description: "Bow Attacks fire an additional Arrow", ilevel: 70, family: "AdditionalArrows"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "mb", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "gla", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "wa", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "rd", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ngo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "car", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dra", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "mel", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "acr", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "ps", isPrefix: true, name: "Sharpshooter's", description: "+2 to Level of Socketed Bow Gems", ilevel: 64, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tc", isPrefix: true, name: "Fletcher's", description: "+1 to Level of Socketed Bow Gems", ilevel: 9, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "lio", isPrefix: false, name: "of Lioneye", description: "+(625-780) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rks", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "per$", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "prec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "mas", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "empo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nf", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "eg", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "rest", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "f lig", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "^va", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "hoc", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wor", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "y'", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "e'", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "rue", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "gal", isPrefix: false, name: "of the Gale", description: "(42-46)% increased Projectile Speed", ilevel: 82, family: "ProjectileSpeed"},
    {regex: "zep", isPrefix: false, name: "of the Zephyr", description: "(34-41)% increased Projectile Speed", ilevel: 55, family: "ProjectileSpeed"},
    {regex: "rop", isPrefix: false, name: "of Propulsion", description: "(26-33)% increased Projectile Speed", ilevel: 41, family: "ProjectileSpeed"},
    {regex: "fli", isPrefix: false, name: "of Flight", description: "(18-25)% increased Projectile Speed", ilevel: 27, family: "ProjectileSpeed"},
    {regex: "dar", isPrefix: false, name: "of Darting", description: "(10-17)% increased Projectile Speed", ilevel: 14, family: "ProjectileSpeed"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"}
  ],
  "Staves" : [
    {regex: "hyp", isPrefix: false, name: "of the Hyperboreal", description: "30% chance to Freeze", ilevel: 75, family: "ChanceToFreeze"},
    {regex: "kn", isPrefix: false, name: "of Bleakness", description: "25% chance to Freeze", ilevel: 45, family: "ChanceToFreeze"},
    {regex: "f fr", isPrefix: false, name: "of Freezing", description: "20% chance to Freeze", ilevel: 15, family: "ChanceToFreeze"},
    {regex: "onf", isPrefix: false, name: "of Conflagration", description: "30% chance to Ignite", ilevel: 75, family: "ChanceToIgnite"},
    {regex: "mbu", isPrefix: false, name: "of Combustion", description: "25% chance to Ignite", ilevel: 45, family: "ChanceToIgnite"},
    {regex: "niti", isPrefix: false, name: "of Ignition", description: "20% chance to Ignite", ilevel: 15, family: "ChanceToIgnite"},
    {regex: "cutio", isPrefix: false, name: "of Electrocution", description: "30% chance to Shock", ilevel: 75, family: "ChanceToShock"},
    {regex: "zap", isPrefix: false, name: "of Zapping", description: "25% chance to Shock", ilevel: 45, family: "ChanceToShock"},
    {regex: "f sho", isPrefix: false, name: "of Shocking", description: "20% chance to Shock", ilevel: 15, family: "ChanceToShock"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "tali", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "iati", isPrefix: false, name: "of Glaciation", description: "(27-30)% increased Cold Damage", ilevel: 76, family: "ColdDamagePercentage"},
    {regex: "oe", isPrefix: false, name: "of Floe", description: "(23-26)% increased Cold Damage", ilevel: 64, family: "ColdDamagePercentage"},
    {regex: "rime", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "w$", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "e po", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "seal", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "teg", isPrefix: false, name: "of Disintegrating", description: "+(66-75)% to Chaos Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "rts", isPrefix: false, name: "of Heartstopping", description: "+(66-75)% to Cold Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "fan", isPrefix: false, name: "of the Fanatical", description: "+(66-75)% to Fire Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "xs", isPrefix: false, name: "of Exsanguinating", description: "+(66-75)% to Physical Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "yi", isPrefix: false, name: "of Atrophying", description: "+(56-65)% to Chaos Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "gel", isPrefix: false, name: "of the Gelid", description: "+(56-65)% to Cold Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "zea", isPrefix: false, name: "of the Zealous", description: "+(56-65)% to Fire Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "hem", isPrefix: false, name: "of Hemorrhaging", description: "+(56-65)% to Physical Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "det", isPrefix: false, name: "of Deteriorating", description: "+(46-55)% to Chaos Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "e bor", isPrefix: false, name: "of the Boreal", description: "+(46-55)% to Cold Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "arde", isPrefix: false, name: "of the Ardent", description: "+(46-55)% to Fire Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "hl", isPrefix: false, name: "of Phlebotomising", description: "+(46-55)% to Physical Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "was", isPrefix: false, name: "of Wasting", description: "+(36-45)% to Chaos Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "ak$", isPrefix: false, name: "of the Bleak", description: "+(36-45)% to Cold Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "erv", isPrefix: false, name: "of the Fervid", description: "+(36-45)% to Fire Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "spi", isPrefix: false, name: "of Spilling", description: "+(36-45)% to Physical Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "wan", isPrefix: false, name: "of Waning", description: "+(26-35)% to Chaos Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "ncl", isPrefix: false, name: "of the Inclement", description: "+(26-35)% to Cold Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "arn", isPrefix: false, name: "of the Earnest", description: "+(26-35)% to Fire Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "see", isPrefix: false, name: "of Seeping", description: "+(26-35)% to Physical Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "car", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "hes", isPrefix: false, name: "of Ashes", description: "(27-30)% increased Fire Damage", ilevel: 76, family: "FireDamagePercentage"},
    {regex: "imm", isPrefix: false, name: "of Immolation", description: "(23-26)% increased Fire Damage", ilevel: 64, family: "FireDamagePercentage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "cind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "oa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "emb", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "ma$", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "ano", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "whe", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sol", isPrefix: false, name: "of Dissolution", description: "+(41-45)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(36-40)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(31-35)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(24-29)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(16-21)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "gis", isPrefix: true, name: "Magister's", description: "+(1-2) to Level of all Spell Skill Gems", ilevel: 55, family: "IncreaseSocketedGemLevel"},
    {regex: "spl", isPrefix: true, name: "Splintermind's", description: "+3 to Level of all Chaos Spell Skill Gems", ilevel: 77, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ko", isPrefix: true, name: "Winter Beckoner's", description: "+3 to Level of all Cold Spell Skill Gems", ilevel: 77, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "lav", isPrefix: true, name: "Lava Conjurer's", description: "+3 to Level of all Fire Spell Skill Gems", ilevel: 77, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "st m", isPrefix: true, name: "Tempest Master's", description: "+3 to Level of all Lightning Spell Skill Gems", ilevel: 77, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tec", isPrefix: true, name: "Tecton's", description: "+3 to Level of all Physical Spell Skill Gems", ilevel: 77, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "nt'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mad", isPrefix: true, name: "Mad Lord's", description: "+(1-2) to Level of all Chaos Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "inge", isPrefix: true, name: "Frost Singer's", description: "+(1-2) to Level of all Cold Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "hap", isPrefix: true, name: "Flame Shaper's", description: "+(1-2) to Level of all Fire Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^thu", isPrefix: true, name: "Thunderhand's", description: "+(1-2) to Level of all Lightning Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "tho", isPrefix: true, name: "Lithomancer's", description: "+(1-2) to Level of all Physical Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "prec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "cel", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "fame", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery$", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "fin", isPrefix: false, name: "of Finesse", description: "(44-49)% increased Cast Speed", ilevel: 83, family: "IncreasedCastSpeed"},
    {regex: "til", isPrefix: false, name: "of Sortilege", description: "(38-43)% increased Cast Speed", ilevel: 72, family: "IncreasedCastSpeed"},
    {regex: "tid", isPrefix: false, name: "of Prestidigitation", description: "(32-37)% increased Cast Speed", ilevel: 55, family: "IncreasedCastSpeed"},
    {regex: "erd", isPrefix: false, name: "of Legerdemain", description: "(26-31)% increased Cast Speed", ilevel: 40, family: "IncreasedCastSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(20-25)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "nim", isPrefix: false, name: "of Nimbleness", description: "(14-19)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(8-13)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(200-229) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(180-199) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(160-179) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(140-159) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(120-139) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(100-119) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(90-99) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(80-89) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(70-79) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(60-69) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(50-59) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(40-49) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "lys", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "igy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "ory", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "mpr", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "ini", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "rki", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "f ar", isPrefix: false, name: "of Arcing", description: "(27-30)% increased Lightning Damage", ilevel: 76, family: "LightningDamagePercentage"},
    {regex: "rge$", isPrefix: false, name: "of Discharge", description: "(23-26)% increased Lightning Damage", ilevel: 64, family: "LightningDamagePercentage"},
    {regex: "lta", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "tatic", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "ks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "rom", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pest$", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rhe", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm$", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wor", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "^ty", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "err", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ons", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "liss", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "joy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "ere", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (61-81) to (120-140) Cold Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (66-88) to (132-155) Fire Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (7-20) to (255-270) Lightning Damage to Spells", ilevel: 74, family: "SpellAddedElementalDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (49-64) to (96-113) Cold Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (5-17) to (204-216) Lightning Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (53-70) to (107-123) Fire Damage to Spells", ilevel: 62, family: "SpellAddedElementalDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (39-52) to (77-90) Cold Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (42-56) to (85-99) Fire Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (5-12) to (164-173) Lightning Damage to Spells", ilevel: 51, family: "SpellAddedElementalDamage"},
    {regex: "^arci", isPrefix: true, name: "Arcing", description: "Adds (3-10) to (126-133) Lightning Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (30-40) to (59-69) Cold Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (32-44) to (65-76) Fire Damage to Spells", ilevel: 42, family: "SpellAddedElementalDamage"},
    {regex: "rki", isPrefix: true, name: "Sparking", description: "Adds (2-8) to (97-102) Lightning Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (24-30) to (45-53) Cold Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "flami", isPrefix: true, name: "Flaming", description: "Adds (25-33) to (50-59) Fire Damage to Spells", ilevel: 33, family: "SpellAddedElementalDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (16-22) to (33-38) Cold Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (18-23) to (36-42) Fire Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (2-5) to (69-73) Lightning Damage to Spells", ilevel: 26, family: "SpellAddedElementalDamage"},
    {regex: "sna", isPrefix: true, name: "Snapping", description: "Adds (1-4) to (49-52) Lightning Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (12-15) to (23-28) Cold Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (13-17) to (26-29) Fire Damage to Spells", ilevel: 18, family: "SpellAddedElementalDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds (1-3) to (32-34) Lightning Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (8-10) to (15-18) Cold Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (8-11) to (17-19) Fire Damage to Spells", ilevel: 11, family: "SpellAddedElementalDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (1-2) to (3-4) Cold Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (1-2) to (4-5) Fire Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (6-7) Lightning Damage to Spells", ilevel: 1, family: "SpellAddedElementalDamage"},
    {regex: "unm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "cala", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "isa", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ich", isPrefix: true, name: "Lich's", description: "(51-55)% increased Spell Damage|+(60-64) to maximum Mana", ilevel: 80, family: "SpellDamageAndMana"},
    {regex: "chm", isPrefix: true, name: "Archmage's", description: "(45-50)% increased Spell Damage|+(54-59) to maximum Mana", ilevel: 58, family: "SpellDamageAndMana"},
    {regex: "^mage", isPrefix: true, name: "Mage's", description: "(38-44)% increased Spell Damage|+(48-53) to maximum Mana", ilevel: 46, family: "SpellDamageAndMana"},
    {regex: "k'", isPrefix: true, name: "Warlock's", description: "(30-37)% increased Spell Damage|+(42-47) to maximum Mana", ilevel: 35, family: "SpellDamageAndMana"},
    {regex: "wiz", isPrefix: true, name: "Wizard's", description: "(23-29)% increased Spell Damage|+(36-41) to maximum Mana", ilevel: 23, family: "SpellDamageAndMana"},
    {regex: "uro", isPrefix: true, name: "Conjuror's", description: "(15-22)% increased Spell Damage|+(31-35) to maximum Mana", ilevel: 11, family: "SpellDamageAndMana"},
    {regex: "^cas", isPrefix: true, name: "Caster's", description: "(8-14)% increased Spell Damage|+(26-30) to maximum Mana", ilevel: 2, family: "SpellDamageAndMana"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sla", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "tant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "ox", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"},
    {regex: "l'", isPrefix: true, name: "Tul's", description: "(150-164)% increased Cold Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^x", isPrefix: true, name: "Xoph's", description: "(150-164)% increased Fire Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^es", isPrefix: true, name: "Esh's", description: "(150-164)% increased Lightning Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^ru", isPrefix: true, name: "Runic", description: "(150-164)% increased Spell Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "yo", isPrefix: true, name: "Cryomancer's", description: "(125-149)% increased Cold Damage", ilevel: 79, family: "WeaponCasterDamagePrefix"},
    {regex: "py", isPrefix: true, name: "Pyroclastic", description: "(125-149)% increased Fire Damage", ilevel: 79, family: "WeaponCasterDamagePrefix"},
    {regex: "^io", isPrefix: true, name: "Ionising", description: "(125-149)% increased Lightning Damage", ilevel: 79, family: "WeaponCasterDamagePrefix"},
    {regex: "gly", isPrefix: true, name: "Glyphic", description: "(125-149)% increased Spell Damage", ilevel: 79, family: "WeaponCasterDamagePrefix"},
    {regex: "tall", isPrefix: true, name: "Crystalline", description: "(105-124)% increased Cold Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "gmat", isPrefix: true, name: "Magmatic", description: "(105-124)% increased Fire Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "smi", isPrefix: true, name: "Smiting", description: "(105-124)% increased Lightning Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "nca", isPrefix: true, name: "Incanter's", description: "(105-124)% increased Spell Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^ha", isPrefix: true, name: "Hailing", description: "(85-104)% increased Cold Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^vo", isPrefix: true, name: "Volcanic", description: "(85-104)% increased Fire Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "iki", isPrefix: true, name: "Striking", description: "(85-104)% increased Lightning Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^oc", isPrefix: true, name: "Occultist's", description: "(85-104)% increased Spell Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "wy", isPrefix: true, name: "Snowy", description: "(60-84)% increased Cold Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cau", isPrefix: true, name: "Cauterising", description: "(60-84)% increased Fire Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cou", isPrefix: true, name: "Coursing", description: "(60-84)% increased Lightning Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "rof", isPrefix: true, name: "Professor's", description: "(60-84)% increased Spell Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "^al", isPrefix: true, name: "Alpine", description: "(45-59)% increased Cold Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bli", isPrefix: true, name: "Blistering", description: "(45-59)% increased Fire Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bo", isPrefix: true, name: "Bolting", description: "(45-59)% increased Lightning Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "cho", isPrefix: true, name: "Scholar's", description: "(45-59)% increased Spell Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "biti", isPrefix: true, name: "Biting", description: "(30-44)% increased Cold Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "zl", isPrefix: true, name: "Sizzling", description: "(30-44)% increased Fire Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "^hi", isPrefix: true, name: "Hissing", description: "(30-44)% increased Lightning Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "ade", isPrefix: true, name: "Adept's", description: "(30-44)% increased Spell Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "itt", isPrefix: true, name: "Bitter", description: "(15-29)% increased Cold Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^sea", isPrefix: true, name: "Searing", description: "(15-29)% increased Fire Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "rged", isPrefix: true, name: "Charged", description: "(15-29)% increased Lightning Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^ap", isPrefix: true, name: "Apprentice's", description: "(15-29)% increased Spell Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"}
  ],
  "Two Hand Swords" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "nishm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "fre", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "wa", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ngo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "^bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sa", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(41-45)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(36-40)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(31-35)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(24-29)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(16-21)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "cel", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "eg", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "ini", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rt", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "tl", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "or$", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "xe", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Two Hand Axes" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "fre", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "e po", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "wa", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "ngu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "tru", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "jag", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "rd", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lu", isPrefix: false, name: "of Dissolution", description: "+(41-45)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(36-40)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(31-35)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(24-29)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(16-21)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "eg", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rt", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "rr", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "box", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Two Hand Maces" : [
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "lac", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "hil", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "gu", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "rb", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "urnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sa", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "iss", isPrefix: false, name: "of Dissolution", description: "+(41-45)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(36-40)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(31-35)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(24-29)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "acr", isPrefix: false, name: "of Acrimony", description: "+(16-21)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "t'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "eb", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "me$", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "ery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "se$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "ly", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "eg", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "^la", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "ini", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rg", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wor", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^ma", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pio", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cen", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ave", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "rue", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "dt", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "^wi", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ons", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sla", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "ct$", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "ant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "ox", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Warstaves" : [
    {regex: "yp", isPrefix: false, name: "of the Hyperboreal", description: "30% chance to Freeze", ilevel: 75, family: "ChanceToFreeze"},
    {regex: "kn", isPrefix: false, name: "of Bleakness", description: "25% chance to Freeze", ilevel: 45, family: "ChanceToFreeze"},
    {regex: "f fr", isPrefix: false, name: "of Freezing", description: "20% chance to Freeze", ilevel: 15, family: "ChanceToFreeze"},
    {regex: "onf", isPrefix: false, name: "of Conflagration", description: "30% chance to Ignite", ilevel: 75, family: "ChanceToIgnite"},
    {regex: "mbu", isPrefix: false, name: "of Combustion", description: "25% chance to Ignite", ilevel: 45, family: "ChanceToIgnite"},
    {regex: "niti", isPrefix: false, name: "of Ignition", description: "20% chance to Ignite", ilevel: 15, family: "ChanceToIgnite"},
    {regex: "cutio", isPrefix: false, name: "of Electrocution", description: "30% chance to Shock", ilevel: 75, family: "ChanceToShock"},
    {regex: "zap", isPrefix: false, name: "of Zapping", description: "25% chance to Shock", ilevel: 45, family: "ChanceToShock"},
    {regex: "f sho", isPrefix: false, name: "of Shocking", description: "20% chance to Shock", ilevel: 15, family: "ChanceToShock"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "cry", isPrefix: true, name: "Crystalising", description: "Adds (149-204) to (300-348) Cold Damage", ilevel: 82, family: "ColdDamage"},
    {regex: "bi", isPrefix: true, name: "Entombing", description: "Adds (124-170) to (250-290) Cold Damage", ilevel: 75, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (99-136) to (200-232) Cold Damage", ilevel: 63, family: "ColdDamage"},
    {regex: "^gla", isPrefix: true, name: "Glaciated", description: "Adds (77-104) to (154-178) Cold Damage", ilevel: 52, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (57-77) to (114-132) Cold Damage", ilevel: 43, family: "ColdDamage"},
    {regex: "^fre", isPrefix: true, name: "Freezing", description: "Adds (41-55) to (81-95) Cold Damage", ilevel: 34, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (29-40) to (58-68) Cold Damage", ilevel: 27, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (21-28) to (42-48) Cold Damage", ilevel: 19, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (12-17) to (26-30) Cold Damage", ilevel: 12, family: "ColdDamage"},
    {regex: "oste", isPrefix: true, name: "Frosted", description: "Adds (2-3) to (6-7) Cold Damage", ilevel: 2, family: "ColdDamage"},
    {regex: "iati", isPrefix: false, name: "of Glaciation", description: "(27-30)% increased Cold Damage", ilevel: 76, family: "ColdDamagePercentage"},
    {regex: "oe", isPrefix: false, name: "of Floe", description: "(23-26)% increased Cold Damage", ilevel: 64, family: "ColdDamagePercentage"},
    {regex: "rime", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "w$", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "in$", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance", ilevel: 73, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance", ilevel: 59, family: "CriticalStrikeChanceIncrease"},
    {regex: "pun", isPrefix: false, name: "of Puncturing", description: "(25-29)% increased Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "pie", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance", ilevel: 1, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "oci", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 44, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 30, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(15-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(10-14)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "teg", isPrefix: false, name: "of Disintegrating", description: "+(66-75)% to Chaos Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "fan", isPrefix: false, name: "of the Fanatical", description: "+(66-75)% to Fire Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "xs", isPrefix: false, name: "of Exsanguinating", description: "+(66-75)% to Physical Damage over Time Multiplier", ilevel: 78, family: "DamageOverTimeMultiplier"},
    {regex: "yi", isPrefix: false, name: "of Atrophying", description: "+(56-65)% to Chaos Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "zea", isPrefix: false, name: "of the Zealous", description: "+(56-65)% to Fire Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "hem", isPrefix: false, name: "of Hemorrhaging", description: "+(56-65)% to Physical Damage over Time Multiplier", ilevel: 64, family: "DamageOverTimeMultiplier"},
    {regex: "det", isPrefix: false, name: "of Deteriorating", description: "+(46-55)% to Chaos Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "ard", isPrefix: false, name: "of the Ardent", description: "+(46-55)% to Fire Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "hl", isPrefix: false, name: "of Phlebotomising", description: "+(46-55)% to Physical Damage over Time Multiplier", ilevel: 36, family: "DamageOverTimeMultiplier"},
    {regex: "was", isPrefix: false, name: "of Wasting", description: "+(36-45)% to Chaos Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "erv", isPrefix: false, name: "of the Fervid", description: "+(36-45)% to Fire Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "spil", isPrefix: false, name: "of Spilling", description: "+(36-45)% to Physical Damage over Time Multiplier", ilevel: 12, family: "DamageOverTimeMultiplier"},
    {regex: "wan", isPrefix: false, name: "of Waning", description: "+(26-35)% to Chaos Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "arn", isPrefix: false, name: "of the Earnest", description: "+(26-35)% to Fire Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "see", isPrefix: false, name: "of Seeping", description: "+(26-35)% to Physical Damage over Time Multiplier", ilevel: 4, family: "DamageOverTimeMultiplier"},
    {regex: "car", isPrefix: true, name: "Carbonising", description: "Adds (165-225) to (335-390) Fire Damage", ilevel: 82, family: "FireDamage"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (137-188) to (279-325) Fire Damage", ilevel: 74, family: "FireDamage"},
    {regex: "bla", isPrefix: true, name: "Blasting", description: "Adds (110-150) to (223-260) Fire Damage", ilevel: 62, family: "FireDamage"},
    {regex: "^inc", isPrefix: true, name: "Incinerating", description: "Adds (85-115) to (172-200) Fire Damage", ilevel: 51, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (63-85) to (128-148) Fire Damage", ilevel: 42, family: "FireDamage"},
    {regex: "lami", isPrefix: true, name: "Flaming", description: "Adds (45-61) to (91-106) Fire Damage", ilevel: 33, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (32-44) to (65-76) Fire Damage", ilevel: 26, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (23-31) to (47-54) Fire Damage", ilevel: 18, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (14-20) to (29-33) Fire Damage", ilevel: 11, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds (3-5) to (6-7) Fire Damage", ilevel: 1, family: "FireDamage"},
    {regex: "hes", isPrefix: false, name: "of Ashes", description: "(27-30)% increased Fire Damage", ilevel: 76, family: "FireDamagePercentage"},
    {regex: "imm", isPrefix: false, name: "of Immolation", description: "(23-26)% increased Fire Damage", ilevel: 64, family: "FireDamagePercentage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "ind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "oa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "emb", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "lc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sso", isPrefix: false, name: "of Dissolution", description: "+(41-45)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(36-40)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(31-35)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(24-29)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(16-21)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "^pa", isPrefix: true, name: "Paragon's", description: "+1 to Level of Socketed Gems", ilevel: 50, family: "IncreaseSocketedGemLevel"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "his", isPrefix: true, name: "Anarchist's", description: "+2 to Level of Socketed Chaos Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "win", isPrefix: true, name: "Winterbringer's", description: "+2 to Level of Socketed Cold Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "lav", isPrefix: true, name: "Lava Caller's", description: "+2 to Level of Socketed Fire Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "g'", isPrefix: true, name: "Tempest King's", description: "+2 to Level of Socketed Lightning Gems", ilevel: 55, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "nt'", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^n", isPrefix: true, name: "Nihilist's", description: "+1 to Level of Socketed Chaos Gems", ilevel: 4, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "weav", isPrefix: true, name: "Frost Weaver's", description: "+1 to Level of Socketed Cold Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "inn", isPrefix: true, name: "Flame Spinner's", description: "+1 to Level of Socketed Fire Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "d'", isPrefix: true, name: "Thunder Lord's", description: "+1 to Level of Socketed Lightning Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(456-624) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(326-455) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(216-325) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(131-215) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(80-130) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "cel", isPrefix: false, name: "of Celebration", description: "(26-27)% increased Attack Speed", ilevel: 77, family: "IncreasedAttackSpeed"},
    {regex: "nfa", isPrefix: false, name: "of Infamy", description: "(23-25)% increased Attack Speed", ilevel: 60, family: "IncreasedAttackSpeed"},
    {regex: "fame", isPrefix: false, name: "of Fame", description: "(20-22)% increased Attack Speed", ilevel: 45, family: "IncreasedAttackSpeed"},
    {regex: "ccl", isPrefix: false, name: "of Acclaim", description: "(17-19)% increased Attack Speed", ilevel: 37, family: "IncreasedAttackSpeed"},
    {regex: "wn", isPrefix: false, name: "of Renown", description: "(14-16)% increased Attack Speed", ilevel: 30, family: "IncreasedAttackSpeed"},
    {regex: "tery", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "se$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(200-229) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(180-199) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(160-179) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(140-159) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(120-139) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(100-119) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(90-99) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(80-89) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sa", isPrefix: true, name: "Sapphire", description: "+(70-79) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(60-69) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(50-59) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(40-49) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(87-100)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(73-85)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(63-71)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "mpo", isPrefix: true, name: "Empowering", description: "(53-61)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(36-51)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(19-34)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "gy", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nou", isPrefix: false, name: "of Nourishment", description: "Grants 5 Life per Enemy Hit", ilevel: 40, family: "LifeGainPerTarget"},
    {regex: "wt", isPrefix: false, name: "of Regrowth", description: "Grants 4 Life per Enemy Hit", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Grants 3 Life per Enemy Hit", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Grants 2 Life per Enemy Hit", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "mpr", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "vap", isPrefix: true, name: "Vapourising", description: "Adds (28-38) to (549-638) Lightning Damage", ilevel: 82, family: "LightningDamage"},
    {regex: "utin", isPrefix: true, name: "Electrocuting", description: "Adds (23-32) to (458-531) Lightning Damage", ilevel: 74, family: "LightningDamage"},
    {regex: "rgi", isPrefix: true, name: "Discharging", description: "Adds (19-25) to (366-425) Lightning Damage", ilevel: 63, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (14-20) to (281-327) Lightning Damage", ilevel: 51, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (11-14) to (208-242) Lightning Damage", ilevel: 42, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (8-10) to (148-173) Lightning Damage", ilevel: 34, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (5-8) to (106-123) Lightning Damage", ilevel: 31, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (4-5) to (76-88) Lightning Damage", ilevel: 19, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 3 to (46-53) Lightning Damage", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 2 to (10-11) Lightning Damage", ilevel: 3, family: "LightningDamage"},
    {regex: "f ar", isPrefix: false, name: "of Arcing", description: "(27-30)% increased Lightning Damage", ilevel: 76, family: "LightningDamagePercentage"},
    {regex: "rge$", isPrefix: false, name: "of Discharge", description: "(23-26)% increased Lightning Damage", ilevel: 64, family: "LightningDamagePercentage"},
    {regex: "olt", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "tic$", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "ks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "rom", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pest$", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "erh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "wo", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "mal", isPrefix: true, name: "Malicious", description: "Adds (98-149) to (183-280) Chaos Damage", ilevel: 83, family: "LocalChaosDamage"},
    {regex: "dic", isPrefix: true, name: "Dictator's", description: "(75-79)% increased Physical Damage|+(175-200) to Accuracy Rating", ilevel: 83, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "pero", isPrefix: true, name: "Emperor's", description: "(65-74)% increased Physical Damage|+(150-174) to Accuracy Rating", ilevel: 73, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "nq", isPrefix: true, name: "Conqueror's", description: "(55-64)% increased Physical Damage|+(124-149) to Accuracy Rating", ilevel: 60, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "ham", isPrefix: true, name: "Champion's", description: "(45-54)% increased Physical Damage|+(98-123) to Accuracy Rating", ilevel: 46, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "rce", isPrefix: true, name: "Mercenary's", description: "(35-44)% increased Physical Damage|+(73-97) to Accuracy Rating", ilevel: 35, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^rea", isPrefix: true, name: "Reaver's", description: "(25-34)% increased Physical Damage|+(47-72) to Accuracy Rating", ilevel: 23, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^j", isPrefix: true, name: "Journeyman's", description: "(20-24)% increased Physical Damage|+(21-46) to Accuracy Rating", ilevel: 11, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "^sq", isPrefix: true, name: "Squire's", description: "(15-19)% increased Physical Damage|+(16-20) to Accuracy Rating", ilevel: 1, family: "LocalIncreasedPhysicalDamagePercentAndAccuracyRating"},
    {regex: "cil", isPrefix: true, name: "Merciless", description: "(170-179)% increased Physical Damage", ilevel: 83, family: "LocalPhysicalDamagePercent"},
    {regex: "yr", isPrefix: true, name: "Tyrannical", description: "(155-169)% increased Physical Damage", ilevel: 73, family: "LocalPhysicalDamagePercent"},
    {regex: "cru", isPrefix: true, name: "Cruel", description: "(135-154)% increased Physical Damage", ilevel: 60, family: "LocalPhysicalDamagePercent"},
    {regex: "oo", isPrefix: true, name: "Bloodthirsty", description: "(110-134)% increased Physical Damage", ilevel: 46, family: "LocalPhysicalDamagePercent"},
    {regex: "^vi", isPrefix: true, name: "Vicious", description: "(85-109)% increased Physical Damage", ilevel: 35, family: "LocalPhysicalDamagePercent"},
    {regex: "wic", isPrefix: true, name: "Wicked", description: "(65-84)% increased Physical Damage", ilevel: 23, family: "LocalPhysicalDamagePercent"},
    {regex: "^se", isPrefix: true, name: "Serrated", description: "(50-64)% increased Physical Damage", ilevel: 11, family: "LocalPhysicalDamagePercent"},
    {regex: "vy", isPrefix: true, name: "Heavy", description: "(40-49)% increased Physical Damage", ilevel: 1, family: "LocalPhysicalDamagePercent"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^thi", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "oy", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (34-47) to (72-84) Physical Damage", ilevel: 77, family: "PhysicalDamage"},
    {regex: "ere", isPrefix: true, name: "Tempered", description: "Adds (30-40) to (63-73) Physical Damage", ilevel: 65, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (25-33) to (52-61) Physical Damage", ilevel: 54, family: "PhysicalDamage"},
    {regex: "nea", isPrefix: true, name: "Annealed", description: "Adds (20-28) to (43-51) Physical Damage", ilevel: 46, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (16-22) to (35-40) Physical Damage", ilevel: 36, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (13-17) to (28-32) Physical Damage", ilevel: 29, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (10-13) to (21-25) Physical Damage", ilevel: 21, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (6-8) to (12-15) Physical Damage", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 2 to (4-5) Physical Damage", ilevel: 2, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sla", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "lad", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "tant$", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "ox", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Fishing Rods" : [
    {regex: "fl", isPrefix: false, name: "of Flight", description: "(30-50)% increased Fishing Range", ilevel: 1, family: "FishingCastDistance"},
    {regex: "sn", isPrefix: false, name: "of Snaring", description: "Karui Stone Hook", ilevel: 1, family: "FishingHookType"},
    {regex: "il", isPrefix: true, name: "Filigree", description: "(20-40)% increased Fishing Line Strength", ilevel: 1, family: "FishingLineStrength"},
    {regex: "^al", isPrefix: true, name: "Alluring", description: "Rhoa Feather Lure", ilevel: 1, family: "FishingLureType"},
    {regex: "lm", isPrefix: true, name: "Calming", description: "(15-30)% reduced Fishing Pool Consumption", ilevel: 1, family: "FishingPoolConsumption"},
    {regex: "fa", isPrefix: false, name: "of Fascination", description: "(15-20)% increased Quantity of Fish Caught", ilevel: 1, family: "FishingQuantity"},
    {regex: "bo", isPrefix: false, name: "of Bounty", description: "(25-40)% increased Rarity of Fish Caught", ilevel: 1, family: "FishingRarity"},
    {regex: "sti", isPrefix: false, name: "of Casting", description: "(24-28)% increased Cast Speed", ilevel: 10, family: "IncreasedCastSpeed"}
  ],
  "Amulets" : [
    {regex: "ltiv", isPrefix: false, name: "of the Multiverse", description: "+(33-35) to all Attributes", ilevel: 85, family: "AllAttributes"},
    {regex: "nfi", isPrefix: false, name: "of the Infinite", description: "+(29-32) to all Attributes", ilevel: 77, family: "AllAttributes"},
    {regex: "uni", isPrefix: false, name: "of the Universe", description: "+(25-28) to all Attributes", ilevel: 66, family: "AllAttributes"},
    {regex: "xy", isPrefix: false, name: "of the Galaxy", description: "+(21-24) to all Attributes", ilevel: 55, family: "AllAttributes"},
    {regex: "eav", isPrefix: false, name: "of the Heavens", description: "+(17-20) to all Attributes", ilevel: 44, family: "AllAttributes"},
    {regex: "com", isPrefix: false, name: "of the Comet", description: "+(13-16) to all Attributes", ilevel: 33, family: "AllAttributes"},
    {regex: "eor", isPrefix: false, name: "of the Meteor", description: "+(9-12) to all Attributes", ilevel: 22, family: "AllAttributes"},
    {regex: "ky", isPrefix: false, name: "of the Sky", description: "+(5-8) to all Attributes", ilevel: 11, family: "AllAttributes"},
    {regex: "uds", isPrefix: false, name: "of the Clouds", description: "+(1-4) to all Attributes", ilevel: 1, family: "AllAttributes"},
    {regex: "span", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "ris", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (17-22) to (34-40) Cold Damage to Attacks", ilevel: 77, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (14-19) to (29-34) Cold Damage to Attacks", ilevel: 65, family: "ColdDamage"},
    {regex: "aci", isPrefix: true, name: "Glaciated", description: "Adds (12-16) to (24-28) Cold Damage to Attacks", ilevel: 53, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ezi", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "flo", isPrefix: false, name: "of Floe", description: "(23-26)% increased Cold Damage", ilevel: 64, family: "ColdDamagePercentage"},
    {regex: "me$", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "sno", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "seal", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "ncis", isPrefix: false, name: "of Incision", description: "(35-38)% increased Global Critical Strike Chance", ilevel: 72, family: "CriticalStrikeChanceIncrease"},
    {regex: "net", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Global Critical Strike Chance", ilevel: 58, family: "CriticalStrikeChanceIncrease"},
    {regex: "rup", isPrefix: false, name: "of Rupturing", description: "(25-29)% increased Global Critical Strike Chance", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "erci", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Global Critical Strike Chance", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Global Critical Strike Chance", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Global Critical Strike Chance", ilevel: 5, family: "CriticalStrikeChanceIncrease"},
    {regex: "tru", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Global Critical Strike Multiplier", ilevel: 74, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Global Critical Strike Multiplier", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Global Critical Strike Multiplier", ilevel: 45, family: "CriticalStrikeMultiplier"},
    {regex: "rage", isPrefix: false, name: "of Rage", description: "+(20-24)% to Global Critical Strike Multiplier", ilevel: 31, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(13-19)% to Global Critical Strike Multiplier", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "f ir", isPrefix: false, name: "of Ire", description: "+(8-12)% to Global Critical Strike Multiplier", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "wind", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "phan", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "jag", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "nth", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ngo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "una", isPrefix: true, name: "Unassailable", description: "(20-22)% increased maximum Energy Shield", ilevel: 77, family: "EnergyShieldPercent"},
    {regex: "omi", isPrefix: true, name: "Indomitable", description: "(17-19)% increased maximum Energy Shield", ilevel: 70, family: "EnergyShieldPercent"},
    {regex: "dau", isPrefix: true, name: "Dauntless", description: "(14-16)% increased maximum Energy Shield", ilevel: 56, family: "EnergyShieldPercent"},
    {regex: "^fe", isPrefix: true, name: "Fearless", description: "(11-13)% increased maximum Energy Shield", ilevel: 42, family: "EnergyShieldPercent"},
    {regex: "eso", isPrefix: true, name: "Resolute", description: "(8-10)% increased maximum Energy Shield", ilevel: 30, family: "EnergyShieldPercent"},
    {regex: "ron", isPrefix: true, name: "Strong-Willed", description: "(5-7)% increased maximum Energy Shield", ilevel: 18, family: "EnergyShieldPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(2-4)% increased maximum Energy Shield", ilevel: 3, family: "EnergyShieldPercent"},
    {regex: "vap", isPrefix: true, name: "Vaporous", description: "(33-36)% increased Evasion Rating", ilevel: 77, family: "EvasionRatingPercent"},
    {regex: "^pha", isPrefix: true, name: "Phased", description: "(29-32)% increased Evasion Rating", ilevel: 70, family: "EvasionRatingPercent"},
    {regex: "rr", isPrefix: true, name: "Blurred", description: "(24-28)% increased Evasion Rating", ilevel: 56, family: "EvasionRatingPercent"},
    {regex: "^fle", isPrefix: true, name: "Fleet", description: "(19-23)% increased Evasion Rating", ilevel: 42, family: "EvasionRatingPercent"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "(14-18)% increased Evasion Rating", ilevel: 30, family: "EvasionRatingPercent"},
    {regex: "dan", isPrefix: true, name: "Dancer's", description: "(9-13)% increased Evasion Rating", ilevel: 19, family: "EvasionRatingPercent"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "(4-8)% increased Evasion Rating", ilevel: 2, family: "EvasionRatingPercent"},
    {regex: "^cre", isPrefix: true, name: "Cremating", description: "Adds (19-25) to (39-45) Fire Damage to Attacks", ilevel: 76, family: "FireDamage"},
    {regex: "blas", isPrefix: true, name: "Blasting", description: "Adds (16-22) to (32-38) Fire Damage to Attacks", ilevel: 64, family: "FireDamage"},
    {regex: "ncin", isPrefix: true, name: "Incinerating", description: "Adds (13-18) to (27-31) Fire Damage to Attacks", ilevel: 52, family: "FireDamage"},
    {regex: "^sco", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "lami", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "oul", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "mmo", isPrefix: false, name: "of Immolation", description: "(23-26)% increased Fire Damage", ilevel: 64, family: "FireDamagePercentage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "cind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "oa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "emb", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "olc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rna", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "sso", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "mel", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "imo", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "rov", isPrefix: true, name: "Provocateur's", description: "+1 to Level of all Chaos Skill Gems", ilevel: 75, family: "GlobalDamageTypeGemLevel"},
    {regex: "dw", isPrefix: true, name: "Rimedweller's", description: "+1 to Level of all Cold Skill Gems", ilevel: 75, family: "GlobalDamageTypeGemLevel"},
    {regex: "vu", isPrefix: true, name: "Vulcanist's", description: "+1 to Level of all Fire Skill Gems", ilevel: 75, family: "GlobalDamageTypeGemLevel"},
    {regex: "rmb", isPrefix: true, name: "Stormbrewer's", description: "+1 to Level of all Lightning Skill Gems", ilevel: 75, family: "GlobalDamageTypeGemLevel"},
    {regex: "eh", isPrefix: true, name: "Behemoth's", description: "+1 to Level of all Physical Skill Gems", ilevel: 75, family: "GlobalDamageTypeGemLevel"},
    {regex: "xa", isPrefix: true, name: "Exalter's", description: "+1 to Level of all Skill Gems", ilevel: 75, family: "GlobalSkillGemLevel"},
    {regex: "ranger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "ksm", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "leg", isPrefix: false, name: "of Legerdemain", description: "(17-20)% increased Cast Speed", ilevel: 40, family: "IncreasedCastSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(13-16)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "imb", isPrefix: false, name: "of Nimbleness", description: "(9-12)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(5-8)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "zl", isPrefix: true, name: "Dazzling", description: "+(48-51) to maximum Energy Shield", ilevel: 80, family: "IncreasedEnergyShield"},
    {regex: "end", isPrefix: true, name: "Resplendent", description: "+(44-47) to maximum Energy Shield", ilevel: 74, family: "IncreasedEnergyShield"},
    {regex: "nca", isPrefix: true, name: "Incandescent", description: "+(38-43) to maximum Energy Shield", ilevel: 68, family: "IncreasedEnergyShield"},
    {regex: "sci", isPrefix: true, name: "Scintillating", description: "+(32-37) to maximum Energy Shield", ilevel: 59, family: "IncreasedEnergyShield"},
    {regex: "laz", isPrefix: true, name: "Blazing", description: "+(27-31) to maximum Energy Shield", ilevel: 50, family: "IncreasedEnergyShield"},
    {regex: "^se", isPrefix: true, name: "Seething", description: "+(23-26) to maximum Energy Shield", ilevel: 42, family: "IncreasedEnergyShield"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(20-22) to maximum Energy Shield", ilevel: 35, family: "IncreasedEnergyShield"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(16-19) to maximum Energy Shield", ilevel: 29, family: "IncreasedEnergyShield"},
    {regex: "owi", isPrefix: true, name: "Glowing", description: "+(13-15) to maximum Energy Shield", ilevel: 23, family: "IncreasedEnergyShield"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(9-12) to maximum Energy Shield", ilevel: 17, family: "IncreasedEnergyShield"},
    {regex: "lim", isPrefix: true, name: "Glimmering", description: "+(4-8) to maximum Energy Shield", ilevel: 11, family: "IncreasedEnergyShield"},
    {regex: "^shi", isPrefix: true, name: "Shining", description: "+(1-3) to maximum Energy Shield", ilevel: 3, family: "IncreasedEnergyShield"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^vi", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^ul", isPrefix: true, name: "Ultramarine", description: "+(74-78) to maximum Mana", ilevel: 85, family: "IncreasedMana"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "^blue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "egn", isPrefix: true, name: "Impregnable", description: "(33-36)% increased Armour", ilevel: 77, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(29-32)% increased Armour", ilevel: 70, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "hic", isPrefix: true, name: "Thickened", description: "(24-28)% increased Armour", ilevel: 56, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "utt", isPrefix: true, name: "Buttressed", description: "(19-23)% increased Armour", ilevel: 42, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "^lo", isPrefix: true, name: "Lobstered", description: "(14-18)% increased Armour", ilevel: 30, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "ay", isPrefix: true, name: "Layered", description: "(9-13)% increased Armour", ilevel: 18, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "rei", isPrefix: true, name: "Reinforced", description: "(4-8)% increased Armour", ilevel: 2, family: "IncreasedPhysicalDamageReductionRatingPercent"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ov", isPrefix: true, name: "Overpowering", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nl", isPrefix: true, name: "Unleashed", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^em", isPrefix: true, name: "Empowering", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nfu", isPrefix: true, name: "Infusing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ca", isPrefix: true, name: "Catalysing", description: "(5-10)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "irt", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "stu", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xca", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "og", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "s'", isPrefix: true, name: "Perandus'", description: "(25-28)% increased Rarity of Items found", ilevel: 84, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "n'", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "egr", isPrefix: false, name: "of Regrowth", description: "Gain 4 Life per Enemy Hit with Attacks", ilevel: 30, family: "LifeGainPerTarget"},
    {regex: "esto", isPrefix: false, name: "of Restoration", description: "Gain 3 Life per Enemy Hit with Attacks", ilevel: 20, family: "LifeGainPerTarget"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vam", isPrefix: true, name: "Vampire's", description: "(1-1.2)% of Physical Attack Damage Leeched as Life", ilevel: 70, family: "LifeLeech"},
    {regex: "y'", isPrefix: true, name: "Lamprey's", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Life", ilevel: 60, family: "LifeLeech"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "hoe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "ysl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "oll", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "wor", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "liz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "wt$", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "cut", isPrefix: true, name: "Electrocuting", description: "Adds (3-7) to (68-72) Lightning Damage to Attacks", ilevel: 76, family: "LightningDamage"},
    {regex: "^di", isPrefix: true, name: "Discharging", description: "Adds (3-6) to (57-61) Lightning Damage to Attacks", ilevel: 64, family: "LightningDamage"},
    {regex: "cki", isPrefix: true, name: "Shocking", description: "Adds (2-5) to (47-50) Lightning Damage to Attacks", ilevel: 52, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "rge$", isPrefix: false, name: "of Discharge", description: "(23-26)% increased Lightning Damage", ilevel: 64, family: "LightningDamagePercentage"},
    {regex: "olt", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "atic", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "rks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "torm$", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud$", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "ons", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "abs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^pa", isPrefix: true, name: "Parched", description: "(0.6-0.8)% of Physical Attack Damage Leeched as Mana", ilevel: 70, family: "ManaLeech"},
    {regex: "irs", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "rv", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eup", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "jo", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xci", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (11-15) to (22-26) Physical Damage to Attacks", ilevel: 76, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (9-12) to (19-22) Physical Damage to Attacks", ilevel: 64, family: "PhysicalDamage"},
    {regex: "raz", isPrefix: true, name: "Razor-sharp", description: "Adds (7-10) to (15-18) Physical Damage to Attacks", ilevel: 52, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (6-9) to (13-15) Physical Damage to Attacks", ilevel: 44, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (5-7) to (11-12) Physical Damage to Attacks", ilevel: 35, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "glin", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "d'", isPrefix: true, name: "Wizard's", description: "(23-26)% increased Spell Damage", ilevel: 76, family: "SpellDamage"},
    {regex: "hau", isPrefix: true, name: "Thaumaturgist's", description: "(18-22)% increased Spell Damage", ilevel: 56, family: "SpellDamage"},
    {regex: "rer", isPrefix: true, name: "Sorcerer's", description: "(13-17)% increased Spell Damage", ilevel: 38, family: "SpellDamage"},
    {regex: "ge'", isPrefix: true, name: "Mage's", description: "(8-12)% increased Spell Damage", ilevel: 20, family: "SpellDamage"},
    {regex: "ante", isPrefix: true, name: "Chanter's", description: "(3-7)% increased Spell Damage", ilevel: 5, family: "SpellDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "levi", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "lia", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wre", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"}
  ],
  "Rings" : [
    {regex: "ome", isPrefix: false, name: "of the Comet", description: "+(13-16) to all Attributes", ilevel: 33, family: "AllAttributes"},
    {regex: "ete", isPrefix: false, name: "of the Meteor", description: "+(9-12) to all Attributes", ilevel: 22, family: "AllAttributes"},
    {regex: "ky", isPrefix: false, name: "of the Sky", description: "+(5-8) to all Attributes", ilevel: 11, family: "AllAttributes"},
    {regex: "uds", isPrefix: false, name: "of the Clouds", description: "+(1-4) to all Attributes", ilevel: 1, family: "AllAttributes"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "sm$", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "cti", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "^en", isPrefix: true, name: "Entombing", description: "Adds (17-22) to (34-40) Cold Damage to Attacks", ilevel: 77, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (14-19) to (29-34) Cold Damage to Attacks", ilevel: 65, family: "ColdDamage"},
    {regex: "gla", isPrefix: true, name: "Glaciated", description: "Adds (12-16) to (24-28) Cold Damage to Attacks", ilevel: 53, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "rim", isPrefix: false, name: "of Rime", description: "(18-22)% increased Cold Damage", ilevel: 50, family: "ColdDamagePercentage"},
    {regex: "f ic", isPrefix: false, name: "of Ice", description: "(13-17)% increased Cold Damage", ilevel: 36, family: "ColdDamagePercentage"},
    {regex: "sle", isPrefix: false, name: "of Sleet", description: "(8-12)% increased Cold Damage", ilevel: 24, family: "ColdDamagePercentage"},
    {regex: "sno", isPrefix: false, name: "of Snow", description: "(3-7)% increased Cold Damage", ilevel: 12, family: "ColdDamagePercentage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "e ic", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "in$", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "hb", isPrefix: false, name: "of Fleshbinding", description: "(13-15)% of Damage taken Recouped as Life", ilevel: 79, family: "DamageTakenGainedAsLife"},
    {regex: "sut", isPrefix: false, name: "of Suturing", description: "(10-12)% of Damage taken Recouped as Life", ilevel: 68, family: "DamageTakenGainedAsLife"},
    {regex: "itc", isPrefix: false, name: "of Stitching", description: "(7-9)% of Damage taken Recouped as Life", ilevel: 56, family: "DamageTakenGainedAsLife"},
    {regex: "nda", isPrefix: false, name: "of Bandaging", description: "(4-6)% of Damage taken Recouped as Life", ilevel: 44, family: "DamageTakenGainedAsLife"},
    {regex: "wind", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "phan", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fa", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (19-25) to (39-45) Fire Damage to Attacks", ilevel: 76, family: "FireDamage"},
    {regex: "blas", isPrefix: true, name: "Blasting", description: "Adds (16-22) to (32-38) Fire Damage to Attacks", ilevel: 64, family: "FireDamage"},
    {regex: "nci", isPrefix: true, name: "Incinerating", description: "Adds (13-18) to (27-31) Fire Damage to Attacks", ilevel: 52, family: "FireDamage"},
    {regex: "orc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "^fla", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "mes", isPrefix: false, name: "of Flames", description: "(18-22)% increased Fire Damage", ilevel: 50, family: "FireDamagePercentage"},
    {regex: "cind", isPrefix: false, name: "of Cinders", description: "(13-17)% increased Fire Damage", ilevel: 36, family: "FireDamagePercentage"},
    {regex: "coa", isPrefix: false, name: "of Coals", description: "(8-12)% increased Fire Damage", ilevel: 22, family: "FireDamagePercentage"},
    {regex: "emb", isPrefix: false, name: "of Embers", description: "(3-7)% increased Fire Damage", ilevel: 8, family: "FireDamagePercentage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "olc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "ksm", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "xpe", isPrefix: false, name: "of Expertise", description: "(13-16)% increased Cast Speed", ilevel: 30, family: "IncreasedCastSpeed"},
    {regex: "imb", isPrefix: false, name: "of Nimbleness", description: "(9-12)% increased Cast Speed", ilevel: 15, family: "IncreasedCastSpeed"},
    {regex: "tale", isPrefix: false, name: "of Talent", description: "(5-8)% increased Cast Speed", ilevel: 2, family: "IncreasedCastSpeed"},
    {regex: "esp", isPrefix: true, name: "Resplendent", description: "+(44-47) to maximum Energy Shield", ilevel: 74, family: "IncreasedEnergyShield"},
    {regex: "nca", isPrefix: true, name: "Incandescent", description: "+(38-43) to maximum Energy Shield", ilevel: 68, family: "IncreasedEnergyShield"},
    {regex: "sci", isPrefix: true, name: "Scintillating", description: "+(32-37) to maximum Energy Shield", ilevel: 59, family: "IncreasedEnergyShield"},
    {regex: "laz", isPrefix: true, name: "Blazing", description: "+(27-31) to maximum Energy Shield", ilevel: 50, family: "IncreasedEnergyShield"},
    {regex: "^se", isPrefix: true, name: "Seething", description: "+(23-26) to maximum Energy Shield", ilevel: 42, family: "IncreasedEnergyShield"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(20-22) to maximum Energy Shield", ilevel: 35, family: "IncreasedEnergyShield"},
    {regex: "^rad", isPrefix: true, name: "Radiating", description: "+(16-19) to maximum Energy Shield", ilevel: 29, family: "IncreasedEnergyShield"},
    {regex: "owi", isPrefix: true, name: "Glowing", description: "+(13-15) to maximum Energy Shield", ilevel: 23, family: "IncreasedEnergyShield"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(9-12) to maximum Energy Shield", ilevel: 17, family: "IncreasedEnergyShield"},
    {regex: "lim", isPrefix: true, name: "Glimmering", description: "+(4-8) to maximum Energy Shield", ilevel: 11, family: "IncreasedEnergyShield"},
    {regex: "^shi", isPrefix: true, name: "Shining", description: "+(1-3) to maximum Energy Shield", ilevel: 3, family: "IncreasedEnergyShield"},
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(151-170) to Evasion Rating", ilevel: 84, family: "IncreasedEvasionRating"},
    {regex: "^pha", isPrefix: true, name: "Phased", description: "+(121-150) to Evasion Rating", ilevel: 72, family: "IncreasedEvasionRating"},
    {regex: "rr", isPrefix: true, name: "Blurred", description: "+(81-120) to Evasion Rating", ilevel: 58, family: "IncreasedEvasionRating"},
    {regex: "^fle", isPrefix: true, name: "Fleet", description: "+(61-80) to Evasion Rating", ilevel: 42, family: "IncreasedEvasionRating"},
    {regex: "t'", isPrefix: true, name: "Acrobat's", description: "+(36-60) to Evasion Rating", ilevel: 29, family: "IncreasedEvasionRating"},
    {regex: "r'", isPrefix: true, name: "Dancer's", description: "+(11-35) to Evasion Rating", ilevel: 18, family: "IncreasedEvasionRating"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(3-10) to Evasion Rating", ilevel: 1, family: "IncreasedEvasionRating"},
    {regex: "^vi", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^ul", isPrefix: true, name: "Ultramarine", description: "+(74-78) to maximum Mana", ilevel: 85, family: "IncreasedMana"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "^sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "ov", isPrefix: true, name: "Overpowering", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nl", isPrefix: true, name: "Unleashed", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^em", isPrefix: true, name: "Empowering", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "nf", isPrefix: true, name: "Infusing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^ca", isPrefix: true, name: "Catalysing", description: "(5-10)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "eni", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "stu", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xca", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "hae", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "s'", isPrefix: true, name: "Perandus'", description: "(25-28)% increased Rarity of Items found", ilevel: 84, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "n'", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "ysl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "atw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "f sh", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "roc", isPrefix: true, name: "Electrocuting", description: "Adds (3-7) to (68-72) Lightning Damage to Attacks", ilevel: 76, family: "LightningDamage"},
    {regex: "^di", isPrefix: true, name: "Discharging", description: "Adds (3-6) to (57-61) Lightning Damage to Attacks", ilevel: 64, family: "LightningDamage"},
    {regex: "sho", isPrefix: true, name: "Shocking", description: "Adds (2-5) to (47-50) Lightning Damage to Attacks", ilevel: 52, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "olt", isPrefix: false, name: "of Voltage", description: "(18-22)% increased Lightning Damage", ilevel: 50, family: "LightningDamagePercentage"},
    {regex: "ric", isPrefix: false, name: "of Electricity", description: "(13-17)% increased Lightning Damage", ilevel: 36, family: "LightningDamagePercentage"},
    {regex: "tat", isPrefix: false, name: "of Static", description: "(8-12)% increased Lightning Damage", ilevel: 23, family: "LightningDamagePercentage"},
    {regex: "rks$", isPrefix: false, name: "of Sparks", description: "(3-7)% increased Lightning Damage", ilevel: 10, family: "LightningDamagePercentage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "mpe", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud$", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "rv", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "jo", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xci", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (6-9) to (13-15) Physical Damage to Attacks", ilevel: 44, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (5-7) to (11-12) Physical Damage to Attacks", ilevel: 35, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "poli", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "glin", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "lia", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"}
  ],
  "Belts" : [
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "erf", isPrefix: false, name: "of Overflowing", description: "(35-40)% increased Flask Charges gained", ilevel: 84, family: "BeltFlaskCharges"},
    {regex: "bri", isPrefix: false, name: "of Brimming", description: "(29-34)% increased Flask Charges gained", ilevel: 70, family: "BeltFlaskCharges"},
    {regex: "pou", isPrefix: false, name: "of Pouring", description: "(23-28)% increased Flask Charges gained", ilevel: 48, family: "BeltFlaskCharges"},
    {regex: "rep", isPrefix: false, name: "of Replenishing", description: "(17-22)% increased Flask Charges gained", ilevel: 32, family: "BeltFlaskCharges"},
    {regex: "toc", isPrefix: false, name: "of Restocking", description: "(11-16)% increased Flask Charges gained", ilevel: 16, family: "BeltFlaskCharges"},
    {regex: "ip", isPrefix: false, name: "of Sipping", description: "(10-20)% reduced Flask Charges used", ilevel: 3, family: "BeltFlaskCharges"},
    {regex: "fil", isPrefix: false, name: "of Refilling", description: "(5-10)% increased Flask Charges gained", ilevel: 2, family: "BeltFlaskCharges"},
    {regex: "rev", isPrefix: false, name: "of Reveling", description: "(28-33)% increased Flask Effect Duration", ilevel: 82, family: "BeltFlaskDuration"},
    {regex: "rel", isPrefix: false, name: "of Relishing", description: "(22-27)% increased Flask Effect Duration", ilevel: 66, family: "BeltFlaskDuration"},
    {regex: "sav", isPrefix: false, name: "of Savouring", description: "(16-21)% increased Flask Effect Duration", ilevel: 50, family: "BeltFlaskDuration"},
    {regex: "tas", isPrefix: false, name: "of Tasting", description: "(10-15)% increased Flask Effect Duration", ilevel: 34, family: "BeltFlaskDuration"},
    {regex: "ip", isPrefix: false, name: "of Sipping", description: "(4-9)% increased Flask Effect Duration", ilevel: 8, family: "BeltFlaskDuration"},
    {regex: "nerati", isPrefix: true, name: "Regenerating", description: "(35-40)% increased Flask Life Recovery rate", ilevel: 77, family: "BeltFlaskRecoveryRate"},
    {regex: "ins", isPrefix: true, name: "Inspiring", description: "(35-40)% increased Flask Mana Recovery rate", ilevel: 77, family: "BeltFlaskRecoveryRate"},
    {regex: "ej", isPrefix: true, name: "Rejuvenating", description: "(29-34)% increased Flask Life Recovery rate", ilevel: 63, family: "BeltFlaskRecoveryRate"},
    {regex: "lv", isPrefix: true, name: "Galvanizing", description: "(29-34)% increased Flask Mana Recovery rate", ilevel: 63, family: "BeltFlaskRecoveryRate"},
    {regex: "fr", isPrefix: true, name: "Refreshing", description: "(23-28)% increased Flask Life Recovery rate", ilevel: 49, family: "BeltFlaskRecoveryRate"},
    {regex: "xc", isPrefix: true, name: "Exciting", description: "(23-28)% increased Flask Mana Recovery rate", ilevel: 49, family: "BeltFlaskRecoveryRate"},
    {regex: "ewi", isPrefix: true, name: "Renewing", description: "(17-22)% increased Flask Life Recovery rate", ilevel: 35, family: "BeltFlaskRecoveryRate"},
    {regex: "rte", isPrefix: true, name: "Heartening", description: "(17-22)% increased Flask Mana Recovery rate", ilevel: 35, family: "BeltFlaskRecoveryRate"},
    {regex: "^rec", isPrefix: true, name: "Recovering", description: "(11-16)% increased Flask Life Recovery rate", ilevel: 21, family: "BeltFlaskRecoveryRate"},
    {regex: "rr", isPrefix: true, name: "Stirring", description: "(11-16)% increased Flask Mana Recovery rate", ilevel: 21, family: "BeltFlaskRecoveryRate"},
    {regex: "tori", isPrefix: true, name: "Restoring", description: "(5-10)% increased Flask Life Recovery rate", ilevel: 5, family: "BeltFlaskRecoveryRate"},
    {regex: "af", isPrefix: true, name: "Affecting", description: "(5-10)% increased Flask Mana Recovery rate", ilevel: 5, family: "BeltFlaskRecoveryRate"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "lc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "gn", isPrefix: true, name: "Magnifying", description: "Flasks applied to you have (10-12)% increased Effect", ilevel: 85, family: "FlaskEffect"},
    {regex: "ens", isPrefix: true, name: "Condensing", description: "Flasks applied to you have (7-9)% increased Effect", ilevel: 65, family: "FlaskEffect"},
    {regex: "^di", isPrefix: true, name: "Distilling", description: "Flasks applied to you have (4-6)% increased Effect", ilevel: 45, family: "FlaskEffect"},
    {regex: "zz", isPrefix: true, name: "Dazzling", description: "+(48-51) to maximum Energy Shield", ilevel: 80, family: "IncreasedEnergyShield"},
    {regex: "esp", isPrefix: true, name: "Resplendent", description: "+(44-47) to maximum Energy Shield", ilevel: 74, family: "IncreasedEnergyShield"},
    {regex: "des", isPrefix: true, name: "Incandescent", description: "+(38-43) to maximum Energy Shield", ilevel: 68, family: "IncreasedEnergyShield"},
    {regex: "^sc", isPrefix: true, name: "Scintillating", description: "+(32-37) to maximum Energy Shield", ilevel: 59, family: "IncreasedEnergyShield"},
    {regex: "bla", isPrefix: true, name: "Blazing", description: "+(27-31) to maximum Energy Shield", ilevel: 50, family: "IncreasedEnergyShield"},
    {regex: "^se", isPrefix: true, name: "Seething", description: "+(23-26) to maximum Energy Shield", ilevel: 42, family: "IncreasedEnergyShield"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(20-22) to maximum Energy Shield", ilevel: 35, family: "IncreasedEnergyShield"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(16-19) to maximum Energy Shield", ilevel: 29, family: "IncreasedEnergyShield"},
    {regex: "^glo", isPrefix: true, name: "Glowing", description: "+(13-15) to maximum Energy Shield", ilevel: 23, family: "IncreasedEnergyShield"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(9-12) to maximum Energy Shield", ilevel: 17, family: "IncreasedEnergyShield"},
    {regex: "lim", isPrefix: true, name: "Glimmering", description: "+(4-8) to maximum Energy Shield", ilevel: 11, family: "IncreasedEnergyShield"},
    {regex: "^sh", isPrefix: true, name: "Shining", description: "+(1-3) to maximum Energy Shield", ilevel: 3, family: "IncreasedEnergyShield"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "lu", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "nv", isPrefix: true, name: "Enveloped", description: "+(461-540) to Armour", ilevel: 86, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "enc", isPrefix: true, name: "Encased", description: "+(401-460) to Armour", ilevel: 83, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "car", isPrefix: true, name: "Carapaced", description: "+(323-400) to Armour", ilevel: 71, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(139-322) to Armour", ilevel: 57, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "^fo", isPrefix: true, name: "Fortified", description: "+(61-138) to Armour", ilevel: 44, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-60) to Armour", ilevel: 30, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "^stu", isPrefix: true, name: "Studded", description: "+(11-35) to Armour", ilevel: 18, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "cq", isPrefix: true, name: "Lacquered", description: "+(3-10) to Armour", ilevel: 1, family: "IncreasedPhysicalDamageReductionRating"},
    {regex: "dev", isPrefix: true, name: "Devastating", description: "(43-50)% increased Elemental Damage with Attack Skills", ilevel: 86, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "rp", isPrefix: true, name: "Overpowering", description: "(37-42)% increased Elemental Damage with Attack Skills", ilevel: 81, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^u", isPrefix: true, name: "Unleashed", description: "(31-36)% increased Elemental Damage with Attack Skills", ilevel: 60, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "^em", isPrefix: true, name: "Empowering", description: "(21-30)% increased Elemental Damage with Attack Skills", ilevel: 30, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "inf", isPrefix: true, name: "Infusing", description: "(11-20)% increased Elemental Damage with Attack Skills", ilevel: 15, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "cat", isPrefix: true, name: "Catalysing", description: "(5-10)% increased Elemental Damage with Attack Skills", ilevel: 4, family: "IncreasedWeaponElementalDamagePercent"},
    {regex: "up", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "ysl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "liz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "wt", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "mpe", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "torm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "lou", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "ay", isPrefix: false, name: "of the Godslayer", description: "+(56-60) to Strength", ilevel: 85, family: "Strength"},
    {regex: "ds$", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "oli", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "tag", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "amm", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "dazi", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "mpa", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "ste", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"},
    {regex: "gla", isPrefix: false, name: "of the Gladiator", description: "(14-15)% reduced Enemy Stun Threshold", ilevel: 58, family: "StunThresholdReduction"},
    {regex: "mb", isPrefix: false, name: "of the Combatant", description: "(12-13)% reduced Enemy Stun Threshold", ilevel: 44, family: "StunThresholdReduction"},
    {regex: "bo", isPrefix: false, name: "of the Boxer", description: "(10-11)% reduced Enemy Stun Threshold", ilevel: 30, family: "StunThresholdReduction"},
    {regex: "wl", isPrefix: false, name: "of the Brawler", description: "(8-9)% reduced Enemy Stun Threshold", ilevel: 20, family: "StunThresholdReduction"},
    {regex: "pug", isPrefix: false, name: "of the Pugilist", description: "(5-7)% reduced Enemy Stun Threshold", ilevel: 5, family: "StunThresholdReduction"}
  ],
  "Shields (str)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "pri", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "tho", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "wi", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "itu", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "lv", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "unm", isPrefix: true, name: "Unmoving", description: "+(301-400) to Armour", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "^ab", isPrefix: true, name: "Abating", description: "+(201-300) to Armour", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "nv", isPrefix: true, name: "Enveloped", description: "+(151-200) to Armour", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "nca", isPrefix: true, name: "Encased", description: "+(121-150) to Armour", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^ca", isPrefix: true, name: "Carapaced", description: "+(102-120) to Armour", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(83-101) to Armour", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "^fo", isPrefix: true, name: "Fortified", description: "+(64-82) to Armour", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-63) to Armour", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "^stu", isPrefix: true, name: "Studded", description: "+(13-35) to Armour", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "cq", isPrefix: true, name: "Lacquered", description: "+(6-12) to Armour", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "n'", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "net", isPrefix: true, name: "Impenetrable", description: "(101-110)% increased Armour", ilevel: 86, family: "DefencesPercent"},
    {regex: "gn", isPrefix: true, name: "Impregnable", description: "(92-100)% increased Armour", ilevel: 84, family: "DefencesPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(80-91)% increased Armour", ilevel: 72, family: "DefencesPercent"},
    {regex: "ken", isPrefix: true, name: "Thickened", description: "(68-79)% increased Armour", ilevel: 60, family: "DefencesPercent"},
    {regex: "^bu", isPrefix: true, name: "Buttressed", description: "(56-67)% increased Armour", ilevel: 42, family: "DefencesPercent"},
    {regex: "^lo", isPrefix: true, name: "Lobstered", description: "(43-55)% increased Armour", ilevel: 29, family: "DefencesPercent"},
    {regex: "^lay", isPrefix: true, name: "Layered", description: "(27-42)% increased Armour", ilevel: 17, family: "DefencesPercent"},
    {regex: "^rei", isPrefix: true, name: "Reinforced", description: "(15-26)% increased Armour", ilevel: 3, family: "DefencesPercent"},
    {regex: "h'", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour|(12-13)% increased Stun and Block Recovery", ilevel: 42, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour|(10-11)% increased Stun and Block Recovery", ilevel: 29, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour|(8-9)% increased Stun and Block Recovery", ilevel: 17, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour|(6-7)% increased Stun and Block Recovery", ilevel: 1, family: "DefencesPercentAndStunRecovery"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "ma$", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "lc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "urn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mb", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "te'", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "dur", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "nw", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "^wa", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^ad", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "nr", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "iv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "up", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "har", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "oth$", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "urs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "od$", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "dan", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "les", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "tru", isPrefix: false, name: "of Obstruction", description: "You take (51-60)% reduced Extra Damage from Critical Strikes", ilevel: 78, family: "ReducedExtraDamageFromCrits"},
    {regex: "erf", isPrefix: false, name: "of Interference", description: "You take (41-50)% reduced Extra Damage from Critical Strikes", ilevel: 67, family: "ReducedExtraDamageFromCrits"},
    {regex: "ade", isPrefix: false, name: "of Deadening", description: "You take (31-40)% reduced Extra Damage from Critical Strikes", ilevel: 45, family: "ReducedExtraDamageFromCrits"},
    {regex: "dul", isPrefix: false, name: "of Dulling", description: "You take (21-30)% reduced Extra Damage from Critical Strikes", ilevel: 33, family: "ReducedExtraDamageFromCrits"},
    {regex: "ns", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "ote", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "wat", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "^se", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "nl", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "nig", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "ya", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "ori", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "k sk", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Shields (dex)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "pri", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^spi", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "wil", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "for", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "lv", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "^li", isPrefix: true, name: "Lissome", description: "+(301-400) to Evasion Rating", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "adr", isPrefix: true, name: "Adroit", description: "+(201-300) to Evasion Rating", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "elu", isPrefix: true, name: "Elusory", description: "+(151-200) to Evasion Rating", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(121-150) to Evasion Rating", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "has", isPrefix: true, name: "Phased", description: "+(102-120) to Evasion Rating", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^bl", isPrefix: true, name: "Blurred", description: "+(83-101) to Evasion Rating", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Fleet", description: "+(64-82) to Evasion Rating", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "+(36-63) to Evasion Rating", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "^da", isPrefix: true, name: "Dancer's", description: "+(13-35) to Evasion Rating", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(6-12) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "ram", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "ab", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^il", isPrefix: true, name: "Illusion's", description: "(101-110)% increased Evasion Rating", ilevel: 86, family: "DefencesPercent"},
    {regex: "^mi", isPrefix: true, name: "Mirage's", description: "(92-100)% increased Evasion Rating", ilevel: 84, family: "DefencesPercent"},
    {regex: "^n", isPrefix: true, name: "Nightmare's", description: "(80-91)% increased Evasion Rating", ilevel: 72, family: "DefencesPercent"},
    {regex: "tas", isPrefix: true, name: "Phantasm's", description: "(68-79)% increased Evasion Rating", ilevel: 60, family: "DefencesPercent"},
    {regex: "wr", isPrefix: true, name: "Wraith's", description: "(56-67)% increased Evasion Rating", ilevel: 44, family: "DefencesPercent"},
    {regex: "pec", isPrefix: true, name: "Spectre's", description: "(43-55)% increased Evasion Rating", ilevel: 30, family: "DefencesPercent"},
    {regex: "^gh", isPrefix: true, name: "Ghost's", description: "(27-42)% increased Evasion Rating", ilevel: 19, family: "DefencesPercent"},
    {regex: "sha", isPrefix: true, name: "Shade's", description: "(15-26)% increased Evasion Rating", ilevel: 3, family: "DefencesPercent"},
    {regex: "gb", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion Rating|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion Rating|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "p'", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion Rating|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion Rating|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "^mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion Rating|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion Rating|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "nth", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mb", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "adi", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "skil", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "te'", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "dur", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "unw", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "rde", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^ada", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "nr", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "iv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fla", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "har", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "mam", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "urs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "rod", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "ped", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "dea", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "^se", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "nl", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "igm", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "rv", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "f ir", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Shields (int)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "pris", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "tal$", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "rny", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "ill$", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "rti", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "olv", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "des", isPrefix: true, name: "Incandescent", description: "+(77-90) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "sci", isPrefix: true, name: "Scintillating", description: "+(62-76) to maximum Energy Shield", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "zi", isPrefix: true, name: "Blazing", description: "+(50-61) to maximum Energy Shield", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Seething", description: "+(39-49) to maximum Energy Shield", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(31-38) to maximum Energy Shield", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(24-30) to maximum Energy Shield", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "glo", isPrefix: true, name: "Glowing", description: "+(17-23) to maximum Energy Shield", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "glit", isPrefix: true, name: "Glittering", description: "+(12-16) to maximum Energy Shield", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "lim", isPrefix: true, name: "Glimmering", description: "+(6-11) to maximum Energy Shield", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "shin", isPrefix: true, name: "Shining", description: "+(3-5) to maximum Energy Shield", ilevel: 3, family: "BaseLocalDefences"},
    {regex: "prie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "eac", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^ac", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "k'", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "seal", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "unf", isPrefix: true, name: "Unfaltering", description: "(101-110)% increased Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "una", isPrefix: true, name: "Unassailable", description: "(92-100)% increased Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "ndo", isPrefix: true, name: "Indomitable", description: "(80-91)% increased Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "tl", isPrefix: true, name: "Dauntless", description: "(68-79)% increased Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "rl", isPrefix: true, name: "Fearless", description: "(56-67)% increased Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "olu", isPrefix: true, name: "Resolute", description: "(43-55)% increased Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ong", isPrefix: true, name: "Strong-Willed", description: "(27-42)% increased Energy Shield", ilevel: 18, family: "DefencesPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(11-28)% increased Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 18, family: "DefencesPercentAndStunRecovery"},
    {regex: "^pi", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 3, family: "DefencesPercentAndStunRecovery"},
    {regex: "wind", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "gal", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "liv", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "ma$", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "ano", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "iln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "hel", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "mad", isPrefix: true, name: "Mad Lord's", description: "+1 to Level of all Chaos Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^fr", isPrefix: true, name: "Frost Singer's", description: "+1 to Level of all Cold Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^fl", isPrefix: true, name: "Flame Shaper's", description: "+1 to Level of all Fire Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "rha", isPrefix: true, name: "Thunderhand's", description: "+1 to Level of all Lightning Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "^li", isPrefix: true, name: "Lithomancer's", description: "+1 to Level of all Physical Spell Skill Gems", ilevel: 2, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "gor", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "let", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "ril", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^hal", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "pph", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "end", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "nw", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "^wa", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^ada", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "nr", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "ius", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "dig", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "stud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "atw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "liz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "wt", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rhe", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "liss", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "jo", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "ony", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "mam", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "furs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "od$", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "ped", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "eat", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "eer", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "hte", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "nig", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "ya", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "nm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "cala", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "dis", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "ick", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"},
    {regex: "l'", isPrefix: true, name: "Tul's", description: "(100-109)% increased Cold Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^x", isPrefix: true, name: "Xoph's", description: "(100-109)% increased Fire Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^es", isPrefix: true, name: "Esh's", description: "(100-109)% increased Lightning Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "^ru", isPrefix: true, name: "Runic", description: "(100-109)% increased Spell Damage", ilevel: 84, family: "WeaponCasterDamagePrefix"},
    {regex: "ryo", isPrefix: true, name: "Cryomancer's", description: "(85-99)% increased Cold Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "py", isPrefix: true, name: "Pyroclastic", description: "(85-99)% increased Fire Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "^io", isPrefix: true, name: "Ionising", description: "(85-99)% increased Lightning Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "yp", isPrefix: true, name: "Glyphic", description: "(85-99)% increased Spell Damage", ilevel: 64, family: "WeaponCasterDamagePrefix"},
    {regex: "tall", isPrefix: true, name: "Crystalline", description: "(70-84)% increased Cold Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^mag", isPrefix: true, name: "Magmatic", description: "(70-84)% increased Fire Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "^sm", isPrefix: true, name: "Smiting", description: "(70-84)% increased Lightning Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "cant", isPrefix: true, name: "Incanter's", description: "(70-84)% increased Spell Damage", ilevel: 58, family: "WeaponCasterDamagePrefix"},
    {regex: "hai", isPrefix: true, name: "Hailing", description: "(55-69)% increased Cold Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "^vo", isPrefix: true, name: "Volcanic", description: "(55-69)% increased Fire Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "iki", isPrefix: true, name: "Striking", description: "(55-69)% increased Lightning Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "cc", isPrefix: true, name: "Occultist's", description: "(55-69)% increased Spell Damage", ilevel: 46, family: "WeaponCasterDamagePrefix"},
    {regex: "sn", isPrefix: true, name: "Snowy", description: "(40-54)% increased Cold Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "^ca", isPrefix: true, name: "Cauterising", description: "(40-54)% increased Fire Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "cou", isPrefix: true, name: "Coursing", description: "(40-54)% increased Lightning Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "rof", isPrefix: true, name: "Professor's", description: "(40-54)% increased Spell Damage", ilevel: 35, family: "WeaponCasterDamagePrefix"},
    {regex: "^al", isPrefix: true, name: "Alpine", description: "(30-39)% increased Cold Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "^bli", isPrefix: true, name: "Blistering", description: "(30-39)% increased Fire Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "bol", isPrefix: true, name: "Bolting", description: "(30-39)% increased Lightning Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "sch", isPrefix: true, name: "Scholar's", description: "(30-39)% increased Spell Damage", ilevel: 23, family: "WeaponCasterDamagePrefix"},
    {regex: "biti", isPrefix: true, name: "Biting", description: "(20-29)% increased Cold Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "zz", isPrefix: true, name: "Sizzling", description: "(20-29)% increased Fire Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "^hi", isPrefix: true, name: "Hissing", description: "(20-29)% increased Lightning Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "ade", isPrefix: true, name: "Adept's", description: "(20-29)% increased Spell Damage", ilevel: 11, family: "WeaponCasterDamagePrefix"},
    {regex: "bitt", isPrefix: true, name: "Bitter", description: "(10-19)% increased Cold Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^sea", isPrefix: true, name: "Searing", description: "(10-19)% increased Fire Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "rged", isPrefix: true, name: "Charged", description: "(10-19)% increased Lightning Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"},
    {regex: "^ap", isPrefix: true, name: "Apprentice's", description: "(10-19)% increased Spell Damage", ilevel: 2, family: "WeaponCasterDamagePrefix"}
  ],
  "Shields (str, dex)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "pri", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "wil", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "for", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "lv", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "dap", isPrefix: true, name: "Adaptable", description: "+(221-300) to Armour|+(221-300) to Evasion Rating", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "sil", isPrefix: true, name: "Resilient", description: "+(146-220) to Armour|+(146-220) to Evasion Rating", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "dy", isPrefix: true, name: "Sturdy", description: "+(86-145) to Armour|+(86-145) to Evasion Rating", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "^dur", isPrefix: true, name: "Durable", description: "+(49-85) to Armour|+(49-85) to Evasion Rating", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "lex", isPrefix: true, name: "Flexible", description: "+(28-48) to Armour|+(28-48) to Evasion Rating", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Pliant", description: "+(10-27) to Armour|+(10-27) to Evasion Rating", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ppl", isPrefix: true, name: "Supple", description: "+(5-9) to Armour|+(5-9) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "m'", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rc", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "^oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xil", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^vic", isPrefix: true, name: "Victor's", description: "(101-110)% increased Armour and Evasion", ilevel: 86, family: "DefencesPercent"},
    {regex: "d'", isPrefix: true, name: "Legend's", description: "(92-100)% increased Armour and Evasion", ilevel: 84, family: "DefencesPercent"},
    {regex: "ero", isPrefix: true, name: "Hero's", description: "(80-91)% increased Armour and Evasion", ilevel: 72, family: "DefencesPercent"},
    {regex: "due", isPrefix: true, name: "Duelist's", description: "(68-79)% increased Armour and Evasion", ilevel: 60, family: "DefencesPercent"},
    {regex: "gl", isPrefix: true, name: "Gladiator's", description: "(56-67)% increased Armour and Evasion", ilevel: 44, family: "DefencesPercent"},
    {regex: "fen", isPrefix: true, name: "Fencer's", description: "(43-55)% increased Armour and Evasion", ilevel: 30, family: "DefencesPercent"},
    {regex: "wl", isPrefix: true, name: "Brawler's", description: "(27-42)% increased Armour and Evasion", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sc", isPrefix: true, name: "Scrapper's", description: "(15-26)% increased Armour and Evasion", ilevel: 3, family: "DefencesPercent"},
    {regex: "h'", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour and Evasion|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour and Evasion|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour and Evasion|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour and Evasion|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour and Evasion|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour and Evasion|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "nth", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "urn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "nm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mb", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "nes", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "skil", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "te'", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "alt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "uri", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "nw", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "^wa", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^adam", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "nr", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "iv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "har", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "oth$", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "urs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "rod", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "ped", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "eat", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "bs", isPrefix: false, name: "of Obstruction", description: "You take (51-60)% reduced Extra Damage from Critical Strikes", ilevel: 78, family: "ReducedExtraDamageFromCrits"},
    {regex: "erf", isPrefix: false, name: "of Interference", description: "You take (41-50)% reduced Extra Damage from Critical Strikes", ilevel: 67, family: "ReducedExtraDamageFromCrits"},
    {regex: "ade", isPrefix: false, name: "of Deadening", description: "You take (31-40)% reduced Extra Damage from Critical Strikes", ilevel: 45, family: "ReducedExtraDamageFromCrits"},
    {regex: "dul", isPrefix: false, name: "of Dulling", description: "You take (21-30)% reduced Extra Damage from Critical Strikes", ilevel: 33, family: "ReducedExtraDamageFromCrits"},
    {regex: "ns", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "ote", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "tc", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "^se", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "nl", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "nig", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "ya", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "ori", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Shields (str, int)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "ris", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "wil", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "rti", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "eso", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "ntl", isPrefix: true, name: "Saintly", description: "+(221-300) to Armour|+(61-72) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "ecr", isPrefix: true, name: "Consecrated", description: "+(146-220) to Armour|+(49-60) to maximum Energy Shield", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "^bea", isPrefix: true, name: "Beatified", description: "+(86-145) to Armour|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "hall", isPrefix: true, name: "Hallowed", description: "+(49-85) to Armour|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "nct", isPrefix: true, name: "Sanctified", description: "+(28-48) to Armour|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^an", isPrefix: true, name: "Anointed", description: "+(10-27) to Armour|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ble", isPrefix: true, name: "Blessed", description: "+(5-9) to Armour|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "ies", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "ab", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "s'", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "eac", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^ur", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "k'", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "r b", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "rpe", isPrefix: true, name: "Interpermeated", description: "(101-110)% increased Armour and Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "nsp", isPrefix: true, name: "Inspired", description: "(92-100)% increased Armour and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "rpo", isPrefix: true, name: "Interpolated", description: "(80-91)% increased Armour and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "ncu", isPrefix: true, name: "Inculcated", description: "(68-79)% increased Armour and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "nfu", isPrefix: true, name: "Infused", description: "(56-67)% increased Armour and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nst", isPrefix: true, name: "Instilled", description: "(43-55)% increased Armour and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ngr", isPrefix: true, name: "Ingrained", description: "(27-42)% increased Armour and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "xe", isPrefix: true, name: "Infixed", description: "(15-26)% increased Armour and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Armour and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Armour and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "a'", isPrefix: true, name: "Naga's", description: "(27-32)% increased Armour and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Armour and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Armour and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^pi", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Armour and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "win", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "gal", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "liv", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "urn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mb", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "oro", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^f", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "let", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "iri", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "sang", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "hale", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "^ma", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "pp", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "end", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "nw", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "^wa", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^ad", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "nr", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "dig", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "stud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "arf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "jo", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "ony", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "mm", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "urs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "od$", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "ped", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "deat", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "bs", isPrefix: false, name: "of Obstruction", description: "You take (51-60)% reduced Extra Damage from Critical Strikes", ilevel: 78, family: "ReducedExtraDamageFromCrits"},
    {regex: "erf", isPrefix: false, name: "of Interference", description: "You take (41-50)% reduced Extra Damage from Critical Strikes", ilevel: 67, family: "ReducedExtraDamageFromCrits"},
    {regex: "ade", isPrefix: false, name: "of Deadening", description: "You take (31-40)% reduced Extra Damage from Critical Strikes", ilevel: 45, family: "ReducedExtraDamageFromCrits"},
    {regex: "dul", isPrefix: false, name: "of Dulling", description: "You take (21-30)% reduced Extra Damage from Critical Strikes", ilevel: 33, family: "ReducedExtraDamageFromCrits"},
    {regex: "erv", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "ote", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "wat", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "see", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "hte", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "nig", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "ya", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "unm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "mit", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "dis", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "lla", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Shields (dex, int)" : [
    {regex: "spa", isPrefix: false, name: "of the Span", description: "+(17-18)% to all Elemental Resistances", ilevel: 85, family: "AllResistances"},
    {regex: "nb", isPrefix: false, name: "of the Rainbow", description: "+(15-16)% to all Elemental Resistances", ilevel: 60, family: "AllResistances"},
    {regex: "var", isPrefix: false, name: "of Variegation", description: "+(12-14)% to all Elemental Resistances", ilevel: 48, family: "AllResistances"},
    {regex: "ka", isPrefix: false, name: "of the Kaleidoscope", description: "+(9-11)% to all Elemental Resistances", ilevel: 36, family: "AllResistances"},
    {regex: "ris", isPrefix: false, name: "of the Prism", description: "+(6-8)% to all Elemental Resistances", ilevel: 24, family: "AllResistances"},
    {regex: "cry", isPrefix: false, name: "of the Crystal", description: "+(3-5)% to all Elemental Resistances", ilevel: 12, family: "AllResistances"},
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "rb", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "pin", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "f w", isPrefix: false, name: "of Will", description: "(31-35)% chance to Avoid Elemental Ailments", ilevel: 73, family: "AvoidElementalStatusAilments"},
    {regex: "for", isPrefix: false, name: "of Fortitude", description: "(26-30)% chance to Avoid Elemental Ailments", ilevel: 57, family: "AvoidElementalStatusAilments"},
    {regex: "eso", isPrefix: false, name: "of Resolve", description: "(21-25)% chance to Avoid Elemental Ailments", ilevel: 41, family: "AvoidElementalStatusAilments"},
    {regex: "toi", isPrefix: false, name: "of Stoicism", description: "(16-20)% chance to Avoid Elemental Ailments", ilevel: 23, family: "AvoidElementalStatusAilments"},
    {regex: "^ap", isPrefix: true, name: "Apparition's", description: "+(221-300) to Evasion Rating|+(61-72) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "^ei", isPrefix: true, name: "Eidolon's", description: "+(146-220) to Evasion Rating|+(49-60) to maximum Energy Shield", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "pir", isPrefix: true, name: "Spirit's", description: "+(86-145) to Evasion Rating|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "ub", isPrefix: true, name: "Cherub's", description: "+(49-85) to Evasion Rating|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "sy", isPrefix: true, name: "Sylph's", description: "+(28-48) to Evasion Rating|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nymph's", description: "+(10-27) to Evasion Rating|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "^wi", isPrefix: true, name: "Will-o-wisp's", description: "+(5-9) to Evasion Rating|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "ies", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "m'", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "eac", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "^ac", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "rp", isPrefix: true, name: "Incorporeal", description: "(101-110)% increased Evasion and Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "^il", isPrefix: true, name: "Illusory", description: "(92-100)% increased Evasion and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "nrea", isPrefix: true, name: "Unreal", description: "(80-91)% increased Evasion and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^ev", isPrefix: true, name: "Evanescent", description: "(68-79)% increased Evasion and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^ep", isPrefix: true, name: "Ephemeral", description: "(56-67)% increased Evasion and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "rl", isPrefix: true, name: "Unworldly", description: "(43-55)% increased Evasion and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "^et", isPrefix: true, name: "Ethereal", description: "(27-42)% increased Evasion and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "wy", isPrefix: true, name: "Shadowy", description: "(15-26)% increased Evasion and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "gb", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "nf", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "was", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "th'", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "e wi", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "nth", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "d w", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "gal", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "liv", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "furn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "onm", isPrefix: true, name: "Weaponmaster's", description: "+2 to Level of Socketed Melee Gems", ilevel: 63, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "mb", isPrefix: true, name: "Combatant's", description: "+1 to Level of Socketed Melee Gems", ilevel: 8, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "adi", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f ma", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "skil", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "gor", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "let", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "ril", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "^ma", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "yi", isPrefix: true, name: "Unyielding", description: "(76-81)% increased Chance to Block", ilevel: 86, family: "IncreasedShieldBlockPercentage"},
    {regex: "end", isPrefix: true, name: "Enduring", description: "(70-75)% increased Chance to Block", ilevel: 77, family: "IncreasedShieldBlockPercentage"},
    {regex: "nwa", isPrefix: true, name: "Unwavering", description: "(64-69)% increased Chance to Block", ilevel: 61, family: "IncreasedShieldBlockPercentage"},
    {regex: "rde", isPrefix: true, name: "Warded", description: "(58-63)% increased Chance to Block", ilevel: 48, family: "IncreasedShieldBlockPercentage"},
    {regex: "^ad", isPrefix: true, name: "Adamant", description: "(52-57)% increased Chance to Block", ilevel: 35, family: "IncreasedShieldBlockPercentage"},
    {regex: "rel", isPrefix: true, name: "Unrelenting", description: "(46-51)% increased Chance to Block", ilevel: 15, family: "IncreasedShieldBlockPercentage"},
    {regex: "df", isPrefix: true, name: "Steadfast", description: "(40-45)% increased Chance to Block", ilevel: 2, family: "IncreasedShieldBlockPercentage"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "dig", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "den", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "arf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fla", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "e sto", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nir", isPrefix: false, name: "of Nirvana", description: "(60-69)% increased Mana Regeneration Rate", ilevel: 79, family: "ManaRegeneration"},
    {regex: "eu", isPrefix: false, name: "of Euphoria", description: "(50-59)% increased Mana Regeneration Rate", ilevel: 55, family: "ManaRegeneration"},
    {regex: "bli", isPrefix: false, name: "of Bliss", description: "(40-49)% increased Mana Regeneration Rate", ilevel: 42, family: "ManaRegeneration"},
    {regex: "ela", isPrefix: false, name: "of Elation", description: "(30-39)% increased Mana Regeneration Rate", ilevel: 29, family: "ManaRegeneration"},
    {regex: "jo", isPrefix: false, name: "of Joy", description: "(20-29)% increased Mana Regeneration Rate", ilevel: 18, family: "ManaRegeneration"},
    {regex: "xc", isPrefix: false, name: "of Excitement", description: "(10-19)% increased Mana Regeneration Rate", ilevel: 2, family: "ManaRegeneration"},
    {regex: "arm", isPrefix: false, name: "of Harmony", description: "+3% to maximum Chaos Resistance", ilevel: 81, family: "MaximumChaosResistance"},
    {regex: "onc", isPrefix: false, name: "of Concord", description: "+2% to maximum Chaos Resistance", ilevel: 75, family: "MaximumChaosResistance"},
    {regex: "egu", isPrefix: false, name: "of Regularity", description: "+1% to maximum Chaos Resistance", ilevel: 68, family: "MaximumChaosResistance"},
    {regex: "mam", isPrefix: false, name: "of the Mammoth", description: "+3% to maximum Cold Resistance", ilevel: 81, family: "MaximumColdResist"},
    {regex: "ndr", isPrefix: false, name: "of the Tundra", description: "+2% to maximum Cold Resistance", ilevel: 75, family: "MaximumColdResist"},
    {regex: "urs", isPrefix: false, name: "of Furs", description: "+1% to maximum Cold Resistance", ilevel: 68, family: "MaximumColdResist"},
    {regex: "sola", isPrefix: false, name: "of the Solar Storm", description: "+3% to maximum Fire Resistance", ilevel: 81, family: "MaximumFireResist"},
    {regex: "mol", isPrefix: false, name: "of the Molten Core", description: "+2% to maximum Fire Resistance", ilevel: 75, family: "MaximumFireResist"},
    {regex: "hf", isPrefix: false, name: "of the Bushfire", description: "+1% to maximum Fire Resistance", ilevel: 68, family: "MaximumFireResist"},
    {regex: "od$", isPrefix: false, name: "of the Lightning Rod", description: "+3% to maximum Lightning Resistance", ilevel: 81, family: "MaximumLightningResistance"},
    {regex: "kp", isPrefix: false, name: "of Shockproofing", description: "+2% to maximum Lightning Resistance", ilevel: 75, family: "MaximumLightningResistance"},
    {regex: "ped", isPrefix: false, name: "of Impedance", description: "+1% to maximum Lightning Resistance", ilevel: 68, family: "MaximumLightningResistance"},
    {regex: "eat", isPrefix: false, name: "of the Deathless", description: "+2% to all maximum Resistances", ilevel: 81, family: "MaximumResistances"},
    {regex: "sem", isPrefix: false, name: "of the Sempiternal", description: "+1% to all maximum Resistances", ilevel: 75, family: "MaximumResistances"},
    {regex: "^or", isPrefix: true, name: "Oracle's", description: "(14-15)% Chance to Block Spell Damage", ilevel: 77, family: "SpellBlockPercentage"},
    {regex: "^se", isPrefix: true, name: "Seer's", description: "(12-13)% Chance to Block Spell Damage", ilevel: 66, family: "SpellBlockPercentage"},
    {regex: "hte", isPrefix: true, name: "Enlightened", description: "(10-11)% Chance to Block Spell Damage", ilevel: 55, family: "SpellBlockPercentage"},
    {regex: "nig", isPrefix: true, name: "Enigmatic", description: "(8-9)% Chance to Block Spell Damage", ilevel: 40, family: "SpellBlockPercentage"},
    {regex: "ya", isPrefix: true, name: "Clairvoyant", description: "(6-7)% Chance to Block Spell Damage", ilevel: 28, family: "SpellBlockPercentage"},
    {regex: "^my", isPrefix: true, name: "Mystic", description: "(4-5)% Chance to Block Spell Damage", ilevel: 16, family: "SpellBlockPercentage"},
    {regex: "unm", isPrefix: false, name: "of Unmaking", description: "(100-109)% increased Spell Critical Strike Chance", ilevel: 76, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "rui", isPrefix: false, name: "of Ruin", description: "(80-99)% increased Spell Critical Strike Chance", ilevel: 59, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ami", isPrefix: false, name: "of Calamity", description: "(60-79)% increased Spell Critical Strike Chance", ilevel: 41, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "dis", isPrefix: false, name: "of Disaster", description: "(40-59)% increased Spell Critical Strike Chance", ilevel: 28, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "hav", isPrefix: false, name: "of Havoc", description: "(20-39)% increased Spell Critical Strike Chance", ilevel: 21, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "ena", isPrefix: false, name: "of Menace", description: "(10-19)% increased Spell Critical Strike Chance", ilevel: 11, family: "SpellCriticalStrikeChanceIncrease"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "anti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Quivers" : [
    {regex: "spl", isPrefix: false, name: "of Splintering", description: "Bow Attacks fire an additional Arrow", ilevel: 70, family: "AdditionalArrows"},
    {regex: "^m", isPrefix: true, name: "Malicious", description: "Adds (27-41) to (55-69) Chaos Damage to Attacks", ilevel: 83, family: "ChaosDamage"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "mb", isPrefix: true, name: "Entombing", description: "Adds (33-45) to (67-78) Cold Damage to Attacks", ilevel: 77, family: "ColdDamage"},
    {regex: "^pola", isPrefix: true, name: "Polar", description: "Adds (23-32) to (48-55) Cold Damage to Attacks", ilevel: 65, family: "ColdDamage"},
    {regex: "ia", isPrefix: true, name: "Glaciated", description: "Adds (18-24) to (36-42) Cold Damage to Attacks", ilevel: 53, family: "ColdDamage"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (15-20) to (30-35) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (12-16) to (24-28) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "id", isPrefix: true, name: "Frigid", description: "Adds (10-13) to (19-22) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (7-9) to (14-16) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^ch", isPrefix: true, name: "Chilled", description: "Adds (5-6) to (9-10) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds (1-2) to 3 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "end", isPrefix: false, name: "of Rending", description: "(39-44)% increased Critical Strike Chance with Bows", ilevel: 85, family: "CriticalStrikeChanceIncrease"},
    {regex: "f in", isPrefix: false, name: "of Incision", description: "(35-38)% increased Critical Strike Chance with Bows", ilevel: 72, family: "CriticalStrikeChanceIncrease"},
    {regex: "f pe", isPrefix: false, name: "of Penetrating", description: "(30-34)% increased Critical Strike Chance with Bows", ilevel: 58, family: "CriticalStrikeChanceIncrease"},
    {regex: "up", isPrefix: false, name: "of Rupturing", description: "(25-29)% increased Critical Strike Chance with Bows", ilevel: 44, family: "CriticalStrikeChanceIncrease"},
    {regex: "erci", isPrefix: false, name: "of Piercing", description: "(20-24)% increased Critical Strike Chance with Bows", ilevel: 30, family: "CriticalStrikeChanceIncrease"},
    {regex: "ngi", isPrefix: false, name: "of Stinging", description: "(15-19)% increased Critical Strike Chance with Bows", ilevel: 20, family: "CriticalStrikeChanceIncrease"},
    {regex: "dl", isPrefix: false, name: "of Needling", description: "(10-14)% increased Critical Strike Chance with Bows", ilevel: 5, family: "CriticalStrikeChanceIncrease"},
    {regex: "des", isPrefix: false, name: "of Destruction", description: "+(35-38)% to Critical Strike Multiplier with Bows", ilevel: 74, family: "CriticalStrikeMultiplier"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(30-34)% to Critical Strike Multiplier with Bows", ilevel: 59, family: "CriticalStrikeMultiplier"},
    {regex: "ury", isPrefix: false, name: "of Fury", description: "+(25-29)% to Critical Strike Multiplier with Bows", ilevel: 45, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(20-24)% to Critical Strike Multiplier with Bows", ilevel: 31, family: "CriticalStrikeMultiplier"},
    {regex: "f an", isPrefix: false, name: "of Anger", description: "+(13-19)% to Critical Strike Multiplier with Bows", ilevel: 21, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(8-12)% to Critical Strike Multiplier with Bows", ilevel: 8, family: "CriticalStrikeMultiplier"},
    {regex: "pal", isPrefix: true, name: "Impaling", description: "(43-50)% increased Damage with Bow Skills", ilevel: 86, family: "DamageWithWeaponTypeSkill"},
    {regex: "^la", isPrefix: true, name: "Lacerating", description: "(37-42)% increased Damage with Bow Skills", ilevel: 81, family: "DamageWithWeaponTypeSkill"},
    {regex: "siv", isPrefix: true, name: "Incisive", description: "(31-36)% increased Damage with Bow Skills", ilevel: 60, family: "DamageWithWeaponTypeSkill"},
    {regex: "rf", isPrefix: true, name: "Perforating", description: "(21-30)% increased Damage with Bow Skills", ilevel: 30, family: "DamageWithWeaponTypeSkill"},
    {regex: "^tr", isPrefix: true, name: "Trenchant", description: "(11-20)% increased Damage with Bow Skills", ilevel: 15, family: "DamageWithWeaponTypeSkill"},
    {regex: "^ac", isPrefix: true, name: "Acute", description: "(5-10)% increased Damage with Bow Skills", ilevel: 4, family: "DamageWithWeaponTypeSkill"},
    {regex: "lur", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "rd", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "go", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "ema", isPrefix: true, name: "Cremating", description: "Adds (37-50) to (74-87) Fire Damage to Attacks", ilevel: 76, family: "FireDamage"},
    {regex: "^bl", isPrefix: true, name: "Blasting", description: "Adds (27-35) to (53-62) Fire Damage to Attacks", ilevel: 64, family: "FireDamage"},
    {regex: "ner", isPrefix: true, name: "Incinerating", description: "Adds (20-27) to (40-47) Fire Damage to Attacks", ilevel: 52, family: "FireDamage"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (17-22) to (33-38) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "flam", isPrefix: true, name: "Flaming", description: "Adds (13-18) to (27-31) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (11-14) to (21-25) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (8-10) to (15-18) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (5-7) to (10-12) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "heat", isPrefix: true, name: "Heated", description: "Adds (1-2) to 3 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "nac", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "dr", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "iss", isPrefix: false, name: "of Dissolution", description: "+(24-26)% to Damage over Time Multiplier with Attack Skills", ilevel: 82, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "mel", isPrefix: false, name: "of Melting", description: "+(20-23)% to Damage over Time Multiplier with Attack Skills", ilevel: 76, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(16-19)% to Damage over Time Multiplier with Attack Skills", ilevel: 68, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(12-15)% to Damage over Time Multiplier with Attack Skills", ilevel: 55, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(7-11)% to Damage over Time Multiplier with Attack Skills", ilevel: 44, family: "GlobalDamageOverTimeMultiplier"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "he r", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "rec", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f ma", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "alt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "iu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "lec", isPrefix: true, name: "Electrocuting", description: "Adds (5-11) to (124-140) Lightning Damage to Attacks", ilevel: 76, family: "LightningDamage"},
    {regex: "^di", isPrefix: true, name: "Discharging", description: "Adds (3-8) to (89-99) Lightning Damage to Attacks", ilevel: 64, family: "LightningDamage"},
    {regex: "^sh", isPrefix: true, name: "Shocking", description: "Adds (2-6) to (66-75) Lightning Damage to Attacks", ilevel: 52, family: "LightningDamage"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (2-5) to (56-62) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (2-4) to (44-50) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (2-3) to (35-40) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds (1-3) to (25-28) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 2 to (16-18) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to (3-4) Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "e li", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "pes", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "rm", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^th", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "flar", isPrefix: true, name: "Flaring", description: "Adds (17-23) to (34-39) Physical Damage to Attacks", ilevel: 76, family: "PhysicalDamage"},
    {regex: "^te", isPrefix: true, name: "Tempered", description: "Adds (14-19) to (28-33) Physical Damage to Attacks", ilevel: 64, family: "PhysicalDamage"},
    {regex: "zo", isPrefix: true, name: "Razor-sharp", description: "Adds (11-16) to (23-26) Physical Damage to Attacks", ilevel: 52, family: "PhysicalDamage"},
    {regex: "^an", isPrefix: true, name: "Annealed", description: "Adds (10-13) to (19-23) Physical Damage to Attacks", ilevel: 44, family: "PhysicalDamage"},
    {regex: "gle", isPrefix: true, name: "Gleaming", description: "Adds (8-11) to (16-18) Physical Damage to Attacks", ilevel: 35, family: "PhysicalDamage"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (6-9) to (13-16) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "oli", isPrefix: true, name: "Polished", description: "Adds (5-6) to (9-10) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (3-4) to (6-8) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds (1-2) to 3 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "gal", isPrefix: false, name: "of the Gale", description: "(42-46)% increased Projectile Speed", ilevel: 82, family: "ProjectileSpeed"},
    {regex: "yr", isPrefix: false, name: "of the Zephyr", description: "(34-41)% increased Projectile Speed", ilevel: 55, family: "ProjectileSpeed"},
    {regex: "rop", isPrefix: false, name: "of Propulsion", description: "(26-33)% increased Projectile Speed", ilevel: 41, family: "ProjectileSpeed"},
    {regex: "fli", isPrefix: false, name: "of Flight", description: "(18-25)% increased Projectile Speed", ilevel: 27, family: "ProjectileSpeed"},
    {regex: "dar", isPrefix: false, name: "of Darting", description: "(10-17)% increased Projectile Speed", ilevel: 14, family: "ProjectileSpeed"},
    {regex: "gg", isPrefix: false, name: "of Staggering", description: "(31-35)% increased Stun Duration on Enemies", ilevel: 58, family: "StunDurationIncreasePercent"},
    {regex: "sl", isPrefix: false, name: "of Slamming", description: "(26-30)% increased Stun Duration on Enemies", ilevel: 44, family: "StunDurationIncreasePercent"},
    {regex: "unn", isPrefix: false, name: "of Stunning", description: "(21-25)% increased Stun Duration on Enemies", ilevel: 30, family: "StunDurationIncreasePercent"},
    {regex: "daz", isPrefix: false, name: "of Dazing", description: "(16-20)% increased Stun Duration on Enemies", ilevel: 18, family: "StunDurationIncreasePercent"},
    {regex: "pac", isPrefix: false, name: "of Impact", description: "(11-15)% increased Stun Duration on Enemies", ilevel: 5, family: "StunDurationIncreasePercent"}
  ],
  "Gloves (str)" : [
    {regex: "^en", isPrefix: true, name: "Encased", description: "+(121-150) to Armour", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^ca", isPrefix: true, name: "Carapaced", description: "+(102-120) to Armour", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(83-101) to Armour", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "^fo", isPrefix: true, name: "Fortified", description: "+(64-82) to Armour", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-63) to Armour", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "tud", isPrefix: true, name: "Studded", description: "+(13-35) to Armour", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "cq", isPrefix: true, name: "Lacquered", description: "+(6-12) to Armour", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "cti", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^ch", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "in$", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "gn", isPrefix: true, name: "Impregnable", description: "(92-100)% increased Armour", ilevel: 84, family: "DefencesPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(80-91)% increased Armour", ilevel: 72, family: "DefencesPercent"},
    {regex: "hic", isPrefix: true, name: "Thickened", description: "(68-79)% increased Armour", ilevel: 60, family: "DefencesPercent"},
    {regex: "utt", isPrefix: true, name: "Buttressed", description: "(56-67)% increased Armour", ilevel: 42, family: "DefencesPercent"},
    {regex: "^lo", isPrefix: true, name: "Lobstered", description: "(43-55)% increased Armour", ilevel: 29, family: "DefencesPercent"},
    {regex: "ay", isPrefix: true, name: "Layered", description: "(27-42)% increased Armour", ilevel: 17, family: "DefencesPercent"},
    {regex: "ei", isPrefix: true, name: "Reinforced", description: "(15-26)% increased Armour", ilevel: 3, family: "DefencesPercent"},
    {regex: "h'", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "t'", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour|(12-13)% increased Stun and Block Recovery", ilevel: 42, family: "DefencesPercentAndStunRecovery"},
    {regex: "rma", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour|(10-11)% increased Stun and Block Recovery", ilevel: 29, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour|(8-9)% increased Stun and Block Recovery", ilevel: 17, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour|(6-7)% increased Stun and Block Recovery", ilevel: 1, family: "DefencesPercentAndStunRecovery"},
    {regex: "blu", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fa", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "mon", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "^fl", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "oul", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rks", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "rai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "iu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "a'", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "pet", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "rci", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "pp", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "thu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "abs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "hir", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tan$", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "han$", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "ath$", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"}
  ],
  "Gloves (dex)" : [
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(121-150) to Evasion Rating", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "has", isPrefix: true, name: "Phased", description: "+(102-120) to Evasion Rating", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rr", isPrefix: true, name: "Blurred", description: "+(83-101) to Evasion Rating", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Fleet", description: "+(64-82) to Evasion Rating", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "+(36-63) to Evasion Rating", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "r'", isPrefix: true, name: "Dancer's", description: "+(13-35) to Evasion Rating", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(6-12) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "lea", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "rev", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^ch", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^mi", isPrefix: true, name: "Mirage's", description: "(92-100)% increased Evasion Rating", ilevel: 84, family: "DefencesPercent"},
    {regex: "^n", isPrefix: true, name: "Nightmare's", description: "(80-91)% increased Evasion Rating", ilevel: 72, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Phantasm's", description: "(68-79)% increased Evasion Rating", ilevel: 60, family: "DefencesPercent"},
    {regex: "^wr", isPrefix: true, name: "Wraith's", description: "(56-67)% increased Evasion Rating", ilevel: 44, family: "DefencesPercent"},
    {regex: "pec", isPrefix: true, name: "Spectre's", description: "(43-55)% increased Evasion Rating", ilevel: 30, family: "DefencesPercent"},
    {regex: "^gh", isPrefix: true, name: "Ghost's", description: "(27-42)% increased Evasion Rating", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sh", isPrefix: true, name: "Shade's", description: "(15-26)% increased Evasion Rating", ilevel: 3, family: "DefencesPercent"},
    {regex: "gb", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion Rating|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion Rating|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "p'", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion Rating|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "erf", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion Rating|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion Rating|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion Rating|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "ur$", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "tom$", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "ami", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "ksm", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sni", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "adi", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "skil", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^vi", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "heal", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "iu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "emo", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "spa", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "Humming", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"}
  ],
  "Gloves (int)" : [
    {regex: "see", isPrefix: true, name: "Seething", description: "+(39-49) to maximum Energy Shield", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(31-38) to maximum Energy Shield", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(24-30) to maximum Energy Shield", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "owi", isPrefix: true, name: "Glowing", description: "+(17-23) to maximum Energy Shield", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "tte", isPrefix: true, name: "Glittering", description: "+(12-16) to maximum Energy Shield", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "lim", isPrefix: true, name: "Glimmering", description: "+(6-11) to maximum Energy Shield", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^sh", isPrefix: true, name: "Shining", description: "+(3-5) to maximum Energy Shield", ilevel: 3, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "r'", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^u", isPrefix: true, name: "Unassailable", description: "(92-100)% increased Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "ndo", isPrefix: true, name: "Indomitable", description: "(80-91)% increased Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^da", isPrefix: true, name: "Dauntless", description: "(68-79)% increased Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^fe", isPrefix: true, name: "Fearless", description: "(56-67)% increased Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "eso", isPrefix: true, name: "Resolute", description: "(43-55)% increased Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ron", isPrefix: true, name: "Strong-Willed", description: "(27-42)% increased Energy Shield", ilevel: 18, family: "DefencesPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(11-28)% increased Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "bo", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 18, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 3, family: "DefencesPercentAndStunRecovery"},
    {regex: "lur", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "ind$", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "tom$", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fa", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "go", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "yi", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "^fl", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "lio", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger$", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rks", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "ill$", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "av", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "ge$", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "up", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "rai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "pir", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "emo", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "glin", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"}
  ],
  "Gloves (str, dex)" : [
    {regex: "dur", isPrefix: true, name: "Durable", description: "+(49-85) to Armour|+(49-85) to Evasion Rating", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "lex", isPrefix: true, name: "Flexible", description: "+(28-48) to Armour|+(28-48) to Evasion Rating", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Pliant", description: "+(10-27) to Armour|+(10-27) to Evasion Rating", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ppl", isPrefix: true, name: "Supple", description: "+(5-9) to Armour|+(5-9) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "lea", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "rev", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xil", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "cti", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^ch", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "in$", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "d'", isPrefix: true, name: "Legend's", description: "(92-100)% increased Armour and Evasion", ilevel: 84, family: "DefencesPercent"},
    {regex: "ero", isPrefix: true, name: "Hero's", description: "(80-91)% increased Armour and Evasion", ilevel: 72, family: "DefencesPercent"},
    {regex: "ue", isPrefix: true, name: "Duelist's", description: "(68-79)% increased Armour and Evasion", ilevel: 60, family: "DefencesPercent"},
    {regex: "gla", isPrefix: true, name: "Gladiator's", description: "(56-67)% increased Armour and Evasion", ilevel: 44, family: "DefencesPercent"},
    {regex: "^fe", isPrefix: true, name: "Fencer's", description: "(43-55)% increased Armour and Evasion", ilevel: 30, family: "DefencesPercent"},
    {regex: "wl", isPrefix: true, name: "Brawler's", description: "(27-42)% increased Armour and Evasion", ilevel: 19, family: "DefencesPercent"},
    {regex: "scr", isPrefix: true, name: "Scrapper's", description: "(15-26)% increased Armour and Evasion", ilevel: 3, family: "DefencesPercent"},
    {regex: "h'", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour and Evasion|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour and Evasion|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour and Evasion|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "rma", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour and Evasion|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour and Evasion|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour and Evasion|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "blu", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "sco", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "ami", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "oul", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "sk", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "ob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "rai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "iu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "emo", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "iv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "pet", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "arc", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "^hu", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "thu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"}
  ],
  "Gloves (str, int)" : [
    {regex: "hall", isPrefix: true, name: "Hallowed", description: "+(49-85) to Armour|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "nct", isPrefix: true, name: "Sanctified", description: "+(28-48) to Armour|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "oi", isPrefix: true, name: "Anointed", description: "+(10-27) to Armour|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ble", isPrefix: true, name: "Blessed", description: "+(5-9) to Armour|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "lar", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "nsp", isPrefix: true, name: "Inspired", description: "(92-100)% increased Armour and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "rpo", isPrefix: true, name: "Interpolated", description: "(80-91)% increased Armour and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "ncu", isPrefix: true, name: "Inculcated", description: "(68-79)% increased Armour and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "nfu", isPrefix: true, name: "Infused", description: "(56-67)% increased Armour and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nst", isPrefix: true, name: "Instilled", description: "(43-55)% increased Armour and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ngr", isPrefix: true, name: "Ingrained", description: "(27-42)% increased Armour and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "xe", isPrefix: true, name: "Infixed", description: "(15-26)% increased Armour and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Armour and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Armour and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Armour and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "bo", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Armour and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Armour and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Armour and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "lur", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fa", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "do", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "^fl", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "ok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "oul", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rks", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "sk", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "sang", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "hale", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "av", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "up", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "ira", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "emo", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "rpe", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "mm", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ons", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tit", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "br", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"}
  ],
  "Gloves (dex, int)" : [
    {regex: "ub", isPrefix: true, name: "Cherub's", description: "+(49-85) to Evasion Rating|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "sy", isPrefix: true, name: "Sylph's", description: "+(28-48) to Evasion Rating|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nymph's", description: "+(10-27) to Evasion Rating|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "^wi", isPrefix: true, name: "Will-o-wisp's", description: "+(5-9) to Evasion Rating|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "r'", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "fle", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "rev", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "oz", isPrefix: true, name: "Frozen", description: "Adds (10-13) to (20-24) Cold Damage to Attacks", ilevel: 45, family: "ColdDamage"},
    {regex: "ez", isPrefix: true, name: "Freezing", description: "Adds (8-11) to (16-19) Cold Damage to Attacks", ilevel: 36, family: "ColdDamage"},
    {regex: "fri", isPrefix: true, name: "Frigid", description: "Adds (6-9) to (13-16) Cold Damage to Attacks", ilevel: 29, family: "ColdDamage"},
    {regex: "^ic", isPrefix: true, name: "Icy", description: "Adds (5-7) to (10-12) Cold Damage to Attacks", ilevel: 21, family: "ColdDamage"},
    {regex: "^chi", isPrefix: true, name: "Chilled", description: "Adds (3-4) to (7-8) Cold Damage to Attacks", ilevel: 13, family: "ColdDamage"},
    {regex: "ros", isPrefix: true, name: "Frosted", description: "Adds 1 to 2 Cold Damage to Attacks", ilevel: 2, family: "ColdDamage"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^il", isPrefix: true, name: "Illusory", description: "(92-100)% increased Evasion and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "nr", isPrefix: true, name: "Unreal", description: "(80-91)% increased Evasion and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^ev", isPrefix: true, name: "Evanescent", description: "(68-79)% increased Evasion and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^ep", isPrefix: true, name: "Ephemeral", description: "(56-67)% increased Evasion and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nw", isPrefix: true, name: "Unworldly", description: "(43-55)% increased Evasion and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "^et", isPrefix: true, name: "Ethereal", description: "(27-42)% increased Evasion and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "wy", isPrefix: true, name: "Shadowy", description: "(15-26)% increased Evasion and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "gb", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^wa", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "tte", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "lur", isPrefix: false, name: "of the Blur", description: "+(56-60) to Dexterity", ilevel: 85, family: "Dexterity"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "tom$", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "^sc", isPrefix: true, name: "Scorching", description: "Adds (11-15) to (23-27) Fire Damage to Attacks", ilevel: 44, family: "FireDamage"},
    {regex: "ami", isPrefix: true, name: "Flaming", description: "Adds (9-12) to (19-22) Fire Damage to Attacks", ilevel: 35, family: "FireDamage"},
    {regex: "rnin", isPrefix: true, name: "Burning", description: "Adds (7-10) to (15-18) Fire Damage to Attacks", ilevel: 28, family: "FireDamage"},
    {regex: "mok", isPrefix: true, name: "Smoking", description: "Adds (5-7) to (11-13) Fire Damage to Attacks", ilevel: 20, family: "FireDamage"},
    {regex: "mou", isPrefix: true, name: "Smouldering", description: "Adds (3-5) to (7-8) Fire Damage to Attacks", ilevel: 12, family: "FireDamage"},
    {regex: "eat", isPrefix: true, name: "Heated", description: "Adds 1 to 2 Fire Damage to Attacks", ilevel: 1, family: "FireDamage"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "mar", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "dm", isPrefix: false, name: "of Grandmastery", description: "(14-16)% increased Attack Speed", ilevel: 76, family: "IncreasedAttackSpeed"},
    {regex: "f m", isPrefix: false, name: "of Mastery", description: "(11-13)% increased Attack Speed", ilevel: 22, family: "IncreasedAttackSpeed"},
    {regex: "ase$", isPrefix: false, name: "of Ease", description: "(8-10)% increased Attack Speed", ilevel: 11, family: "IncreasedAttackSpeed"},
    {regex: "sk", isPrefix: false, name: "of Skill", description: "(5-7)% increased Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "ia", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "av", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "ilo", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "rai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ej", isPrefix: false, name: "of Rejuvenation", description: "Gain 2 Life per Enemy Hit with Attacks", ilevel: 8, family: "LifeGainPerTarget"},
    {regex: "riu", isPrefix: false, name: "of Triumph", description: "Gain (11-14) Life per Enemy Killed", ilevel: 40, family: "LifeGainedFromEnemyDeath"},
    {regex: "cto", isPrefix: false, name: "of Victory", description: "Gain (7-10) Life per Enemy Killed", ilevel: 23, family: "LifeGainedFromEnemyDeath"},
    {regex: "suc", isPrefix: false, name: "of Success", description: "Gain (3-6) Life per Enemy Killed", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "emo", isPrefix: true, name: "Remora's", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Life", ilevel: 50, family: "LifeLeech"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "^ar", isPrefix: true, name: "Arcing", description: "Adds (1-4) to (40-43) Lightning Damage to Attacks", ilevel: 44, family: "LightningDamage"},
    {regex: "^sp", isPrefix: true, name: "Sparking", description: "Adds (1-3) to (33-34) Lightning Damage to Attacks", ilevel: 35, family: "LightningDamage"},
    {regex: "kl", isPrefix: true, name: "Crackling", description: "Adds (1-2) to (27-28) Lightning Damage to Attacks", ilevel: 28, family: "LightningDamage"},
    {regex: "^sn", isPrefix: true, name: "Snapping", description: "Adds (1-2) to (22-23) Lightning Damage to Attacks", ilevel: 22, family: "LightningDamage"},
    {regex: "uz", isPrefix: true, name: "Buzzing", description: "Adds 1 to (14-15) Lightning Damage to Attacks", ilevel: 13, family: "LightningDamage"},
    {regex: "Humming", isPrefix: true, name: "Humming", description: "Adds 1 to 5 Lightning Damage to Attacks", ilevel: 3, family: "LightningDamage"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "apt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ns", isPrefix: false, name: "of Consumption", description: "Gain (4-6) Mana per Enemy Killed", ilevel: 40, family: "ManaGainedFromEnemyDeath"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (2-3) Mana per Enemy Killed", ilevel: 24, family: "ManaGainedFromEnemyDeath"},
    {regex: "bs", isPrefix: false, name: "of Absorption", description: "Gain 1 Mana per Enemy Killed", ilevel: 1, family: "ManaGainedFromEnemyDeath"},
    {regex: "^t", isPrefix: true, name: "Thirsty", description: "(0.2-0.4)% of Physical Attack Damage Leeched as Mana", ilevel: 50, family: "ManaLeech"},
    {regex: "^ho", isPrefix: true, name: "Honed", description: "Adds (4-6) to (9-10) Physical Damage to Attacks", ilevel: 28, family: "PhysicalDamage"},
    {regex: "^po", isPrefix: true, name: "Polished", description: "Adds (3-4) to (6-7) Physical Damage to Attacks", ilevel: 19, family: "PhysicalDamage"},
    {regex: "rnis", isPrefix: true, name: "Burnished", description: "Adds (2-3) to (4-5) Physical Damage to Attacks", ilevel: 13, family: "PhysicalDamage"},
    {regex: "gli", isPrefix: true, name: "Glinting", description: "Adds 1 to 2 Physical Damage to Attacks", ilevel: 5, family: "PhysicalDamage"}
  ],
  "Boots (str)" : [
    {regex: "^en", isPrefix: true, name: "Encased", description: "+(121-150) to Armour", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^ca", isPrefix: true, name: "Carapaced", description: "+(102-120) to Armour", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(83-101) to Armour", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "^f", isPrefix: true, name: "Fortified", description: "+(64-82) to Armour", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-63) to Armour", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "dd", isPrefix: true, name: "Studded", description: "+(13-35) to Armour", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "cq", isPrefix: true, name: "Lacquered", description: "+(6-12) to Armour", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "ct", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "gn", isPrefix: true, name: "Impregnable", description: "(92-100)% increased Armour", ilevel: 84, family: "DefencesPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(80-91)% increased Armour", ilevel: 72, family: "DefencesPercent"},
    {regex: "^t", isPrefix: true, name: "Thickened", description: "(68-79)% increased Armour", ilevel: 60, family: "DefencesPercent"},
    {regex: "^bu", isPrefix: true, name: "Buttressed", description: "(56-67)% increased Armour", ilevel: 42, family: "DefencesPercent"},
    {regex: "bs", isPrefix: true, name: "Lobstered", description: "(43-55)% increased Armour", ilevel: 29, family: "DefencesPercent"},
    {regex: "ay", isPrefix: true, name: "Layered", description: "(27-42)% increased Armour", ilevel: 17, family: "DefencesPercent"},
    {regex: "^rei", isPrefix: true, name: "Reinforced", description: "(15-26)% increased Armour", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "t'", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour|(12-13)% increased Stun and Block Recovery", ilevel: 42, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour|(10-11)% increased Stun and Block Recovery", ilevel: 29, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour|(8-9)% increased Stun and Block Recovery", ilevel: 17, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour|(6-7)% increased Stun and Block Recovery", ilevel: 1, family: "DefencesPercentAndStunRecovery"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "id", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "lu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ho", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fl", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "uth", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "pet", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "j", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "tor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "^sp", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "god", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tan$", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "han$", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "ath$", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "n s", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ne s", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "k s", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Boots (dex)" : [
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(121-150) to Evasion Rating", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "has", isPrefix: true, name: "Phased", description: "+(102-120) to Evasion Rating", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rr", isPrefix: true, name: "Blurred", description: "+(83-101) to Evasion Rating", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "lee", isPrefix: true, name: "Fleet", description: "+(64-82) to Evasion Rating", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "+(36-63) to Evasion Rating", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "^da", isPrefix: true, name: "Dancer's", description: "+(13-35) to Evasion Rating", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(6-12) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^mi", isPrefix: true, name: "Mirage's", description: "(92-100)% increased Evasion Rating", ilevel: 84, family: "DefencesPercent"},
    {regex: "^n", isPrefix: true, name: "Nightmare's", description: "(80-91)% increased Evasion Rating", ilevel: 72, family: "DefencesPercent"},
    {regex: "sm", isPrefix: true, name: "Phantasm's", description: "(68-79)% increased Evasion Rating", ilevel: 60, family: "DefencesPercent"},
    {regex: "^wr", isPrefix: true, name: "Wraith's", description: "(56-67)% increased Evasion Rating", ilevel: 44, family: "DefencesPercent"},
    {regex: "pec", isPrefix: true, name: "Spectre's", description: "(43-55)% increased Evasion Rating", ilevel: 30, family: "DefencesPercent"},
    {regex: "^gh", isPrefix: true, name: "Ghost's", description: "(27-42)% increased Evasion Rating", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sh", isPrefix: true, name: "Shade's", description: "(15-26)% increased Evasion Rating", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion Rating|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion Rating|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "p'", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion Rating|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion Rating|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion Rating|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion Rating|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "ind$", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "tom$", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "mon", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "rak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "lp", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^vi", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^hea", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "torm$", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud$", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "spr", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "n s", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "one", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Boots (int)" : [
    {regex: "see", isPrefix: true, name: "Seething", description: "+(39-49) to maximum Energy Shield", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(31-38) to maximum Energy Shield", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(24-30) to maximum Energy Shield", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "glo", isPrefix: true, name: "Glowing", description: "+(17-23) to maximum Energy Shield", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(12-16) to maximum Energy Shield", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "mm", isPrefix: true, name: "Glimmering", description: "+(6-11) to maximum Energy Shield", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^sh", isPrefix: true, name: "Shining", description: "+(3-5) to maximum Energy Shield", ilevel: 3, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^u", isPrefix: true, name: "Unassailable", description: "(92-100)% increased Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "ndo", isPrefix: true, name: "Indomitable", description: "(80-91)% increased Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "tl", isPrefix: true, name: "Dauntless", description: "(68-79)% increased Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^f", isPrefix: true, name: "Fearless", description: "(56-67)% increased Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "eso", isPrefix: true, name: "Resolute", description: "(43-55)% increased Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ong", isPrefix: true, name: "Strong-Willed", description: "(27-42)% increased Energy Shield", ilevel: 18, family: "DefencesPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(11-28)% increased Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 18, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 3, family: "DefencesPercentAndStunRecovery"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "lam", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "^bl", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "va", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "ge$", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "up", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "id", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "pir", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "x$", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fl", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "^ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "^sp", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "ste", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ston", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Boots (str, dex)" : [
    {regex: "dur", isPrefix: true, name: "Durable", description: "+(49-85) to Armour|+(49-85) to Evasion Rating", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "lex", isPrefix: true, name: "Flexible", description: "+(28-48) to Armour|+(28-48) to Evasion Rating", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Pliant", description: "+(10-27) to Armour|+(10-27) to Evasion Rating", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ppl", isPrefix: true, name: "Supple", description: "+(5-9) to Armour|+(5-9) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xil", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "ct", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "d'", isPrefix: true, name: "Legend's", description: "(92-100)% increased Armour and Evasion", ilevel: 84, family: "DefencesPercent"},
    {regex: "ero", isPrefix: true, name: "Hero's", description: "(80-91)% increased Armour and Evasion", ilevel: 72, family: "DefencesPercent"},
    {regex: "ue", isPrefix: true, name: "Duelist's", description: "(68-79)% increased Armour and Evasion", ilevel: 60, family: "DefencesPercent"},
    {regex: "gl", isPrefix: true, name: "Gladiator's", description: "(56-67)% increased Armour and Evasion", ilevel: 44, family: "DefencesPercent"},
    {regex: "^fe", isPrefix: true, name: "Fencer's", description: "(43-55)% increased Armour and Evasion", ilevel: 30, family: "DefencesPercent"},
    {regex: "wl", isPrefix: true, name: "Brawler's", description: "(27-42)% increased Armour and Evasion", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sc", isPrefix: true, name: "Scrapper's", description: "(15-26)% increased Armour and Evasion", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour and Evasion|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour and Evasion|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour and Evasion|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour and Evasion|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour and Evasion|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour and Evasion|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "wi", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "mon", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "ob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "ai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "lu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ho", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "ra$", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "iv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "pet", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "^sp", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "n s", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "one", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Boots (str, int)" : [
    {regex: "hall", isPrefix: true, name: "Hallowed", description: "+(49-85) to Armour|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "nct", isPrefix: true, name: "Sanctified", description: "+(28-48) to Armour|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "oi", isPrefix: true, name: "Anointed", description: "+(10-27) to Armour|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ble", isPrefix: true, name: "Blessed", description: "+(5-9) to Armour|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "lar", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "nsp", isPrefix: true, name: "Inspired", description: "(92-100)% increased Armour and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "rpo", isPrefix: true, name: "Interpolated", description: "(80-91)% increased Armour and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "cu", isPrefix: true, name: "Inculcated", description: "(68-79)% increased Armour and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "nfu", isPrefix: true, name: "Infused", description: "(56-67)% increased Armour and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nst", isPrefix: true, name: "Instilled", description: "(43-55)% increased Armour and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ngr", isPrefix: true, name: "Ingrained", description: "(27-42)% increased Armour and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "xe", isPrefix: true, name: "Infixed", description: "(15-26)% increased Armour and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Armour and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Armour and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Armour and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Armour and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Armour and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Armour and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "do", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "rn", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "lam", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "sang", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "hale", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "sc", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "pp", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "va", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "up", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "id", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "ira", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ho", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fl", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "rpe", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "tor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "^ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "^sp", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "br", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ston", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Boots (dex, int)" : [
    {regex: "ub", isPrefix: true, name: "Cherub's", description: "+(49-85) to Evasion Rating|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "sy", isPrefix: true, name: "Sylph's", description: "+(28-48) to Evasion Rating|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nymph's", description: "+(10-27) to Evasion Rating|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "^wi", isPrefix: true, name: "Will-o-wisp's", description: "+(5-9) to Evasion Rating|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "ct", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^il", isPrefix: true, name: "Illusory", description: "(92-100)% increased Evasion and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "nr", isPrefix: true, name: "Unreal", description: "(80-91)% increased Evasion and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^ev", isPrefix: true, name: "Evanescent", description: "(68-79)% increased Evasion and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^ep", isPrefix: true, name: "Ephemeral", description: "(56-67)% increased Evasion and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nw", isPrefix: true, name: "Unworldly", description: "(43-55)% increased Evasion and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "^et", isPrefix: true, name: "Ethereal", description: "(27-42)% increased Evasion and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "wy", isPrefix: true, name: "Shadowy", description: "(15-26)% increased Evasion and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^wa", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "tom$", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "ia", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "ai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ho", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "^hel", isPrefix: true, name: "Hellion's", description: "35% increased Movement Speed", ilevel: 86, family: "MovementVelocity"},
    {regex: "ah", isPrefix: true, name: "Cheetah's", description: "30% increased Movement Speed", ilevel: 55, family: "MovementVelocity"},
    {regex: "ga", isPrefix: true, name: "Gazelle's", description: "25% increased Movement Speed", ilevel: 40, family: "MovementVelocity"},
    {regex: "tall", isPrefix: true, name: "Stallion's", description: "20% increased Movement Speed", ilevel: 30, family: "MovementVelocity"},
    {regex: "^sp", isPrefix: true, name: "Sprinter's", description: "15% increased Movement Speed", ilevel: 15, family: "MovementVelocity"},
    {regex: "^ru", isPrefix: true, name: "Runner's", description: "10% increased Movement Speed", ilevel: 1, family: "MovementVelocity"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "ste", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "one", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (str)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "tho", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "rvi", isPrefix: true, name: "Impervious", description: "+(401-500) to Armour", ilevel: 77, family: "BaseLocalDefences"},
    {regex: "^un", isPrefix: true, name: "Unmoving", description: "+(301-400) to Armour", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "^ab", isPrefix: true, name: "Abating", description: "+(201-300) to Armour", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "env", isPrefix: true, name: "Enveloped", description: "+(151-200) to Armour", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "nca", isPrefix: true, name: "Encased", description: "+(121-150) to Armour", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^ca", isPrefix: true, name: "Carapaced", description: "+(102-120) to Armour", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(83-101) to Armour", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "^fo", isPrefix: true, name: "Fortified", description: "+(64-82) to Armour", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-63) to Armour", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "tud", isPrefix: true, name: "Studded", description: "+(13-35) to Armour", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "cq", isPrefix: true, name: "Lacquered", description: "+(6-12) to Armour", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "cro", isPrefix: true, name: "Crocodile's", description: "+(97-144) to Armour|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^ur", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "net", isPrefix: true, name: "Impenetrable", description: "(101-110)% increased Armour", ilevel: 86, family: "DefencesPercent"},
    {regex: "gn", isPrefix: true, name: "Impregnable", description: "(92-100)% increased Armour", ilevel: 84, family: "DefencesPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(80-91)% increased Armour", ilevel: 72, family: "DefencesPercent"},
    {regex: "ken", isPrefix: true, name: "Thickened", description: "(68-79)% increased Armour", ilevel: 60, family: "DefencesPercent"},
    {regex: "^bu", isPrefix: true, name: "Buttressed", description: "(56-67)% increased Armour", ilevel: 42, family: "DefencesPercent"},
    {regex: "bs", isPrefix: true, name: "Lobstered", description: "(43-55)% increased Armour", ilevel: 29, family: "DefencesPercent"},
    {regex: "ay", isPrefix: true, name: "Layered", description: "(27-42)% increased Armour", ilevel: 17, family: "DefencesPercent"},
    {regex: "ei", isPrefix: true, name: "Reinforced", description: "(15-26)% increased Armour", ilevel: 3, family: "DefencesPercent"},
    {regex: "^m", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour|(12-13)% increased Stun and Block Recovery", ilevel: 42, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour|(10-11)% increased Stun and Block Recovery", ilevel: 29, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour|(8-9)% increased Stun and Block Recovery", ilevel: 17, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour|(6-7)% increased Stun and Block Recovery", ilevel: 1, family: "DefencesPercentAndStunRecovery"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "lc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "pri", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "ptu", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "onv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "iv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "up", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "thu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "nse", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "pro", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "tc", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "tan$", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "oli", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "la$", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "k s", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (dex)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^spi", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "^fu", isPrefix: true, name: "Fugitive", description: "+(401-500) to Evasion Rating", ilevel: 77, family: "BaseLocalDefences"},
    {regex: "^li", isPrefix: true, name: "Lissome", description: "+(301-400) to Evasion Rating", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "^ad", isPrefix: true, name: "Adroit", description: "+(201-300) to Evasion Rating", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "elu", isPrefix: true, name: "Elusory", description: "+(151-200) to Evasion Rating", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(121-150) to Evasion Rating", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "has", isPrefix: true, name: "Phased", description: "+(102-120) to Evasion Rating", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rr", isPrefix: true, name: "Blurred", description: "+(83-101) to Evasion Rating", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Fleet", description: "+(64-82) to Evasion Rating", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "+(36-63) to Evasion Rating", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "^da", isPrefix: true, name: "Dancer's", description: "+(13-35) to Evasion Rating", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(6-12) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "x'", isPrefix: true, name: "Ibex's", description: "+(96-120) to Evasion Rating|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "ram", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^il", isPrefix: true, name: "Illusion's", description: "(101-110)% increased Evasion Rating", ilevel: 86, family: "DefencesPercent"},
    {regex: "^mi", isPrefix: true, name: "Mirage's", description: "(92-100)% increased Evasion Rating", ilevel: 84, family: "DefencesPercent"},
    {regex: "^n", isPrefix: true, name: "Nightmare's", description: "(80-91)% increased Evasion Rating", ilevel: 72, family: "DefencesPercent"},
    {regex: "sm", isPrefix: true, name: "Phantasm's", description: "(68-79)% increased Evasion Rating", ilevel: 60, family: "DefencesPercent"},
    {regex: "wr", isPrefix: true, name: "Wraith's", description: "(56-67)% increased Evasion Rating", ilevel: 44, family: "DefencesPercent"},
    {regex: "pec", isPrefix: true, name: "Spectre's", description: "(43-55)% increased Evasion Rating", ilevel: 30, family: "DefencesPercent"},
    {regex: "^gh", isPrefix: true, name: "Ghost's", description: "(27-42)% increased Evasion Rating", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sh", isPrefix: true, name: "Shade's", description: "(15-26)% increased Evasion Rating", ilevel: 3, family: "DefencesPercent"},
    {regex: "gb", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion Rating|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "^dr", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion Rating|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "p'", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion Rating|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion Rating|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion Rating|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion Rating|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "win", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "nx", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "mon", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "pri", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "ptu", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "nv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "ste", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (int)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "esp", isPrefix: true, name: "Resplendent", description: "+(91-100) to maximum Energy Shield", ilevel: 75, family: "BaseLocalDefences"},
    {regex: "nca", isPrefix: true, name: "Incandescent", description: "+(77-90) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "^sc", isPrefix: true, name: "Scintillating", description: "+(62-76) to maximum Energy Shield", ilevel: 60, family: "BaseLocalDefences"},
    {regex: "zi", isPrefix: true, name: "Blazing", description: "+(50-61) to maximum Energy Shield", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "see", isPrefix: true, name: "Seething", description: "+(39-49) to maximum Energy Shield", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(31-38) to maximum Energy Shield", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "rad", isPrefix: true, name: "Radiating", description: "+(24-30) to maximum Energy Shield", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "glo", isPrefix: true, name: "Glowing", description: "+(17-23) to maximum Energy Shield", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(12-16) to maximum Energy Shield", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "mm", isPrefix: true, name: "Glimmering", description: "+(6-11) to maximum Energy Shield", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^sh", isPrefix: true, name: "Shining", description: "+(3-5) to maximum Energy Shield", ilevel: 3, family: "BaseLocalDefences"},
    {regex: "bi", isPrefix: true, name: "Bishop's", description: "+(26-30) to maximum Energy Shield|+(23-25) to maximum Mana", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "xa", isPrefix: true, name: "Exarch's", description: "+(26-30) to maximum Energy Shield|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "nf", isPrefix: true, name: "Unfaltering", description: "(101-110)% increased Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "una", isPrefix: true, name: "Unassailable", description: "(92-100)% increased Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "mi", isPrefix: true, name: "Indomitable", description: "(80-91)% increased Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^da", isPrefix: true, name: "Dauntless", description: "(68-79)% increased Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "fea", isPrefix: true, name: "Fearless", description: "(56-67)% increased Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "eso", isPrefix: true, name: "Resolute", description: "(43-55)% increased Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ong", isPrefix: true, name: "Strong-Willed", description: "(27-42)% increased Energy Shield", ilevel: 18, family: "DefencesPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(11-28)% increased Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 18, family: "DefencesPercentAndStunRecovery"},
    {regex: "^pi", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 3, family: "DefencesPercentAndStunRecovery"},
    {regex: "wind", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "lv", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "nl", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "lc", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "prim", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "ptu", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "gor", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "iri", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "^ma", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "pp", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "ant$", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "age$", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "fla", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (str, dex)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "^ve", isPrefix: true, name: "Versatile", description: "+(301-375) to Armour|+(301-375) to Evasion Rating", ilevel: 79, family: "BaseLocalDefences"},
    {regex: "^ad", isPrefix: true, name: "Adaptable", description: "+(221-300) to Armour|+(221-300) to Evasion Rating", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "sil", isPrefix: true, name: "Resilient", description: "+(146-220) to Armour|+(146-220) to Evasion Rating", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "urd", isPrefix: true, name: "Sturdy", description: "+(86-145) to Armour|+(86-145) to Evasion Rating", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "dur", isPrefix: true, name: "Durable", description: "+(49-85) to Armour|+(49-85) to Evasion Rating", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "lex", isPrefix: true, name: "Flexible", description: "+(28-48) to Armour|+(28-48) to Evasion Rating", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Pliant", description: "+(10-27) to Armour|+(10-27) to Evasion Rating", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ppl", isPrefix: true, name: "Supple", description: "+(5-9) to Armour|+(5-9) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "x'", isPrefix: true, name: "Ibex's", description: "+(96-120) to Evasion Rating|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "cro", isPrefix: true, name: "Crocodile's", description: "+(97-144) to Armour|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "m'", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xil", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "los", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^vic", isPrefix: true, name: "Victor's", description: "(101-110)% increased Armour and Evasion", ilevel: 86, family: "DefencesPercent"},
    {regex: "d'", isPrefix: true, name: "Legend's", description: "(92-100)% increased Armour and Evasion", ilevel: 84, family: "DefencesPercent"},
    {regex: "ero", isPrefix: true, name: "Hero's", description: "(80-91)% increased Armour and Evasion", ilevel: 72, family: "DefencesPercent"},
    {regex: "due", isPrefix: true, name: "Duelist's", description: "(68-79)% increased Armour and Evasion", ilevel: 60, family: "DefencesPercent"},
    {regex: "gl", isPrefix: true, name: "Gladiator's", description: "(56-67)% increased Armour and Evasion", ilevel: 44, family: "DefencesPercent"},
    {regex: "fen", isPrefix: true, name: "Fencer's", description: "(43-55)% increased Armour and Evasion", ilevel: 30, family: "DefencesPercent"},
    {regex: "wl", isPrefix: true, name: "Brawler's", description: "(27-42)% increased Armour and Evasion", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sc", isPrefix: true, name: "Scrapper's", description: "(15-26)% increased Armour and Evasion", ilevel: 3, family: "DefencesPercent"},
    {regex: "^m", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour and Evasion|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour and Evasion|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour and Evasion|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour and Evasion|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour and Evasion|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour and Evasion|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "wi", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "op", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "ly", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "o$", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "pri", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "ptu", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "vig", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "vir", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "ob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "nv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "iv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "ning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "thu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "ud", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "rv", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "pro", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "tc", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "oli", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "ori", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "nti", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (str, int)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "dl", isPrefix: true, name: "Godly", description: "+(301-375) to Armour|+(73-80) to maximum Energy Shield", ilevel: 79, family: "BaseLocalDefences"},
    {regex: "^sai", isPrefix: true, name: "Saintly", description: "+(221-300) to Armour|+(61-72) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "ecr", isPrefix: true, name: "Consecrated", description: "+(146-220) to Armour|+(49-60) to maximum Energy Shield", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "^bea", isPrefix: true, name: "Beatified", description: "+(86-145) to Armour|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "hall", isPrefix: true, name: "Hallowed", description: "+(49-85) to Armour|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "nct", isPrefix: true, name: "Sanctified", description: "+(28-48) to Armour|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "oi", isPrefix: true, name: "Anointed", description: "+(10-27) to Armour|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ess", isPrefix: true, name: "Blessed", description: "+(5-9) to Armour|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "bi", isPrefix: true, name: "Bishop's", description: "+(26-30) to maximum Energy Shield|+(23-25) to maximum Mana", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "xa", isPrefix: true, name: "Exarch's", description: "+(26-30) to maximum Energy Shield|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "cro", isPrefix: true, name: "Crocodile's", description: "+(97-144) to Armour|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "ab", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "s'", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "ban", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice$", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "lar", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "rpe", isPrefix: true, name: "Interpermeated", description: "(101-110)% increased Armour and Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "nsp", isPrefix: true, name: "Inspired", description: "(92-100)% increased Armour and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "rpo", isPrefix: true, name: "Interpolated", description: "(80-91)% increased Armour and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "ncu", isPrefix: true, name: "Inculcated", description: "(68-79)% increased Armour and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "nfu", isPrefix: true, name: "Infused", description: "(56-67)% increased Armour and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nst", isPrefix: true, name: "Instilled", description: "(43-55)% increased Armour and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ngr", isPrefix: true, name: "Ingrained", description: "(27-42)% increased Armour and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "xe", isPrefix: true, name: "Infixed", description: "(15-26)% increased Armour and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Armour and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Armour and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "a'", isPrefix: true, name: "Naga's", description: "(27-32)% increased Armour and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "og", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Armour and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Armour and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^pi", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Armour and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "wi", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "lv", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "nl", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "rim", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "ptu", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "oro", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^f", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "iri", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "sang", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "hale", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "^ma", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "pp", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "cob", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "av", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "rv", isPrefix: false, name: "of the Conservator", description: "8% additional Physical Damage Reduction", ilevel: 86, family: "ReducedPhysicalDamageTaken"},
    {regex: "ote", isPrefix: false, name: "of the Protector", description: "7% additional Physical Damage Reduction", ilevel: 77, family: "ReducedPhysicalDamageTaken"},
    {regex: "kee", isPrefix: false, name: "of the Keeper", description: "6% additional Physical Damage Reduction", ilevel: 67, family: "ReducedPhysicalDamageTaken"},
    {regex: "sen", isPrefix: false, name: "of the Sentry", description: "5% additional Physical Damage Reduction", ilevel: 58, family: "ReducedPhysicalDamageTaken"},
    {regex: "tc", isPrefix: false, name: "of the Watchman", description: "4% additional Physical Damage Reduction", ilevel: 45, family: "ReducedPhysicalDamageTaken"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "oli", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "lla", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lio", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "br", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Body Armours (dex, int)" : [
    {regex: "^j", isPrefix: true, name: "Jagged", description: "Reflects (25-50) Physical Damage to Melee Attackers", ilevel: 35, family: "AttackerTakesDamageNoRange"},
    {regex: "^ba", isPrefix: true, name: "Barbed", description: "Reflects (11-24) Physical Damage to Melee Attackers", ilevel: 20, family: "AttackerTakesDamageNoRange"},
    {regex: "iny", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^t", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "sm", isPrefix: true, name: "Phantasm's", description: "+(301-375) to Evasion Rating|+(73-80) to maximum Energy Shield", ilevel: 79, family: "BaseLocalDefences"},
    {regex: "^ap", isPrefix: true, name: "Apparition's", description: "+(221-300) to Evasion Rating|+(61-72) to maximum Energy Shield", ilevel: 69, family: "BaseLocalDefences"},
    {regex: "ei", isPrefix: true, name: "Eidolon's", description: "+(146-220) to Evasion Rating|+(49-60) to maximum Energy Shield", ilevel: 58, family: "BaseLocalDefences"},
    {regex: "pir", isPrefix: true, name: "Spirit's", description: "+(86-145) to Evasion Rating|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "ub", isPrefix: true, name: "Cherub's", description: "+(49-85) to Evasion Rating|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "sy", isPrefix: true, name: "Sylph's", description: "+(28-48) to Evasion Rating|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nymph's", description: "+(10-27) to Evasion Rating|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "^wi", isPrefix: true, name: "Will-o-wisp's", description: "+(5-9) to Evasion Rating|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "^bi", isPrefix: true, name: "Bishop's", description: "+(26-30) to maximum Energy Shield|+(23-25) to maximum Mana", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "xa", isPrefix: true, name: "Exarch's", description: "+(26-30) to maximum Energy Shield|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "x'", isPrefix: true, name: "Ibex's", description: "+(96-120) to Evasion Rating|+(34-38) to maximum Life", ilevel: 78, family: "BaseLocalDefencesAndLife"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "ram", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "r'", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "wn", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(20-22)% chance to Suppress Spell Damage", ilevel: 86, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(17-19)% chance to Suppress Spell Damage", ilevel: 77, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(14-16)% chance to Suppress Spell Damage", ilevel: 67, family: "ChanceToSuppressSpells"},
    {regex: "sn", isPrefix: false, name: "of Snuffing", description: "+(11-13)% chance to Suppress Spell Damage", ilevel: 58, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(8-10)% chance to Suppress Spell Damage", ilevel: 45, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "pol", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "ye", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "rp", isPrefix: true, name: "Incorporeal", description: "(101-110)% increased Evasion and Energy Shield", ilevel: 86, family: "DefencesPercent"},
    {regex: "^il", isPrefix: true, name: "Illusory", description: "(92-100)% increased Evasion and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "nr", isPrefix: true, name: "Unreal", description: "(80-91)% increased Evasion and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^ev", isPrefix: true, name: "Evanescent", description: "(68-79)% increased Evasion and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^ep", isPrefix: true, name: "Ephemeral", description: "(56-67)% increased Evasion and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nw", isPrefix: true, name: "Unworldly", description: "(43-55)% increased Evasion and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "^et", isPrefix: true, name: "Ethereal", description: "(27-42)% increased Evasion and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "wy", isPrefix: true, name: "Shadowy", description: "(15-26)% increased Evasion and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "nf", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^wa", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "e wi", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "agu", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "fo", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "ong", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "d w", isPrefix: false, name: "of Second Wind", description: "(59-66)% faster start of Energy Shield Recharge", ilevel: 86, family: "EnergyShieldDelay"},
    {regex: "gou", isPrefix: false, name: "of Vigour", description: "(51-58)% faster start of Energy Shield Recharge", ilevel: 77, family: "EnergyShieldDelay"},
    {regex: "lv", isPrefix: false, name: "of Galvanising", description: "(43-50)% faster start of Energy Shield Recharge", ilevel: 67, family: "EnergyShieldDelay"},
    {regex: "ze", isPrefix: false, name: "of Zest", description: "(35-42)% faster start of Energy Shield Recharge", ilevel: 58, family: "EnergyShieldDelay"},
    {regex: "nl", isPrefix: false, name: "of Enlivening", description: "(27-34)% faster start of Energy Shield Recharge", ilevel: 45, family: "EnergyShieldDelay"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "sal", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "prim", isPrefix: true, name: "Prime", description: "+(175-189) to maximum Life", ilevel: 86, family: "IncreasedLife"},
    {regex: "rap", isPrefix: true, name: "Rapturous", description: "+(160-174) to maximum Life", ilevel: 81, family: "IncreasedLife"},
    {regex: "gor", isPrefix: true, name: "Vigorous", description: "+(145-159) to maximum Life", ilevel: 73, family: "IncreasedLife"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "ril", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "^he", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "^bl", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "^ma", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "iu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "av", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "nv", isPrefix: false, name: "of Convalescence", description: "Regenerate (152.1-176) Life per second", ilevel: 86, family: "LifeRegeneration"},
    {regex: "giv", isPrefix: false, name: "of Life-giving", description: "Regenerate (128.1-152) Life per second", ilevel: 83, family: "LifeRegeneration"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "arf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "ae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt$", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (str)" : [
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "tho", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "nv", isPrefix: true, name: "Enveloped", description: "+(151-200) to Armour", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "nca", isPrefix: true, name: "Encased", description: "+(121-150) to Armour", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^ca", isPrefix: true, name: "Carapaced", description: "+(102-120) to Armour", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Plated", description: "+(83-101) to Armour", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "^fo", isPrefix: true, name: "Fortified", description: "+(64-82) to Armour", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "bb", isPrefix: true, name: "Ribbed", description: "+(36-63) to Armour", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "udd", isPrefix: true, name: "Studded", description: "+(13-35) to Armour", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^lac", isPrefix: true, name: "Lacquered", description: "+(6-12) to Armour", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "gn", isPrefix: true, name: "Impregnable", description: "(92-100)% increased Armour", ilevel: 84, family: "DefencesPercent"},
    {regex: "^gi", isPrefix: true, name: "Girded", description: "(80-91)% increased Armour", ilevel: 72, family: "DefencesPercent"},
    {regex: "ken", isPrefix: true, name: "Thickened", description: "(68-79)% increased Armour", ilevel: 60, family: "DefencesPercent"},
    {regex: "^bu", isPrefix: true, name: "Buttressed", description: "(56-67)% increased Armour", ilevel: 42, family: "DefencesPercent"},
    {regex: "bs", isPrefix: true, name: "Lobstered", description: "(43-55)% increased Armour", ilevel: 29, family: "DefencesPercent"},
    {regex: "^lay", isPrefix: true, name: "Layered", description: "(27-42)% increased Armour", ilevel: 17, family: "DefencesPercent"},
    {regex: "ei", isPrefix: true, name: "Reinforced", description: "(15-26)% increased Armour", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "t'", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour|(12-13)% increased Stun and Block Recovery", ilevel: 42, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour|(10-11)% increased Stun and Block Recovery", ilevel: 29, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour|(8-9)% increased Stun and Block Recovery", ilevel: 17, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour|(6-7)% increased Stun and Block Recovery", ilevel: 1, family: "DefencesPercentAndStunRecovery"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "lam", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sn", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "ym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "ude", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "og", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "id", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^dr", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "rys", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "uth", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "rp", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "j", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "lou", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "br", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "f ir", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ston", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "k s", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (dex)" : [
    {regex: "spi", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^th", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "elu", isPrefix: true, name: "Elusory", description: "+(151-200) to Evasion Rating", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "^va", isPrefix: true, name: "Vaporous", description: "+(121-150) to Evasion Rating", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "has", isPrefix: true, name: "Phased", description: "+(102-120) to Evasion Rating", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "^bl", isPrefix: true, name: "Blurred", description: "+(83-101) to Evasion Rating", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Fleet", description: "+(64-82) to Evasion Rating", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "^ac", isPrefix: true, name: "Acrobat's", description: "+(36-63) to Evasion Rating", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "^da", isPrefix: true, name: "Dancer's", description: "+(13-35) to Evasion Rating", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^ag", isPrefix: true, name: "Agile", description: "+(6-12) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "ram", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^fa", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "ab", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "lost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "eng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^mi", isPrefix: true, name: "Mirage's", description: "(92-100)% increased Evasion Rating", ilevel: 84, family: "DefencesPercent"},
    {regex: "^n", isPrefix: true, name: "Nightmare's", description: "(80-91)% increased Evasion Rating", ilevel: 72, family: "DefencesPercent"},
    {regex: "asm", isPrefix: true, name: "Phantasm's", description: "(68-79)% increased Evasion Rating", ilevel: 60, family: "DefencesPercent"},
    {regex: "wr", isPrefix: true, name: "Wraith's", description: "(56-67)% increased Evasion Rating", ilevel: 44, family: "DefencesPercent"},
    {regex: "pec", isPrefix: true, name: "Spectre's", description: "(43-55)% increased Evasion Rating", ilevel: 30, family: "DefencesPercent"},
    {regex: "^gh", isPrefix: true, name: "Ghost's", description: "(27-42)% increased Evasion Rating", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sh", isPrefix: true, name: "Shade's", description: "(15-26)% increased Evasion Rating", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion Rating|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "nf", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion Rating|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "p'", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion Rating|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion Rating|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion Rating|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion Rating|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "ind", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "mon", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^vi", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "obu", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "alt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "ym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "rc", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "aid", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "on'", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iza", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "ia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "oru", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "f ir", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ston", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (int)" : [
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^th", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "zi", isPrefix: true, name: "Blazing", description: "+(50-61) to maximum Energy Shield", ilevel: 51, family: "BaseLocalDefences"},
    {regex: "eet", isPrefix: true, name: "Seething", description: "+(39-49) to maximum Energy Shield", ilevel: 43, family: "BaseLocalDefences"},
    {regex: "^pu", isPrefix: true, name: "Pulsing", description: "+(31-38) to maximum Energy Shield", ilevel: 35, family: "BaseLocalDefences"},
    {regex: "iat", isPrefix: true, name: "Radiating", description: "+(24-30) to maximum Energy Shield", ilevel: 29, family: "BaseLocalDefences"},
    {regex: "owi", isPrefix: true, name: "Glowing", description: "+(17-23) to maximum Energy Shield", ilevel: 23, family: "BaseLocalDefences"},
    {regex: "itt", isPrefix: true, name: "Glittering", description: "+(12-16) to maximum Energy Shield", ilevel: 17, family: "BaseLocalDefences"},
    {regex: "mm", isPrefix: true, name: "Glimmering", description: "+(6-11) to maximum Energy Shield", ilevel: 11, family: "BaseLocalDefences"},
    {regex: "^sh", isPrefix: true, name: "Shining", description: "+(3-5) to maximum Energy Shield", ilevel: 3, family: "BaseLocalDefences"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "eac", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "bea", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^u", isPrefix: true, name: "Unassailable", description: "(92-100)% increased Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "ndo", isPrefix: true, name: "Indomitable", description: "(80-91)% increased Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "tl", isPrefix: true, name: "Dauntless", description: "(68-79)% increased Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "fea", isPrefix: true, name: "Fearless", description: "(56-67)% increased Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "eso", isPrefix: true, name: "Resolute", description: "(43-55)% increased Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ong", isPrefix: true, name: "Strong-Willed", description: "(27-42)% increased Energy Shield", ilevel: 18, family: "DefencesPercent"},
    {regex: "ote", isPrefix: true, name: "Protective", description: "(11-28)% increased Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^n", isPrefix: true, name: "Naga's", description: "(27-32)% increased Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "gg", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 18, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 3, family: "DefencesPercentAndStunRecovery"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "lam", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sn", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "otu", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "blu", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "ym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "hae", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "rai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^dr", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "pir", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iza", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "f sh", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "el s", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "f ir", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "hic", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (str, dex)" : [
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^th", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "dy", isPrefix: true, name: "Sturdy", description: "+(86-145) to Armour|+(86-145) to Evasion Rating", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "dur", isPrefix: true, name: "Durable", description: "+(49-85) to Armour|+(49-85) to Evasion Rating", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "lex", isPrefix: true, name: "Flexible", description: "+(28-48) to Armour|+(28-48) to Evasion Rating", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^pl", isPrefix: true, name: "Pliant", description: "+(10-27) to Armour|+(10-27) to Evasion Rating", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ppl", isPrefix: true, name: "Supple", description: "+(5-9) to Armour|+(5-9) to Evasion Rating", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "m'", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^n", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "^fa", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "xil", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "peng", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "d'", isPrefix: true, name: "Legend's", description: "(92-100)% increased Armour and Evasion", ilevel: 84, family: "DefencesPercent"},
    {regex: "ero", isPrefix: true, name: "Hero's", description: "(80-91)% increased Armour and Evasion", ilevel: 72, family: "DefencesPercent"},
    {regex: "due", isPrefix: true, name: "Duelist's", description: "(68-79)% increased Armour and Evasion", ilevel: 60, family: "DefencesPercent"},
    {regex: "^gl", isPrefix: true, name: "Gladiator's", description: "(56-67)% increased Armour and Evasion", ilevel: 44, family: "DefencesPercent"},
    {regex: "^fen", isPrefix: true, name: "Fencer's", description: "(43-55)% increased Armour and Evasion", ilevel: 30, family: "DefencesPercent"},
    {regex: "wl", isPrefix: true, name: "Brawler's", description: "(27-42)% increased Armour and Evasion", ilevel: 19, family: "DefencesPercent"},
    {regex: "^sc", isPrefix: true, name: "Scrapper's", description: "(15-26)% increased Armour and Evasion", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Mammoth's", description: "(39-42)% increased Armour and Evasion|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "lep", isPrefix: true, name: "Elephant's", description: "(33-38)% increased Armour and Evasion|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^rh", isPrefix: true, name: "Rhino's", description: "(27-32)% increased Armour and Evasion|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^ar", isPrefix: true, name: "Armadillo's", description: "(21-26)% increased Armour and Evasion|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "b'", isPrefix: true, name: "Crab's", description: "(14-20)% increased Armour and Evasion|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "^be", isPrefix: true, name: "Beetle's", description: "(6-13)% increased Armour and Evasion|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "wi", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "nto", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fu", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "fec", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "^sa", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lt", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "ym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "og", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "ai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^dr", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "uth", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "iv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "rp", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "dian", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shi", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "bru", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ston", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (str, int)" : [
    {regex: "^sp", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^th", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "^bea", isPrefix: true, name: "Beatified", description: "+(86-145) to Armour|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "hall", isPrefix: true, name: "Hallowed", description: "+(49-85) to Armour|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "nct", isPrefix: true, name: "Sanctified", description: "+(28-48) to Armour|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^an", isPrefix: true, name: "Anointed", description: "+(10-27) to Armour|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "ble", isPrefix: true, name: "Blessed", description: "+(5-9) to Armour|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "ab", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "s'", isPrefix: true, name: "Nautilus's", description: "+(49-96) to Armour|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "eac", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^u", isPrefix: true, name: "Urchin's", description: "+(33-48) to Armour|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "oy", isPrefix: true, name: "Oyster's", description: "+(20-32) to Armour|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xpu", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "vic", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "aa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "lar", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "nu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "nsp", isPrefix: true, name: "Inspired", description: "(92-100)% increased Armour and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "rpo", isPrefix: true, name: "Interpolated", description: "(80-91)% increased Armour and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "ncu", isPrefix: true, name: "Inculcated", description: "(68-79)% increased Armour and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "nfu", isPrefix: true, name: "Infused", description: "(56-67)% increased Armour and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nst", isPrefix: true, name: "Instilled", description: "(43-55)% increased Armour and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "ngr", isPrefix: true, name: "Ingrained", description: "(27-42)% increased Armour and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "xe", isPrefix: true, name: "Infixed", description: "(15-26)% increased Armour and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "m'", isPrefix: true, name: "Seraphim's", description: "(39-42)% increased Armour and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "dj", isPrefix: true, name: "Djinn's", description: "(33-38)% increased Armour and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "a'", isPrefix: true, name: "Naga's", description: "(27-32)% increased Armour and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "gg", isPrefix: true, name: "Boggart's", description: "(21-26)% increased Armour and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "ml", isPrefix: true, name: "Gremlin's", description: "(14-20)% increased Armour and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "pix", isPrefix: true, name: "Pixie's", description: "(6-13)% increased Armour and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "do", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vo", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "lam", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "sn", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "^f", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "sang", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "hale", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "ue", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "sc", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "yl", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "ym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "rod", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "hae", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "id", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "^dr", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "ira", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "rf", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iz", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "rl", isPrefix: false, name: "of Everlasting", description: "(20-21)% increased Life Regeneration rate", ilevel: 85, family: "LifeRegenerationRate"},
    {regex: "yo", isPrefix: false, name: "of Youth", description: "(18-19)% increased Life Regeneration rate", ilevel: 76, family: "LifeRegenerationRate"},
    {regex: "viv", isPrefix: false, name: "of Vivification", description: "(15-17)% increased Life Regeneration rate", ilevel: 68, family: "LifeRegenerationRate"},
    {regex: "rpe", isPrefix: false, name: "of Perpetuity", description: "(12-14)% increased Life Regeneration rate", ilevel: 57, family: "LifeRegenerationRate"},
    {regex: "esp", isPrefix: false, name: "of Esprit", description: "(9-11)% increased Life Regeneration rate", ilevel: 46, family: "LifeRegenerationRate"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "shin", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "ep", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "om", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "hu", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "sq", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "ort", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "ds", isPrefix: false, name: "of the Gods", description: "+(51-55) to Strength", ilevel: 82, family: "Strength"},
    {regex: "ita", isPrefix: false, name: "of the Titan", description: "+(43-50) to Strength", ilevel: 74, family: "Strength"},
    {regex: "via", isPrefix: false, name: "of the Leviathan", description: "+(38-42) to Strength", ilevel: 66, family: "Strength"},
    {regex: "gol", isPrefix: false, name: "of the Goliath", description: "+(33-37) to Strength", ilevel: 55, family: "Strength"},
    {regex: "gor", isPrefix: false, name: "of the Gorilla", description: "+(28-32) to Strength", ilevel: 44, family: "Strength"},
    {regex: "lion$", isPrefix: false, name: "of the Lion", description: "+(23-27) to Strength", ilevel: 33, family: "Strength"},
    {regex: "e be", isPrefix: false, name: "of the Bear", description: "+(18-22) to Strength", ilevel: 22, family: "Strength"},
    {regex: "wr", isPrefix: false, name: "of the Wrestler", description: "+(13-17) to Strength", ilevel: 11, family: "Strength"},
    {regex: "br", isPrefix: false, name: "of the Brute", description: "+(8-12) to Strength", ilevel: 1, family: "Strength"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "iro", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Helmets (dex, int)" : [
    {regex: "pin", isPrefix: true, name: "Spiny", description: "Reflects (5-10) Physical Damage to Melee Attackers", ilevel: 10, family: "AttackerTakesDamageNoRange"},
    {regex: "^th", isPrefix: true, name: "Thorny", description: "Reflects (1-4) Physical Damage to Melee Attackers", ilevel: 1, family: "AttackerTakesDamageNoRange"},
    {regex: "it'", isPrefix: true, name: "Spirit's", description: "+(86-145) to Evasion Rating|+(29-48) to maximum Energy Shield", ilevel: 46, family: "BaseLocalDefences"},
    {regex: "ub", isPrefix: true, name: "Cherub's", description: "+(49-85) to Evasion Rating|+(23-28) to maximum Energy Shield", ilevel: 38, family: "BaseLocalDefences"},
    {regex: "sy", isPrefix: true, name: "Sylph's", description: "+(28-48) to Evasion Rating|+(13-22) to maximum Energy Shield", ilevel: 30, family: "BaseLocalDefences"},
    {regex: "^n", isPrefix: true, name: "Nymph's", description: "+(10-27) to Evasion Rating|+(5-12) to maximum Energy Shield", ilevel: 18, family: "BaseLocalDefences"},
    {regex: "^wi", isPrefix: true, name: "Will-o-wisp's", description: "+(5-9) to Evasion Rating|+(3-4) to maximum Energy Shield", ilevel: 1, family: "BaseLocalDefences"},
    {regex: "rie", isPrefix: true, name: "Priest's", description: "+(16-25) to maximum Energy Shield|+(20-22) to maximum Mana", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "bb", isPrefix: true, name: "Abbot's", description: "+(16-25) to maximum Energy Shield|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "m'", isPrefix: true, name: "Ram's", description: "+(43-95) to Evasion Rating|+(29-33) to maximum Life", ilevel: 62, family: "BaseLocalDefencesAndLife"},
    {regex: "dea", isPrefix: true, name: "Deacon's", description: "+(11-15) to maximum Energy Shield|+(16-19) to maximum Mana", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "rio", isPrefix: true, name: "Prior's", description: "+(11-15) to maximum Energy Shield|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "^fa", isPrefix: true, name: "Fawn's", description: "+(21-42) to Evasion Rating|+(24-28) to maximum Life", ilevel: 46, family: "BaseLocalDefencesAndLife"},
    {regex: "a'", isPrefix: true, name: "Flea's", description: "+(14-20) to Evasion Rating|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "yt", isPrefix: true, name: "Acolyte's", description: "+(8-10) to maximum Energy Shield|+(11-15) to maximum Mana", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nk", isPrefix: true, name: "Monk's", description: "+(8-10) to maximum Energy Shield|+(18-23) to maximum Life", ilevel: 30, family: "BaseLocalDefencesAndLife"},
    {regex: "nul", isPrefix: false, name: "of Nullification", description: "+(13-14)% chance to Suppress Spell Damage", ilevel: 85, family: "ChanceToSuppressSpells"},
    {regex: "bj", isPrefix: false, name: "of Abjuration", description: "+(11-12)% chance to Suppress Spell Damage", ilevel: 76, family: "ChanceToSuppressSpells"},
    {regex: "ok", isPrefix: false, name: "of Revoking", description: "+(9-10)% chance to Suppress Spell Damage", ilevel: 68, family: "ChanceToSuppressSpells"},
    {regex: "snu", isPrefix: false, name: "of Snuffing", description: "+(7-8)% chance to Suppress Spell Damage", ilevel: 57, family: "ChanceToSuppressSpells"},
    {regex: "eb", isPrefix: false, name: "of Rebuttal", description: "+(5-6)% chance to Suppress Spell Damage", ilevel: 46, family: "ChanceToSuppressSpells"},
    {regex: "bam", isPrefix: false, name: "of Bameth", description: "+(31-35)% to Chaos Resistance", ilevel: 81, family: "ChaosResistance"},
    {regex: "exi", isPrefix: false, name: "of Exile", description: "+(26-30)% to Chaos Resistance", ilevel: 65, family: "ChaosResistance"},
    {regex: "xp", isPrefix: false, name: "of Expulsion", description: "+(21-25)% to Chaos Resistance", ilevel: 56, family: "ChaosResistance"},
    {regex: "evi", isPrefix: false, name: "of Eviction", description: "+(16-20)% to Chaos Resistance", ilevel: 44, family: "ChaosResistance"},
    {regex: "hm", isPrefix: false, name: "of Banishment", description: "+(11-15)% to Chaos Resistance", ilevel: 30, family: "ChaosResistance"},
    {regex: "ost", isPrefix: false, name: "of the Lost", description: "+(5-10)% to Chaos Resistance", ilevel: 16, family: "ChaosResistance"},
    {regex: "haa", isPrefix: false, name: "of Haast", description: "+(46-48)% to Cold Resistance", ilevel: 84, family: "ColdResistance"},
    {regex: "ice", isPrefix: false, name: "of the Ice", description: "+(42-45)% to Cold Resistance", ilevel: 72, family: "ColdResistance"},
    {regex: "ola", isPrefix: false, name: "of the Polar Bear", description: "+(36-41)% to Cold Resistance", ilevel: 60, family: "ColdResistance"},
    {regex: "lr", isPrefix: false, name: "of the Walrus", description: "+(30-35)% to Cold Resistance", ilevel: 50, family: "ColdResistance"},
    {regex: "i$", isPrefix: false, name: "of the Yeti", description: "+(24-29)% to Cold Resistance", ilevel: 38, family: "ColdResistance"},
    {regex: "pen", isPrefix: false, name: "of the Penguin", description: "+(18-23)% to Cold Resistance", ilevel: 26, family: "ColdResistance"},
    {regex: "sea", isPrefix: false, name: "of the Seal", description: "+(12-17)% to Cold Resistance", ilevel: 14, family: "ColdResistance"},
    {regex: "inu", isPrefix: false, name: "of the Inuit", description: "+(6-11)% to Cold Resistance", ilevel: 1, family: "ColdResistance"},
    {regex: "^il", isPrefix: true, name: "Illusory", description: "(92-100)% increased Evasion and Energy Shield", ilevel: 84, family: "DefencesPercent"},
    {regex: "nr", isPrefix: true, name: "Unreal", description: "(80-91)% increased Evasion and Energy Shield", ilevel: 72, family: "DefencesPercent"},
    {regex: "^ev", isPrefix: true, name: "Evanescent", description: "(68-79)% increased Evasion and Energy Shield", ilevel: 60, family: "DefencesPercent"},
    {regex: "^ep", isPrefix: true, name: "Ephemeral", description: "(56-67)% increased Evasion and Energy Shield", ilevel: 44, family: "DefencesPercent"},
    {regex: "nw", isPrefix: true, name: "Unworldly", description: "(43-55)% increased Evasion and Energy Shield", ilevel: 30, family: "DefencesPercent"},
    {regex: "^et", isPrefix: true, name: "Ethereal", description: "(27-42)% increased Evasion and Energy Shield", ilevel: 19, family: "DefencesPercent"},
    {regex: "wy", isPrefix: true, name: "Shadowy", description: "(15-26)% increased Evasion and Energy Shield", ilevel: 3, family: "DefencesPercent"},
    {regex: "mm", isPrefix: true, name: "Hummingbird's", description: "(39-42)% increased Evasion and Energy Shield|(16-17)% increased Stun and Block Recovery", ilevel: 78, family: "DefencesPercentAndStunRecovery"},
    {regex: "nf", isPrefix: true, name: "Dragonfly's", description: "(33-38)% increased Evasion and Energy Shield|(14-15)% increased Stun and Block Recovery", ilevel: 60, family: "DefencesPercentAndStunRecovery"},
    {regex: "^wa", isPrefix: true, name: "Wasp's", description: "(27-32)% increased Evasion and Energy Shield|(12-13)% increased Stun and Block Recovery", ilevel: 44, family: "DefencesPercentAndStunRecovery"},
    {regex: "^bu", isPrefix: true, name: "Butterfly's", description: "(21-26)% increased Evasion and Energy Shield|(10-11)% increased Stun and Block Recovery", ilevel: 30, family: "DefencesPercentAndStunRecovery"},
    {regex: "mot", isPrefix: true, name: "Moth's", description: "(14-20)% increased Evasion and Energy Shield|(8-9)% increased Stun and Block Recovery", ilevel: 19, family: "DefencesPercentAndStunRecovery"},
    {regex: "o'", isPrefix: true, name: "Mosquito's", description: "(6-13)% increased Evasion and Energy Shield|(6-7)% increased Stun and Block Recovery", ilevel: 2, family: "DefencesPercentAndStunRecovery"},
    {regex: "ind", isPrefix: false, name: "of the Wind", description: "+(51-55) to Dexterity", ilevel: 82, family: "Dexterity"},
    {regex: "pha", isPrefix: false, name: "of the Phantom", description: "+(43-50) to Dexterity", ilevel: 74, family: "Dexterity"},
    {regex: "ja", isPrefix: false, name: "of the Jaguar", description: "+(38-42) to Dexterity", ilevel: 66, family: "Dexterity"},
    {regex: "leo", isPrefix: false, name: "of the Leopard", description: "+(33-37) to Dexterity", ilevel: 55, family: "Dexterity"},
    {regex: "pan", isPrefix: false, name: "of the Panther", description: "+(28-32) to Dexterity", ilevel: 44, family: "Dexterity"},
    {regex: "fal", isPrefix: false, name: "of the Falcon", description: "+(23-27) to Dexterity", ilevel: 33, family: "Dexterity"},
    {regex: "ox", isPrefix: false, name: "of the Fox", description: "+(18-22) to Dexterity", ilevel: 22, family: "Dexterity"},
    {regex: "yn", isPrefix: false, name: "of the Lynx", description: "+(13-17) to Dexterity", ilevel: 11, family: "Dexterity"},
    {regex: "oo", isPrefix: false, name: "of the Mongoose", description: "+(8-12) to Dexterity", ilevel: 1, family: "Dexterity"},
    {regex: "rdo", isPrefix: false, name: "of Ardour", description: "(36-38)% increased Energy Shield Recharge Rate", ilevel: 85, family: "EnergyShieldRegeneration"},
    {regex: "buf", isPrefix: false, name: "of Buffering", description: "(33-35)% increased Energy Shield Recharge Rate", ilevel: 76, family: "EnergyShieldRegeneration"},
    {regex: "dis", isPrefix: false, name: "of Dispersal", description: "(30-32)% increased Energy Shield Recharge Rate", ilevel: 68, family: "EnergyShieldRegeneration"},
    {regex: "iff", isPrefix: false, name: "of Diffusion", description: "(27-29)% increased Energy Shield Recharge Rate", ilevel: 57, family: "EnergyShieldRegeneration"},
    {regex: "ay", isPrefix: false, name: "of Allaying", description: "(24-26)% increased Energy Shield Recharge Rate", ilevel: 46, family: "EnergyShieldRegeneration"},
    {regex: "tz", isPrefix: false, name: "of Tzteosh", description: "+(46-48)% to Fire Resistance", ilevel: 84, family: "FireResistance"},
    {regex: "agm", isPrefix: false, name: "of the Magma", description: "+(42-45)% to Fire Resistance", ilevel: 72, family: "FireResistance"},
    {regex: "vol", isPrefix: false, name: "of the Volcano", description: "+(36-41)% to Fire Resistance", ilevel: 60, family: "FireResistance"},
    {regex: "fur", isPrefix: false, name: "of the Furnace", description: "+(30-35)% to Fire Resistance", ilevel: 48, family: "FireResistance"},
    {regex: "ln", isPrefix: false, name: "of the Kiln", description: "+(24-29)% to Fire Resistance", ilevel: 36, family: "FireResistance"},
    {regex: "ak", isPrefix: false, name: "of the Drake", description: "+(18-23)% to Fire Resistance", ilevel: 24, family: "FireResistance"},
    {regex: "ala", isPrefix: false, name: "of the Salamander", description: "+(12-17)% to Fire Resistance", ilevel: 12, family: "FireResistance"},
    {regex: "wh", isPrefix: false, name: "of the Whelpling", description: "+(6-11)% to Fire Resistance", ilevel: 1, family: "FireResistance"},
    {regex: "^ov", isPrefix: true, name: "Overseer's", description: "+2 to Level of all Minion Skill Gems", ilevel: 75, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "km", isPrefix: true, name: "Taskmaster's", description: "+1 to Level of all Minion Skill Gems", ilevel: 14, family: "IncreaseSpecificSocketedGemLevel"},
    {regex: "ey", isPrefix: false, name: "of Lioneye", description: "+(481-600) to Accuracy Rating", ilevel: 85, family: "IncreasedAccuracy"},
    {regex: "ger", isPrefix: false, name: "of the Ranger", description: "+(351-480) to Accuracy Rating", ilevel: 75, family: "IncreasedAccuracy"},
    {regex: "rk", isPrefix: false, name: "of the Marksman", description: "+(251-350) to Accuracy Rating", ilevel: 60, family: "IncreasedAccuracy"},
    {regex: "ip", isPrefix: false, name: "of the Sniper", description: "+(166-250) to Accuracy Rating", ilevel: 40, family: "IncreasedAccuracy"},
    {regex: "eci", isPrefix: false, name: "of Precision", description: "+(100-165) to Accuracy Rating", ilevel: 20, family: "IncreasedAccuracy"},
    {regex: "tea", isPrefix: false, name: "of Steadiness", description: "+(50-100) to Accuracy Rating", ilevel: 1, family: "IncreasedAccuracy"},
    {regex: "^fe", isPrefix: true, name: "Fecund", description: "+(130-144) to maximum Life", ilevel: 64, family: "IncreasedLife"},
    {regex: "hl", isPrefix: true, name: "Athlete's", description: "+(115-129) to maximum Life", ilevel: 54, family: "IncreasedLife"},
    {regex: "^v", isPrefix: true, name: "Virile", description: "+(100-114) to maximum Life", ilevel: 44, family: "IncreasedLife"},
    {regex: "rot", isPrefix: true, name: "Rotund", description: "+(85-99) to maximum Life", ilevel: 36, family: "IncreasedLife"},
    {regex: "rob", isPrefix: true, name: "Robust", description: "+(70-84) to maximum Life", ilevel: 30, family: "IncreasedLife"},
    {regex: "tou", isPrefix: true, name: "Stout", description: "+(55-69) to maximum Life", ilevel: 24, family: "IncreasedLife"},
    {regex: "lw", isPrefix: true, name: "Stalwart", description: "+(40-54) to maximum Life", ilevel: 18, family: "IncreasedLife"},
    {regex: "san", isPrefix: true, name: "Sanguine", description: "+(25-39) to maximum Life", ilevel: 11, family: "IncreasedLife"},
    {regex: "lth", isPrefix: true, name: "Healthy", description: "+(10-24) to maximum Life", ilevel: 5, family: "IncreasedLife"},
    {regex: "^ha", isPrefix: true, name: "Hale", description: "+(3-9) to maximum Life", ilevel: 1, family: "IncreasedLife"},
    {regex: "^z", isPrefix: true, name: "Zaffre", description: "+(69-73) to maximum Mana", ilevel: 81, family: "IncreasedMana"},
    {regex: "^bl", isPrefix: true, name: "Blue", description: "+(65-68) to maximum Mana", ilevel: 75, family: "IncreasedMana"},
    {regex: "maz", isPrefix: true, name: "Mazarine", description: "+(60-64) to maximum Mana", ilevel: 69, family: "IncreasedMana"},
    {regex: "yb", isPrefix: true, name: "Chalybeous", description: "+(55-59) to maximum Mana", ilevel: 60, family: "IncreasedMana"},
    {regex: "^ge", isPrefix: true, name: "Gentian", description: "+(50-54) to maximum Mana", ilevel: 51, family: "IncreasedMana"},
    {regex: "^op", isPrefix: true, name: "Opalescent", description: "+(45-49) to maximum Mana", ilevel: 42, family: "IncreasedMana"},
    {regex: "aq", isPrefix: true, name: "Aqua", description: "+(40-44) to maximum Mana", ilevel: 35, family: "IncreasedMana"},
    {regex: "^ce", isPrefix: true, name: "Cerulean", description: "+(35-39) to maximum Mana", ilevel: 29, family: "IncreasedMana"},
    {regex: "sap", isPrefix: true, name: "Sapphire", description: "+(30-34) to maximum Mana", ilevel: 23, family: "IncreasedMana"},
    {regex: "zu", isPrefix: true, name: "Azure", description: "+(25-29) to maximum Mana", ilevel: 17, family: "IncreasedMana"},
    {regex: "^co", isPrefix: true, name: "Cobalt", description: "+(20-24) to maximum Mana", ilevel: 11, family: "IncreasedMana"},
    {regex: "^be", isPrefix: true, name: "Beryl", description: "+(15-19) to maximum Mana", ilevel: 1, family: "IncreasedMana"},
    {regex: "lym", isPrefix: false, name: "of the Polymath", description: "+(56-60) to Intelligence", ilevel: 85, family: "Intelligence"},
    {regex: "niu", isPrefix: false, name: "of the Genius", description: "+(51-55) to Intelligence", ilevel: 82, family: "Intelligence"},
    {regex: "uo", isPrefix: false, name: "of the Virtuoso", description: "+(43-50) to Intelligence", ilevel: 74, family: "Intelligence"},
    {regex: "sav", isPrefix: false, name: "of the Savant", description: "+(38-42) to Intelligence", ilevel: 66, family: "Intelligence"},
    {regex: "sag", isPrefix: false, name: "of the Sage", description: "+(33-37) to Intelligence", ilevel: 55, family: "Intelligence"},
    {regex: "hil", isPrefix: false, name: "of the Philosopher", description: "+(28-32) to Intelligence", ilevel: 44, family: "Intelligence"},
    {regex: "ugu", isPrefix: false, name: "of the Augur", description: "+(23-27) to Intelligence", ilevel: 33, family: "Intelligence"},
    {regex: "pro", isPrefix: false, name: "of the Prodigy", description: "+(18-22) to Intelligence", ilevel: 22, family: "Intelligence"},
    {regex: "tud", isPrefix: false, name: "of the Student", description: "+(13-17) to Intelligence", ilevel: 11, family: "Intelligence"},
    {regex: "pup", isPrefix: false, name: "of the Pupil", description: "+(8-12) to Intelligence", ilevel: 1, family: "Intelligence"},
    {regex: "xc", isPrefix: false, name: "of Excavation", description: "(21-26)% increased Rarity of Items found", ilevel: 75, family: "ItemFoundRarityIncrease"},
    {regex: "rc", isPrefix: false, name: "of Archaeology", description: "(15-20)% increased Rarity of Items found", ilevel: 53, family: "ItemFoundRarityIncrease"},
    {regex: "ai", isPrefix: false, name: "of Raiding", description: "(11-14)% increased Rarity of Items found", ilevel: 30, family: "ItemFoundRarityIncrease"},
    {regex: "plu", isPrefix: false, name: "of Plunder", description: "(6-10)% increased Rarity of Items found", ilevel: 3, family: "ItemFoundRarityIncrease"},
    {regex: "gon'", isPrefix: true, name: "Dragon's", description: "(19-24)% increased Rarity of Items found", ilevel: 62, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "^pi", isPrefix: true, name: "Pirate's", description: "(13-18)% increased Rarity of Items found", ilevel: 39, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "gp", isPrefix: true, name: "Magpie's", description: "(8-12)% increased Rarity of Items found", ilevel: 20, family: "ItemFoundRarityIncreasePrefix"},
    {regex: "cup", isPrefix: false, name: "of Recuperation", description: "Regenerate (96.1-128) Life per second", ilevel: 78, family: "LifeRegeneration"},
    {regex: "oe", isPrefix: false, name: "of the Phoenix", description: "Regenerate (64.1-96) Life per second", ilevel: 74, family: "LifeRegeneration"},
    {regex: "sl", isPrefix: false, name: "of Ryslatha", description: "Regenerate (48.1-64) Life per second", ilevel: 68, family: "LifeRegeneration"},
    {regex: "rol", isPrefix: false, name: "of the Troll", description: "Regenerate (32.1-48) Life per second", ilevel: 55, family: "LifeRegeneration"},
    {regex: "yd", isPrefix: false, name: "of the Hydra", description: "Regenerate (24.1-32) Life per second", ilevel: 44, family: "LifeRegeneration"},
    {regex: "tar", isPrefix: false, name: "of the Starfish", description: "Regenerate (16.1-24) Life per second", ilevel: 31, family: "LifeRegeneration"},
    {regex: "tw", isPrefix: false, name: "of the Flatworm", description: "Regenerate (8.1-16) Life per second", ilevel: 19, family: "LifeRegeneration"},
    {regex: "iza", isPrefix: false, name: "of the Lizard", description: "Regenerate (2.1-8) Life per second", ilevel: 7, family: "LifeRegeneration"},
    {regex: "ew", isPrefix: false, name: "of the Newt", description: "Regenerate (1-2) Life per second", ilevel: 1, family: "LifeRegeneration"},
    {regex: "dia", isPrefix: false, name: "of Radiance", description: "(16-20)% increased Global Accuracy Rating|15% increased Light Radius", ilevel: 30, family: "LightRadiusAndAccuracy"},
    {regex: "ht$", isPrefix: false, name: "of Light", description: "(12-15)% increased Global Accuracy Rating|10% increased Light Radius", ilevel: 15, family: "LightRadiusAndAccuracy"},
    {regex: "hin", isPrefix: false, name: "of Shining", description: "(9-11)% increased Global Accuracy Rating|5% increased Light Radius", ilevel: 8, family: "LightRadiusAndAccuracy"},
    {regex: "ij", isPrefix: false, name: "of Ephij", description: "+(46-48)% to Lightning Resistance", ilevel: 84, family: "LightningResistance"},
    {regex: "tning$", isPrefix: false, name: "of the Lightning", description: "+(42-45)% to Lightning Resistance", ilevel: 72, family: "LightningResistance"},
    {regex: "mae", isPrefix: false, name: "of the Maelstrom", description: "+(36-41)% to Lightning Resistance", ilevel: 60, family: "LightningResistance"},
    {regex: "emp", isPrefix: false, name: "of the Tempest", description: "+(30-35)% to Lightning Resistance", ilevel: 49, family: "LightningResistance"},
    {regex: "rh", isPrefix: false, name: "of the Thunderhead", description: "+(24-29)% to Lightning Resistance", ilevel: 37, family: "LightningResistance"},
    {regex: "stor", isPrefix: false, name: "of the Storm", description: "+(18-23)% to Lightning Resistance", ilevel: 25, family: "LightningResistance"},
    {regex: "squa", isPrefix: false, name: "of the Squall", description: "+(12-17)% to Lightning Resistance", ilevel: 13, family: "LightningResistance"},
    {regex: "clo", isPrefix: false, name: "of the Cloud", description: "+(6-11)% to Lightning Resistance", ilevel: 1, family: "LightningResistance"},
    {regex: "pt", isPrefix: false, name: "of the Apt", description: "32% reduced Attribute Requirements", ilevel: 60, family: "LocalAttributeRequirements"},
    {regex: "rth", isPrefix: false, name: "of the Worthy", description: "18% reduced Attribute Requirements", ilevel: 36, family: "LocalAttributeRequirements"},
    {regex: "cor", isPrefix: false, name: "of Corundum Skin", description: "(26-28)% increased Stun and Block Recovery", ilevel: 79, family: "StunRecovery"},
    {regex: "ada", isPrefix: false, name: "of Adamantite Skin", description: "(23-25)% increased Stun and Block Recovery", ilevel: 56, family: "StunRecovery"},
    {regex: "tee", isPrefix: false, name: "of Steel Skin", description: "(20-22)% increased Stun and Block Recovery", ilevel: 42, family: "StunRecovery"},
    {regex: "f ir", isPrefix: false, name: "of Iron Skin", description: "(17-19)% increased Stun and Block Recovery", ilevel: 28, family: "StunRecovery"},
    {regex: "ton", isPrefix: false, name: "of Stone Skin", description: "(14-16)% increased Stun and Block Recovery", ilevel: 17, family: "StunRecovery"},
    {regex: "thi", isPrefix: false, name: "of Thick Skin", description: "(11-13)% increased Stun and Block Recovery", ilevel: 1, family: "StunRecovery"}
  ],
  "Tinctures" : [
    {regex: "rw", isPrefix: false, name: "of Overwhelming", description: "Melee Weapon Hits have (50-60)% chance to ignore Enemy Physical Damage Reduction", ilevel: 80, family: "ArmourPenetration"},
    {regex: "ru", isPrefix: false, name: "of Crushing", description: "Melee Weapon Hits have (40-49)% chance to ignore Enemy Physical Damage Reduction", ilevel: 62, family: "ArmourPenetration"},
    {regex: "bat", isPrefix: false, name: "of Battering", description: "Melee Weapon Hits have (30-39)% chance to ignore Enemy Physical Damage Reduction", ilevel: 40, family: "ArmourPenetration"},
    {regex: "fer", isPrefix: false, name: "of Ferocity", description: "+(29-33)% to Melee Weapon Critical Strike Multiplier", ilevel: 85, family: "CriticalStrikeMultiplier"},
    {regex: "fur", isPrefix: false, name: "of Fury", description: "+(24-28)% to Melee Weapon Critical Strike Multiplier", ilevel: 73, family: "CriticalStrikeMultiplier"},
    {regex: "rag", isPrefix: false, name: "of Rage", description: "+(19-23)% to Melee Weapon Critical Strike Multiplier", ilevel: 60, family: "CriticalStrikeMultiplier"},
    {regex: "ger", isPrefix: false, name: "of Anger", description: "+(14-18)% to Melee Weapon Critical Strike Multiplier", ilevel: 32, family: "CriticalStrikeMultiplier"},
    {regex: "re$", isPrefix: false, name: "of Ire", description: "+(9-13)% to Melee Weapon Critical Strike Multiplier", ilevel: 1, family: "CriticalStrikeMultiplier"},
    {regex: "iss", isPrefix: false, name: "of Dissolution", description: "+(29-33)% to Damage over Time Multiplier with Melee Weapon Attacks", ilevel: 84, family: "DamageOverTimeMultiplier"},
    {regex: "elt", isPrefix: false, name: "of Melting", description: "+(24-28)% to Damage over Time Multiplier with Melee Weapon Attacks", ilevel: 70, family: "DamageOverTimeMultiplier"},
    {regex: "iq", isPrefix: false, name: "of Liquefaction", description: "+(19-23)% to Damage over Time Multiplier with Melee Weapon Attacks", ilevel: 58, family: "DamageOverTimeMultiplier"},
    {regex: "isp", isPrefix: false, name: "of Dispersion", description: "+(14-18)% to Damage over Time Multiplier with Melee Weapon Attacks", ilevel: 34, family: "DamageOverTimeMultiplier"},
    {regex: "ny", isPrefix: false, name: "of Acrimony", description: "+(9-13)% to Damage over Time Multiplier with Melee Weapon Attacks", ilevel: 1, family: "DamageOverTimeMultiplier"},
    {regex: "erp", isPrefix: false, name: "of Overpowering", description: "Melee Weapon Damage Penetrates (17-19)% Elemental Resistances", ilevel: 85, family: "ElementalPenetration"},
    {regex: "nl", isPrefix: false, name: "of the Unleashed", description: "Melee Weapon Damage Penetrates (14-16)% Elemental Resistances", ilevel: 73, family: "ElementalPenetration"},
    {regex: "emp", isPrefix: false, name: "of Empowering", description: "Melee Weapon Damage Penetrates (11-13)% Elemental Resistances", ilevel: 60, family: "ElementalPenetration"},
    {regex: "nf", isPrefix: false, name: "of Infusing", description: "Melee Weapon Damage Penetrates (8-10)% Elemental Resistances", ilevel: 32, family: "ElementalPenetration"},
    {regex: "ly", isPrefix: false, name: "of Catalysing", description: "Melee Weapon Damage Penetrates (5-7)% Elemental Resistances", ilevel: 1, family: "ElementalPenetration"},
    {regex: "rf", isPrefix: false, name: "of Overflowing", description: "(26-30)% increased Flask Charges gained from Kills with Melee Weapons", ilevel: 85, family: "FlaskChargesFromWeaponKills"},
    {regex: "pou", isPrefix: false, name: "of Pouring", description: "(21-25)% increased Flask Charges gained from Kills with Melee Weapons", ilevel: 72, family: "FlaskChargesFromWeaponKills"},
    {regex: "rep", isPrefix: false, name: "of Replenishing", description: "(16-20)% increased Flask Charges gained from Kills with Melee Weapons", ilevel: 40, family: "FlaskChargesFromWeaponKills"},
    {regex: "toc", isPrefix: false, name: "of Restocking", description: "(11-15)% increased Flask Charges gained from Kills with Melee Weapons", ilevel: 1, family: "FlaskChargesFromWeaponKills"},
    {regex: "bea", isPrefix: false, name: "of the Beast", description: "(56-65)% increased Duration of Elemental Ailments from Melee Weapon Attacks", ilevel: 82, family: "IncreasedAilmentDuration"},
    {regex: "va", isPrefix: false, name: "of the Savage", description: "(46-55)% increased Duration of Elemental Ailments from Melee Weapon Attacks", ilevel: 60, family: "IncreasedAilmentDuration"},
    {regex: "unt", isPrefix: false, name: "of the Untamed", description: "(36-45)% increased Duration of Elemental Ailments from Melee Weapon Attacks", ilevel: 42, family: "IncreasedAilmentDuration"},
    {regex: "wil", isPrefix: false, name: "of the Wild", description: "(25-35)% increased Duration of Elemental Ailments from Melee Weapon Attacks", ilevel: 1, family: "IncreasedAilmentDuration"},
    {regex: "mas", isPrefix: false, name: "of Mastery", description: "(25-29)% increased Melee Weapon Attack Speed", ilevel: 85, family: "IncreasedAttackSpeed"},
    {regex: "se$", isPrefix: false, name: "of Ease", description: "(20-24)% increased Melee Weapon Attack Speed", ilevel: 65, family: "IncreasedAttackSpeed"},
    {regex: "ski", isPrefix: false, name: "of Skill", description: "(15-19)% increased Melee Weapon Attack Speed", ilevel: 1, family: "IncreasedAttackSpeed"},
    {regex: "eg", isPrefix: false, name: "of Regrowth", description: "Gain (5-7) Life per Enemy Hit with Melee Weapons", ilevel: 77, family: "LifeGainPerTarget"},
    {regex: "ora", isPrefix: false, name: "of Restoration", description: "Gain (3-4) Life per Enemy Hit with Melee Weapons", ilevel: 50, family: "LifeGainPerTarget"},
    {regex: "j", isPrefix: false, name: "of Rejuvenation", description: "Gain (2-3) Life per Enemy Hit with Melee Weapons", ilevel: 1, family: "LifeGainPerTarget"},
    {regex: "nq", isPrefix: false, name: "of Conquest", description: "Gain (26-30) Life per Enemy Killed with Melee Weapons", ilevel: 80, family: "LifeGainedFromEnemyDeath"},
    {regex: "iu", isPrefix: false, name: "of Triumph", description: "Gain (21-25) Life per Enemy Killed with Melee Weapons", ilevel: 62, family: "LifeGainedFromEnemyDeath"},
    {regex: "vi", isPrefix: false, name: "of Victory", description: "Gain (16-20) Life per Enemy Killed with Melee Weapons", ilevel: 35, family: "LifeGainedFromEnemyDeath"},
    {regex: "cc", isPrefix: false, name: "of Success", description: "Gain (11-15) Life per Enemy Killed with Melee Weapons", ilevel: 1, family: "LifeGainedFromEnemyDeath"},
    {regex: "vo", isPrefix: false, name: "of Devouring", description: "Gain 3 Mana per Enemy Hit with Melee Weapons", ilevel: 84, family: "ManaGainPerTarget"},
    {regex: "sip", isPrefix: false, name: "of Siphoning", description: "Gain 2 Mana per Enemy Hit with Melee Weapons", ilevel: 68, family: "ManaGainPerTarget"},
    {regex: "nv", isPrefix: false, name: "of Enveloping", description: "Gain 1 Mana per Enemy Hit with Melee Weapons", ilevel: 45, family: "ManaGainPerTarget"},
    {regex: "sim", isPrefix: false, name: "of Assimilation", description: "Gain (9-11) Mana per Enemy Killed with Melee Weapons", ilevel: 82, family: "ManaGainedOnWeaponKill"},
    {regex: "nsu", isPrefix: false, name: "of Consumption", description: "Gain (6-8) Mana per Enemy Killed with Melee Weapons", ilevel: 68, family: "ManaGainedOnWeaponKill"},
    {regex: "osm", isPrefix: false, name: "of Osmosis", description: "Gain (4-5) Mana per Enemy Killed with Melee Weapons", ilevel: 50, family: "ManaGainedOnWeaponKill"},
    {regex: "ab", isPrefix: false, name: "of Absorption", description: "Gain (2-3) Mana per Enemy Killed with Melee Weapons", ilevel: 38, family: "ManaGainedOnWeaponKill"},
    {regex: "^as", isPrefix: true, name: "Astute", description: "(28-32)% increased Cooldown Recovery Rate", ilevel: 82, family: "TinctureCooldownRecovery"},
    {regex: "gh", isPrefix: true, name: "Insightful", description: "(23-27)% increased Cooldown Recovery Rate", ilevel: 58, family: "TinctureCooldownRecovery"},
    {regex: "rc", isPrefix: true, name: "Perceptive", description: "(18-22)% increased Cooldown Recovery Rate", ilevel: 30, family: "TinctureCooldownRecovery"},
    {regex: "id", isPrefix: true, name: "Lucid", description: "(13-17)% increased Cooldown Recovery Rate", ilevel: 1, family: "TinctureCooldownRecovery"},
    {regex: "rt", isPrefix: true, name: "Horticultural", description: "(20-22)% increased effect", ilevel: 85, family: "TinctureEffect"},
    {regex: "mea", isPrefix: true, name: "Measured", description: "(21-25)% reduced effect|50% reduced Mana Burn rate", ilevel: 84, family: "TinctureEffect"},
    {regex: "nr", isPrefix: true, name: "Enriched", description: "35% increased effect|(37-41)% increased Mana Burn rate", ilevel: 84, family: "TinctureEffect"},
    {regex: "edi", isPrefix: true, name: "Medicinal", description: "(17-19)% increased effect", ilevel: 64, family: "TinctureEffect"},
    {regex: "^di", isPrefix: true, name: "Diluted", description: "(26-30)% reduced effect|50% reduced Mana Burn rate", ilevel: 49, family: "TinctureEffect"},
    {regex: "^co", isPrefix: true, name: "Concentrated", description: "35% increased effect|(42-46)% increased Mana Burn rate", ilevel: 49, family: "TinctureEffect"},
    {regex: "^n", isPrefix: true, name: "Natural", description: "(14-16)% increased effect", ilevel: 35, family: "TinctureEffect"},
    {regex: "rb", isPrefix: true, name: "Herbal", description: "(11-13)% increased effect", ilevel: 1, family: "TinctureEffect"},
    {regex: "^th", isPrefix: true, name: "Thin", description: "(31-35)% reduced effect|50% reduced Mana Burn rate", ilevel: 1, family: "TinctureEffect"},
    {regex: "^po", isPrefix: true, name: "Potent", description: "35% increased effect|(47-51)% increased Mana Burn rate", ilevel: 1, family: "TinctureEffect"},
    {regex: "rse", isPrefix: true, name: "Persevering", description: "(24-26)% reduced Mana Burn rate", ilevel: 80, family: "TinctureToxicityRate"},
    {regex: "rsis", isPrefix: true, name: "Persistent", description: "(21-23)% reduced Mana Burn rate", ilevel: 58, family: "TinctureToxicityRate"},
    {regex: "^te", isPrefix: true, name: "Tenacious", description: "(18-20)% reduced Mana Burn rate", ilevel: 30, family: "TinctureToxicityRate"},
    {regex: "sus", isPrefix: true, name: "Sustained", description: "(15-17)% reduced Mana Burn rate", ilevel: 1, family: "TinctureToxicityRate"},
    {regex: "ssa", isPrefix: false, name: "of Assassination", description: "(72-79)% increased Melee Weapon Damage against Enemies that are on Low Life", ilevel: 80, family: "WeaponDamageVsLowLifeEnemies"},
    {regex: "xt", isPrefix: false, name: "of Extermination", description: "(64-71)% increased Melee Weapon Damage against Enemies that are on Low Life", ilevel: 65, family: "WeaponDamageVsLowLifeEnemies"},
    {regex: "f te", isPrefix: false, name: "of Termination", description: "(56-63)% increased Melee Weapon Damage against Enemies that are on Low Life", ilevel: 47, family: "WeaponDamageVsLowLifeEnemies"},
    {regex: "eli", isPrefix: false, name: "of Elimination", description: "(48-55)% increased Melee Weapon Damage against Enemies that are on Low Life", ilevel: 28, family: "WeaponDamageVsLowLifeEnemies"},
    {regex: "xe", isPrefix: false, name: "of Execution", description: "(40-47)% increased Melee Weapon Damage against Enemies that are on Low Life", ilevel: 1, family: "WeaponDamageVsLowLifeEnemies"}
  ]
}