export interface Scarab {
  name: string
  regex: string
  icon: string
  description: string
  flavourText: string
}
export const scarabs: { [key: string]: Scarab } = {
 "Abyss Scarab": {
   name: "Abyss Scarab",
   regex: "uls",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJBYnlzcyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/5afc346c84/LesserScarabAbyss.png",
   description: "Area contains an additional Abyss",
   flavourText: "They search forever for more souls to drag to the dark.",
 },
 "Abyss Scarab of Edifice": {
   name: "Abyss Scarab of Edifice",
   regex: "gha",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQWJ5c3MiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/e6bcf6b93d/GreaterScarabAbyss.png",
   description: "Abysses in Area that do not lead to an Abyssal Depths lead to a Stygian Spire Abysses in Area spawn Hoards as they travel",
   flavourText: "Their empires ghastly and gaunt glitter with gold.",
 },
 "Abyss Scarab of Multitudes": {
   name: "Abyss Scarab of Multitudes",
   regex: "cea",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJBYnlzcyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/c78d645398/NormalScarabAbyss.png",
   description: "Abysses in Area spawn 75% increased Monsters",
   flavourText: "They ceaselessly sprout from the murderous earth.",
 },
 "Abyss Scarab of Profound Depth": {
   name: "Abyss Scarab of Profound Depth",
   regex: "g,",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYkFieXNzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/7a1f5c2928/AltTier4ScarabAbyss.png",
   description: "Monsters from Abysses in Areas have increased Difficulty and Reward for each prior Pit in that Abyss",
   flavourText: "The deeper we dig, the more we find, be it fortune or foe.",
 },
 "Ambush Scarab": {
   name: "Ambush Scarab",
   regex: "u'",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJTdHJvbmdib3hlcyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/5600ee7971/LesserScarabStrongboxes.png",
   description: "Area contains (2-5) additional Strongboxes",
   flavourText: "Is it still an ambush if you're expecting it?",
 },
 "Ambush Scarab of Containment": {
   name: "Ambush Scarab of Containment",
   regex: "urk",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYlN0cm9uZ2JveGVzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/dd7b8bd53f/Tier4ScarabStrongboxes.png",
   description: "Area contains many additional Strongboxes Area's inhabitants are lying in ambush",
   flavourText: "The silence belies lurking doom.",
 },
 "Ambush Scarab of Discernment": {
   name: "Ambush Scarab of Discernment",
   regex: "kee",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYlN0cm9uZ2JveGVzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/74111052f4/AltTier4ScarabStrongboxes.png",
   description: "Strongboxes in Area are more likely to be rarer varieties",
   flavourText: "A keen eye sorts the worthy from the chaff.",
 },
 "Ambush Scarab of Hidden Compartments": {
   name: "Ambush Scarab of Hidden Compartments",
   regex: "bv",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJTdHJvbmdib3hlcyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/46451362bc/NormalScarabStrongboxes.png",
   description: "15% Chance for Strongboxes in Area to be openable again",
   flavourText: "Beneath the obvious lies the real treasure...",
 },
 "Ambush Scarab of Potency": {
   name: "Ambush Scarab of Potency",
   regex: "sm",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiU3Ryb25nYm94ZXMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/8b46f6270a/GreaterScarabStrongboxes.png",
   description: "75% Increased effect of Explicit Modifiers on Strongboxes in Area",
   flavourText: "The locksmith dreams of building a better box.",
 },
 "Anarchy Scarab": {
   name: "Anarchy Scarab",
   regex: "it'",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJBbmFyY2h5IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/89174f2e33/LesserScarabAnarchy.png",
   description: "Area contains (2-4) additional Rogue Exiles",
   flavourText: "On Wraeclast, it's kill or be killed.",
 },
 "Anarchy Scarab of Gigantification": {
   name: "Anarchy Scarab of Gigantification",
   regex: "wt",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJBbmFyY2h5IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/4165090d5d/NormalScarabAnarchy.png",
   description: "Wild Rogue Exiles in Area have a 30% chance to be replaced with a Rogue Giant",
   flavourText: "The consumption of sulphite can cause strange growth.",
 },
 "Anarchy Scarab of Partnership": {
   name: "Anarchy Scarab of Partnership",
   regex: "tn",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQW5hcmNoeSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/08125ce822/GreaterScarabAnarchy.png",
   description: "Wild Rogue Exiles in Area have a 50% chance to appear in pairs",
   flavourText: "Among exiles, alliances shift and change constantly.",
 },
 "Bestiary Scarab": {
   name: "Bestiary Scarab",
   regex: "stm",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJCZWFzdHMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/ea23afd0ff/LesserScarabBeasts.png",
   description: "Area contains Einhar",
   flavourText: "The Beastmaster answers the call.",
 },
 "Bestiary Scarab of Duplicating": {
   name: "Bestiary Scarab of Duplicating",
   regex: "at'",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQmVhc3RzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/7225b9e62b/GreaterScarabBeasts.png",
   description: "Create a copy of Beasts captured in Area",
   flavourText: "What's better than one captured beast, exile?",
 },
 "Bestiary Scarab of the Herd": {
   name: "Bestiary Scarab of the Herd",
   regex: "ram",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJCZWFzdHMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/0f4dc83dc5/NormalScarabBeasts.png",
   description: "Area contains (3-5) additional Red Beasts if it contains Einhar",
   flavourText: "The First Ones ran rampant and free in the forests of old.",
 },
 "Betrayal Scarab": {
   name: "Betrayal Scarab",
   regex: "pay",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJCZXRyYXlhbCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/26d3ff70f1/LesserScarabBetrayal.png",
   description: "Area contains Jun",
   flavourText: "The Syndicate must pay for what they have done.",
 },
 "Betrayal Scarab of Intelligence": {
   name: "Betrayal Scarab of Intelligence",
   regex: "^tho",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJCZXRyYXlhbCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/01b158342c/NormalScarabBetrayal.png",
   description: "150% Increased Intelligence gained from Immortal Syndicate targets encountered in Area",
   flavourText: "Those that can never die fear only pain.",
 },
 "Betrayal Scarab of Reinforcements": {
   name: "Betrayal Scarab of Reinforcements",
   regex: "mov",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQmV0cmF5YWwiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/85f51a19b8/GreaterScarabBetrayal.png",
   description: "Immortal Syndicate Members in Area have 50% increased chance to be accompanied by reinforcements",
   flavourText: "Move and countermove.",
 },
 "Beyond Scarab": {
   name: "Beyond Scarab",
   regex: "wal",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJCZXlvbmQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/ff67da07b4/LesserScarabBeyond.png",
   description: "Slaying enemies close together in Area can attract monsters from Beyond this realm",
   flavourText: "The silent wall and the raging storm oppose the endless swarm.",
 },
 "Beyond Scarab of Haemophilia": {
   name: "Beyond Scarab of Haemophilia",
   regex: "beg",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJCZXlvbmQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/66114ee2e9/NormalScarabBeyond.png",
   description: "Beyond Portals in Area have 30% increased Merging Radius",
   flavourText: "Reality itself begins to bleed.",
 },
 "Beyond Scarab of Resurgence": {
   name: "Beyond Scarab of Resurgence",
   regex: "ung",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRHcmVhdGVyU2NhcmFiQmV5b25kIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/d007f87e7b/AltGreaterScarabBeyond.png",
   description: "Beyond Bosses in Area area are enraged on low life Beyond Bosses in Area drop 100% increased Tainted Currency Beyond Portals in Area have 30% increased chance to spawn a Unique Boss Beyond Bosses in Area are enraged on low life Beyond Bosses in Area drop 100% increased Tainted Currency Beyond Portals in Area have 30% increased chance to spawn a Unique Boss",
   flavourText: "They hunger.",
 },
 "Beyond Scarab of the Invasion": {
   name: "Beyond Scarab of the Invasion",
   regex: "lmo",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkJleW9uZCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/f3d0278af1/Tier4ScarabBeyond.png",
   description: "Rare and Unique Monsters slain in Area create 8 to 12 additional Beyond Portals",
   flavourText: "The fated hour is almost nigh.",
 },
 "Blight Scarab": {
   name: "Blight Scarab",
   regex: "ndr",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJCbGlnaHQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/c35814598b/LesserScarabBlight.png",
   description: "Area contains a Blight Encounter",
   flavourText: "The tendrils creep and grow...",
 },
 "Blight Scarab of Blooming": {
   name: "Blight Scarab of Blooming",
   regex: "ntu",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkJsaWdodCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/6dde3a6e88/Tier4ScarabBlight.png",
   description: "Blight Encounters in Area have up to 3 additional Unique Bosses Unique enemies in Blight Encounters have 100% increased life Tier 14+ Blighted Maps found in Area drop as Blight-Ravaged Maps instead",
   flavourText: "Once a century, the Blight spills forth...",
 },
 "Blight Scarab of Invigoration": {
   name: "Blight Scarab of Invigoration",
   regex: "pol",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYkJsaWdodCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/e74a0932bd/AltTier4ScarabBlight.png",
   description: "Each Empowering Tower in Areas grants Blighted Monsters in range increased Difficulty and Reward",
   flavourText: "Reversing the polarity of the compression modulator seems to have intriguing effects...",
 },
 "Blight Scarab of the Blightheart": {
   name: "Blight Scarab of the Blightheart",
   regex: "yc",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQmxpZ2h0IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/cf36f28d45/GreaterScarabBlight.png",
   description: "Blight Encounters in Area have one Blighted Chest Blight Encounters in Area spawn many more waves of Enemies Blighted Chests in Area grow larger and more rewarding the more Blighted Enemies are slain",
   flavourText: "The core grows larger with each cycle, doomed to spill forth...",
 },
 "Breach Scarab": {
   name: "Breach Scarab",
   regex: "er fa",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJCcmVhY2giLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/37bd40da6c/LesserScarabBreach.png",
   description: "Area contains an additional Breach Area contains 2 additional Breaches",
   flavourText: "They are never far.",
 },
 "Breach Scarab of Lordship": {
   name: "Breach Scarab of Lordship",
   regex: "gd",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiQnJlYWNoIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/6c012fae5c/GreaterScarabBreach.png",
   description: "Breaches in Area each contain a Breachlord",
   flavourText: "Their kingdoms always seek expansion.",
 },
 "Breach Scarab of Resonant Cascade": {
   name: "Breach Scarab of Resonant Cascade",
   regex: "eak",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYkJyZWFjaCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/aa58715219/AltTier4ScarabBreach.png",
   description: "Breaches in Areas open and close 10% faster for each Breach already opened Monsters from Breaches in Areas have increased Difficulty and Reward for each Breach already opened",
   flavourText: "Reality weakens with each new wound.",
 },
 "Breach Scarab of Snares": {
   name: "Breach Scarab of Snares",
   regex: "tep",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkJyZWFjaCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/0b375322d7/Tier4ScarabBreach.png",
   description: "Breaches in Area have 5 to 10 additional Clasped Hands Clasped Hands in Area are guarded by a rare Breach Monster",
   flavourText: "Care where you step.",
 },
 "Breach Scarab of Splintering": {
   name: "Breach Scarab of Splintering",
   regex: "^bi",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRHcmVhdGVyU2NhcmFiQnJlYWNoIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/e324a54cdc/AltGreaterScarabBreach.png",
   description: "Breach Monsters in Area drop 50% more Breach Splinters Clasped Hands in Area drop 50% more Breach Splinters",
   flavourText: "Bit by bit.",
 },
 "Cartography Scarab of Corruption": {
   name: "Cartography Scarab of Corruption",
   regex: "tw",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiTWFwcyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/2567494a92/GreaterScarabMaps.png",
   description: "Non-Unique Maps found in Area are Corrupted with 8 Modifiers",
   flavourText: "Corruption bleeds between realities.",
 },
 "Cartography Scarab of Escalation": {
   name: "Cartography Scarab of Escalation",
   regex: "^op",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJNYXBzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/c982c28d52/LesserScarabMaps.png",
   description: "10% increased Maps found in Area for each Map Modifier affecting Area",
   flavourText: "Opportunity thrives where dreams are most perilous.",
 },
 "Cartography Scarab of Risk": {
   name: "Cartography Scarab of Risk",
   regex: "efl",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJNYXBzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/0baaf413d0/NormalScarabMaps.png",
   description: "Area has an additional random Modifier",
   flavourText: "What we see in the mirror can only be our own reflection.",
 },
 "Cartography Scarab of Singularity": {
   name: "Cartography Scarab of Singularity",
   regex: "ngu",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHROb3JtYWxTY2FyYWJNYXBzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/95a7a8097c/AltNormalScarabMaps.png",
   description: "A Unique Map will drop from the Final Map Boss",
   flavourText: "Some regions of the Atlas never change.",
 },
 "Cartography Scarab of the Multitude": {
   name: "Cartography Scarab of the Multitude",
   regex: "izo",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHROb3JtYWxTY2FyYWJNYXBzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/95a7a8097c/AltNormalScarabMaps.png",
   description: "Area contains (4-8) to (6-12) additional packs of Difficult and Rewarding Monsters which drop 300% increased Maps",
   flavourText: "There is no end, no horizon.",
 },
 "Delirium Scarab": {
   name: "Delirium Scarab",
   regex: "uo",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJEZWxpcml1bSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/ccb0e23ff2/LesserScarabDelirium.png",
   description: "Area contains a Mirror of Delirium",
   flavourText: "A perfectly innocuous Scarab that does nothing at all.",
 },
 "Delirium Scarab of Delusions": {
   name: "Delirium Scarab of Delusions",
   regex: "lk",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkRlbGlyaXVtIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/6416ee8493/Tier4ScarabDelirium.png",
   description: "Maps found in Area have layers of Delirium",
   flavourText: "What are you talking about? The Atlas was always like this.",
 },
 "Delirium Scarab of Mania": {
   name: "Delirium Scarab of Mania",
   regex: "y'",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJEZWxpcml1bSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/9f8ae4cdb7/NormalScarabDelirium.png",
   description: "Delirium Reward Meters fill 100% faster in Area Delirium in Area increases 50% faster with distance from the mirror",
   flavourText: "They're so loud! Why are they so loud?! All you can do is scream!",
 },
 "Delirium Scarab of Neuroses": {
   name: "Delirium Scarab of Neuroses",
   regex: "eu",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRHcmVhdGVyU2NhcmFiRGVsaXJpdW0iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/e311aea0b6/AltGreaterScarabDelirium.png",
   description: "Delirium Encounters in Area contain all Unique Delirium Bosses Can only be used with Tier 11+ Maps",
   flavourText: "Toothy grins shine in the dark.",
 },
 "Delirium Scarab of Paranoia": {
   name: "Delirium Scarab of Paranoia",
   regex: "rue",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRGVsaXJpdW0iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/c723c51ba6/GreaterScarabDelirium.png",
   description: "Delirium Encounters in Area generate 2 additional Reward types",
   flavourText: "If it seems too good to be true...",
 },
 "Divination Scarab of Pilfering": {
   name: "Divination Scarab of Pilfering",
   regex: "sei",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRGl2aW5hdGlvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/8101d1a6e1/GreaterScarabDivination.png",
   description: "Divination Cards which drop in Area are stolen by a Final Map Boss That Final Map Boss becomes more Difficult and deals increased Damage the more Divination Cards they have stolen That Final Map Boss duplicates all stolen Divination Cards when defeated",
   flavourText: "If you do not seize your fate, someone else will.",
 },
 "Divination Scarab of Plenty": {
   name: "Divination Scarab of Plenty",
   regex: "usa",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJEaXZpbmF0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/7fb7abf05a/NormalScarabDivination.png",
   description: "Area contains 3 additional Packs of Magic Monsters that have 800% increased chance to drop Divination Cards Up to 3 Packs of Magic Monsters in Area drop additional Divination Cards Up to 3 Packs of Magic Monsters in Area have 800% increased chance to drop Divination Cards",
   flavourText: "We create a thousand futures with every action.",
 },
 "Divination Scarab of The Cloister": {
   name: "Divination Scarab of The Cloister",
   regex: "loi",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJEaXZpbmF0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/ac84db8246/LesserScarabDivination.png",
   description: "Area contains 8 to 12 additional packs of Doedre's Devoted Doedre's Devoted have 1% additional chance to drop a Stacked Deck",
   flavourText: "No disease can be contained forever.",
 },
 "Domination Scarab": {
   name: "Domination Scarab",
   regex: "xc",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJEb21pbmF0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/56e13db37d/LesserScarabDomination.png",
   description: "Area contains 2 additional Shrines",
   flavourText: "A simple exchange. Blood for power.",
 },
 "Domination Scarab of Apparitions": {
   name: "Domination Scarab of Apparitions",
   regex: "adn",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJEb21pbmF0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/9ef9fd8595/NormalScarabDomination.png",
   description: "Area contains an additional Apparition Shrine Area contains 2 additional Apparition Shrines",
   flavourText: "Some paths to power lead only to madness.",
 },
 "Domination Scarab of Evolution": {
   name: "Domination Scarab of Evolution",
   regex: "rif",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRG9taW5hdGlvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/88bb71ff95/GreaterScarabDomination.png",
   description: "Area contains an additional Evolving Shrine",
   flavourText: "The Atlas and Wraeclast share one terrifying secret.",
 },
 "Domination Scarab of Terrors": {
   name: "Domination Scarab of Terrors",
   regex: "tev",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkRvbWluYXRpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/2e56a6e5dc/Tier4ScarabDomination.png",
   description: "Shrines in Area are guarded by an Atlas Boss Modifiers to the Final Map Boss also apply to these Atlas Bosses",
   flavourText: "Whatever the prize, fight for it.",
 },
 "Essence Scarab": {
   name: "Essence Scarab",
   regex: "^it",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJFc3NlbmNlIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/c9f153a8cf/LesserScarabEssence.png",
   description: "Area contains an additional Imprisoned Monster Area contains 2 additional Imprisoned Monsters",
   flavourText: "It is perfectly safe, the creatures trapped inside couldn't possibly still be living.",
 },
 "Essence Scarab of Adaptation": {
   name: "Essence Scarab of Adaptation",
   regex: "tti",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYkVzc2VuY2UiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/8dbe247549/AltTier4ScarabEssence.png",
   description: "Imprisoned Monsters released in Areas grant a random Essence Modifier to another Imprisoned Monster in the Area Imprisoned Monsters in Areas have increased Difficulty and Reward for each Essence Modifier",
   flavourText: "The crystalline lattice expands in directions alien to the mortal mind.",
 },
 "Essence Scarab of Ascent": {
   name: "Essence Scarab of Ascent",
   regex: "cet",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJFc3NlbmNlIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/3f918a4115/NormalScarabEssence.png",
   description: "Essences found in Area are a tier higher",
   flavourText: "A more perfect facet cannot be achieved.",
 },
 "Essence Scarab of Calcification": {
   name: "Essence Scarab of Calcification",
   regex: "lc",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkVzc2VuY2UiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/c12e415ec2/Tier4ScarabEssence.png",
   description: "Rare monsters that are natural inhabitants of the Area are imprisoned by Essences",
   flavourText: "The crystals... can spread?",
 },
 "Essence Scarab of Stability": {
   name: "Essence Scarab of Stability",
   regex: "ool",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRXNzZW5jZSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/f476efd29d/GreaterScarabEssence.png",
   description: "Using a Remnant of Corruption in Area can only result in upgrading or transforming Essences",
   flavourText: "With the right tools and a precise hand, the manipulation of the crystals can be made predictable.",
 },
 "Expedition Scarab": {
   name: "Expedition Scarab",
   regex: "cro",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJFeHBlZGl0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/e9bb9fa480/LesserScarabExpedition.png",
   description: "Area contains an Expedition Encounter",
   flavourText: "They come from across the sea in search of ancient truth.",
 },
 "Expedition Scarab of Archaeology": {
   name: "Expedition Scarab of Archaeology",
   regex: "sd",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkV4cGVkaXRpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/b4dd63608b/Tier4ScarabExpedition.png",
   description: "Remnants in Expedition Encounters in Area have 2 additional Suffixes and Prefixes",
   flavourText: "Curiosity leads to wisdom. Wisdom leads to power.",
 },
 "Expedition Scarab of Runefinding": {
   name: "Expedition Scarab of Runefinding",
   regex: "urn",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJFeHBlZGl0aW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/a1f5997c7d/NormalScarabExpedition.png",
   description: "Expedition Encounters in Area have 100% increased number of Runic Monster Markers",
   flavourText: "That which came from the stars longs to return.",
 },
 "Expedition Scarab of Verisium Powder": {
   name: "Expedition Scarab of Verisium Powder",
   regex: "rl",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRXhwZWRpdGlvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/19a2dff87d/GreaterScarabExpedition.png",
   description: "Expedition Encounters in Area have 20% increased number of Explosives 80% increased Explosive Radius",
   flavourText: "A properly refined pinch makes all the difference.",
 },
 "Harbinger Scarab": {
   name: "Harbinger Scarab",
   regex: "oop",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJIYXJiaW5nZXJzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/1940157b44/LesserScarabHarbingers.png",
   description: "Area contains 1 additional Harbinger Area contains 3 additional Harbingers",
   flavourText: "Their troops came in numbers uncountable and from lands unknown.",
 },
 "Harbinger Scarab of Obelisks": {
   name: "Harbinger Scarab of Obelisks",
   regex: "ks$",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJIYXJiaW5nZXJzIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/640ad00ede/NormalScarabHarbingers.png",
   description: "Harbingers in Area drop a single type of currency shard Harbingers in Area spawn Empowered Obelisks Empowered Obelisks drop Currency Shards when defeated",
   flavourText: "They seek to establish an unknown pattern.",
 },
 "Harbinger Scarab of Regency": {
   name: "Harbinger Scarab of Regency",
   regex: "nob",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiSGFyYmluZ2VycyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/ce393cfd58/GreaterScarabHarbingers.png",
   description: "Harbingers in Area have a 50% chance to be replaced by a powerful Harbinger Boss",
   flavourText: "The truly noble wage war from the front.",
 },
 "Harbinger Scarab of Warhoards": {
   name: "Harbinger Scarab of Warhoards",
   regex: "rh",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkhhcmJpbmdlcnMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/81caefbf3f/Tier4ScarabHarbingers.png",
   description: "Currency shards dropped by Harbingers in Area have a 25% chance to be duplicated Currency shards dropped by Harbingers in Area are duplicated",
   flavourText: "For those who can leverage it, war can be as much a machine of profit as it is death.",
 },
 "Harvest Scarab": {
   name: "Harvest Scarab",
   regex: "^hi",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJIYXJ2ZXN0IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/0951fed47e/LesserScarabHarvest.png",
   description: "Area contains the Sacred Grove",
   flavourText: "Hidden routes lead to vales of shadow and light...",
 },
 "Harvest Scarab of Cornucopia": {
   name: "Harvest Scarab of Cornucopia",
   regex: "ler",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkhhcnZlc3QiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/a32654b789/Tier4ScarabHarvest.png",
   description: "If Area contains the Sacred Grove, it will contain up to 1 additional Tier 4 seed of each type, if possible",
   flavourText: "The taller the tree, the better the fruit.",
 },
 "Harvest Scarab of Doubling": {
   name: "Harvest Scarab of Doubling",
   regex: "ub",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiSGFydmVzdCIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/67ebfc1b1f/GreaterScarabHarvest.png",
   description: "Lifeforce dropped by Harvest Monsters in Area is duplicated Harvest Monsters in Area have 100% more Life",
   flavourText: "A good season brings bounty.",
 },
 "Horned Scarab of Awakening": {
   name: "Horned Scarab of Awakening",
   regex: "nyt",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/10856e6528/SuperScarab4.png",
   description: "You can select one Imbued crafting option on the Map Device",
   flavourText: "The Atlas can be anything you want... and everything you want.",
 },
 "Horned Scarab of Bloodlines": {
   name: "Horned Scarab of Bloodlines",
   regex: "rp",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjEiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/8f232821fa/SuperScarab1.png",
   description: "Area has 150% increased Magic Monsters",
   flavourText: "Only the hardiest survive. Only survivors perpetuate.",
 },
 "Horned Scarab of Glittering": {
   name: "Horned Scarab of Glittering",
   regex: "lth",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjYiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/0f282b3a1e/SuperScarab6.png",
   description: "Players in Area gain increased Item Rarity and Item Quantity for each Monster slain recently, up to 200% and 100% respectively",
   flavourText: "Wealth flows to those that shine brightest.",
 },
 "Horned Scarab of Nemeses": {
   name: "Horned Scarab of Nemeses",
   regex: "^gl",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjIiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/f4c8c526ba/SuperScarab2.png",
   description: "Rare Monsters in Area have 2 additional Modifiers",
   flavourText: "Glory draws ever tighter its own doom.",
 },
 "Horned Scarab of Pandemonium": {
   name: "Horned Scarab of Pandemonium",
   regex: "^pu",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjciLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/dd523e523b/SuperScarab7.png",
   description: "Monster packs in Area have a 15% chance to be replaced by a random Atlas Boss Modifiers to the Final Map Boss also apply to these Atlas Bosses",
   flavourText: "Punctured dreams bleed into the mind.",
 },
 "Horned Scarab of Preservation": {
   name: "Horned Scarab of Preservation",
   regex: "rva",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjMiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/ce4ee43474/SuperScarab3.png",
   description: "Other Scarabs are not consumed on use",
   flavourText: "We must not forget those who came before.",
 },
 "Horned Scarab of Tradition": {
   name: "Horned Scarab of Tradition",
   regex: "ges",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9TdXBlclNjYXJhYjUiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/c96bd21955/SuperScarab5.png",
   description: "All Rare and Unique Monsters in Area have dropped items transformed by a Reward Modifier",
   flavourText: "To the strongest, prosperity be thine.",
 },
 "Incursion Scarab": {
   name: "Incursion Scarab",
   regex: "aa",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJJbmN1cnNpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/44cc3c3c34/LesserScarabIncursion.png",
   description: "Area contains Alva",
   flavourText: "Uncover the secrets of the Vaal one incursion at a time.",
 },
 "Incursion Scarab of Champions": {
   name: "Incursion Scarab of Champions",
   regex: "tz",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiSW5jdXJzaW9uIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/f6b60e9be1/GreaterScarabIncursion.png",
   description: "Incursions in Area have a 35% chance for all Monsters to be at least Magic Incursions in Area have a 35% chance for all Monsters to be at least Magic Incursions in Area have 15% increased Pack Size",
   flavourText: "Only the best served in Atzoatl.",
 },
 "Incursion Scarab of Invasion": {
   name: "Incursion Scarab of Invasion",
   regex: "gat",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJJbmN1cnNpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/992c5f7548/NormalScarabIncursion.png",
   description: "Area contains (3-12) to (5-16) additional packs of Incursion Monsters Area contains (3-12)-(5-16) additional packs of Incursion Monsters",
   flavourText: "A gate, once opened, may swing in either direction.",
 },
 "Incursion Scarab of Timelines": {
   name: "Incursion Scarab of Timelines",
   regex: "h,",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkluY3Vyc2lvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/c651660186/Tier4ScarabIncursion.png",
   description: "Final Architect slain in Area will drop an Itemised Temple  Itemised Temples dropped in Area are generated based on current Temple layout, but with randomised room tiers",
   flavourText: "They say if you go far enough, you will meet yourself...",
 },
 "Influencing Scarab of Conversion": {
   name: "Influencing Scarab of Conversion",
   regex: "^me",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYlNoYXBlciIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/1c759412df/Tier4ScarabShaper.png",
   description: "Tier 14+ Maps found in Area have a chance to drop as Shaper Guardian, Elder Guardian, Conqueror or Unique Synthesised Maps instead",
   flavourText: "Memories resound in the Atlas, echoing forever.",
 },
 "Influencing Scarab of Hordes": {
   name: "Influencing Scarab of Hordes",
   regex: "^id",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiRWxkZXIiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/2bd3ed1eca/GreaterScarabElder.png",
   description: "Influenced Monster Packs in Area have 40% increased Pack Size",
   flavourText: "Ideas cannot be contained.",
 },
 "Influencing Scarab of the Elder": {
   name: "Influencing Scarab of the Elder",
   regex: "voi",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJFbGRlciIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/11cabe4d12/LesserScarabElder.png",
   description: "Adds Elder Influence outcome to Area",
   flavourText: "Beyond the light lies only the void.",
 },
 "Influencing Scarab of the Shaper": {
   name: "Influencing Scarab of the Shaper",
   regex: "awai",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJTaGFwZXIiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/9e83e315c9/LesserScarabShaper.png",
   description: "Adds Shaper Influence outcome to Area",
   flavourText: "A sea of stars awaits those who dare imagine.",
 },
 "Legion Scarab": {
   name: "Legion Scarab",
   regex: "arr",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJMZWdpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/0006525c3c/LesserScarabLegion.png",
   description: "Area contains an additional Legion Encounter",
   flavourText: "What good is peace to a warrior?",
 },
 "Legion Scarab of Command": {
   name: "Legion Scarab of Command",
   regex: "nak",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHROb3JtYWxTY2FyYWJMZWdpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/8c2976d5a7/AltNormalScarabLegion.png",
   description: "Legion Factions in Area are accompanied by a General",
   flavourText: "Cut off the head and the snake will perish.",
 },
 "Legion Scarab of Eternal Conflict": {
   name: "Legion Scarab of Eternal Conflict",
   regex: "rni",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYkxlZ2lvbiIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/05b623a2a7/Tier4ScarabLegion.png",
   description: "Legion Monsters in Area can be broken out of Stasis multiple times Legion Monsters in Area gain increased Difficulty and Reward for each time they are broken out",
   flavourText: "An eternity of blood. Glorious.",
 },
 "Legion Scarab of Officers": {
   name: "Legion Scarab of Officers",
   regex: "^ev",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJMZWdpb24iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/0edb9242f1/NormalScarabLegion.png",
   description: "Legion Factions in Area have (2-5) additional Sergeants",
   flavourText: "Even a battlefield has middle management.",
 },
 "Ritual Scarab of Abundance": {
   name: "Ritual Scarab of Abundance",
   regex: "^he",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiUml0dWFsIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/2e76a61fb9/GreaterScarabRitual.png",
   description: "Rituals in Area offer 100% increased Favours",
   flavourText: "He draws ever closer...",
 },
 "Ritual Scarab of Selectiveness": {
   name: "Ritual Scarab of Selectiveness",
   regex: "^pi",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJSaXR1YWwiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/744a25b9f9/LesserScarabRitual.png",
   description: "Rerolling Favours at Ritual Altars in Area has no cost the first 2 times Ritual Altars in Area allow rerolling Favours 1 additional time",
   flavourText: "Pick and choose which sacred tenets you prefer...",
 },
 "Ritual Scarab of Wisps": {
   name: "Ritual Scarab of Wisps",
   regex: "^tr",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJSaXR1YWwiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/45dba85b5a/NormalScarabRitual.png",
   description: "Ritual Altars in Area spawn a Wildwood Wisp Wildwood Wisps grant (50-70)% increased Tribute Gained to nearby Players",
   flavourText: "Trust in the Draíocht, and you shall never be alone.",
 },
 "Scarab of Adversaries": {
   name: "Scarab of Adversaries",
   regex: "dv",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRMZXNzZXJTY2FyYWJNaXNjIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/b51199868c/AltLesserScarabMisc.png",
   description: "Area contains (2-4) additional Packs with Mirrored Rare Monsters",
   flavourText: "They stand against you with equal enmity.",
 },
 "Scarab of Bisection": {
   name: "Scarab of Bisection",
   regex: "f,",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiTWlzYzIiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/b0c70fb6c8/GreaterScarabMisc2.png",
   description: "You can select the Nullify Prefixes or Nullify Suffixes crafting options on the Map Device",
   flavourText: "When something is cut in half, which half is the original?",
 },
 "Scarab of Divinity": {
   name: "Scarab of Divinity",
   regex: "^fa",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJNaXNjIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/a4e827f718/NormalScarabMisc.png",
   description: "Up to 1 additional Rare Monster in Area will be Pantheon-Touched Apparitions spawned by Pantheon-Touched Monsters deal 100% increased damage Up to 2 additional Rare Monsters in Area will be Pantheon-Touched Apparitions spawned by Pantheon-Touched Monsters deal 100% increased damage",
   flavourText: "Faith transforms.",
 },
 "Scarab of Hunted Traitors": {
   name: "Scarab of Hunted Traitors",
   regex: "unf",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiTWlzYyIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/dfed2f59a3/GreaterScarabMisc.png",
   description: "Area contains Hunted Traitors",
   flavourText: "Nothing is as unforgiveable.",
 },
 "Scarab of Monstrous Lineage": {
   name: "Scarab of Monstrous Lineage",
   regex: "eag",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJNaXNjIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/d89cac7adf/LesserScarabMisc.png",
   description: "(25-40)% increased Magic Pack Size",
   flavourText: "The bloodlines evolve faster than they can be culled.",
 },
 "Scarab of Radiant Storms": {
   name: "Scarab of Radiant Storms",
   regex: "cac",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYk1pc2MyIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/a1a6f9261c/Tier4ScarabMisc2.png",
   description: "Area contains a Resplendent Tempest",
   flavourText: "The great cacophony of life roars to a crescendo.",
 },
 "Scarab of Stability": {
   name: "Scarab of Stability",
   regex: "g!",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYk1pc2MiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/42e3a0fccd/Tier4ScarabMisc.png",
   description: "Portals to Area have a 50% chance to not be consumed on use",
   flavourText: "Astonishing! A means to sustain the connection...",
 },
 "Scarab of Wisps": {
   name: "Scarab of Wisps",
   regex: "live",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiTWlzYzEiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/b96cb43b23/GreaterScarabMisc1.png",
   description: "Monsters in Area have a chance to be Empowered by 2000 Wildwood Wisps",
   flavourText: "The Draíocht connects everything that lives.",
 },
 "Sulphite Scarab": {
   name: "Sulphite Scarab",
   regex: "elv",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJTdWxwaGl0ZSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/1e18c30014/LesserScarabSulphite.png",
   description: "Area contains Niko Map owner gains 150% more Sulphite",
   flavourText: "Delve into darkness in search of the truth...",
 },
 "Sulphite Scarab of Fumes": {
   name: "Sulphite Scarab of Fumes",
   regex: "k,",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiU3VscGhpdGUiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/2a3855535e/GreaterScarabSulphite.png",
   description: "Sulphite found in Map Area releases Enraging Fumes Monsters affected by Enraging Fumes have 50% increased Item Quantity Sulphite in your maps is guarded by Monsters from the Azurite Mine",
   flavourText: "When the land itself is sick, even the very ground rots and spills forth.",
 },
 "Sulphite Scarab of Greed": {
   name: "Sulphite Scarab of Greed",
   regex: "'e",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJTdWxwaGl0ZSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/3b6d388b91/NormalScarabSulphite.png",
   description: "Map owner gains 150% more Sulphite",
   flavourText: "There is no such thing as 'enough'.",
 },
 "Titanic Scarab": {
   name: "Titanic Scarab",
   regex: "nam",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJVbmlxdWUiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/72516960f1/LesserScarabUnique.png",
   description: "Unique Monsters have 2% increased Toughness, Damage, Rarity and Quantity of items dropped per 1% increased Pack Size of Area",
   flavourText: "What power lies in a name?",
 },
 "Titanic Scarab of Legend": {
   name: "Titanic Scarab of Legend",
   regex: "ccu",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiVW5pcXVlIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/6ec6815d00/GreaterScarabUnique.png",
   description: "Unique Monsters in Area have 2 additional Monster Modifiers",
   flavourText: "Power accumulates.",
 },
 "Titanic Scarab of Treasures": {
   name: "Titanic Scarab of Treasures",
   regex: "^gr",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJVbmlxdWUiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/774d92ac8e/NormalScarabUnique.png",
   description: "Unique Monsters in Area have 2 additional Rewards Unique Monsters in Area have 30% increased Toughness",
   flavourText: "Greed is a trap of the mind.",
 },
 "Torment Scarab": {
   name: "Torment Scarab",
   regex: "rim",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJUb3JtZW50IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/b4e843a49b/LesserScarabTorment.png",
   description: "Area is haunted by (2-3) additional Tormented Spirits Tormented Spirits in Area have a 25% chance to be set free when Possessed Monsters are slain",
   flavourText: "Their crimes in life haunt you in death.",
 },
 "Torment Scarab of Peculiarity": {
   name: "Torment Scarab of Peculiarity",
   regex: "e!",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJUb3JtZW50IiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/b93daed118/NormalScarabTorment.png",
   description: "Tormented Spirits in Area are replaced with unusual variants",
   flavourText: "Now there's a blood-curdling wail I haven't heard before!",
 },
 "Torment Scarab of Possession": {
   name: "Torment Scarab of Possession",
   regex: "y al",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYlRvcm1lbnQiLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/b809b07f2b/Tier4ScarabTorment.png",
   description: "Rare Monsters in Area have a quarter chance to be Possessed by up to 1 Tormented Spirit",
   flavourText: "The strong are never truly alone.",
 },
 "Ultimatum Scarab": {
   name: "Ultimatum Scarab",
   regex: "eng",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9MZXNzZXJTY2FyYWJVbHRpbWF0dW0iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/e0bf2875db/LesserScarabUltimatum.png",
   description: "Area contains an Ultimatum Encounter",
   flavourText: "A challenge may be requested at any time.",
 },
 "Ultimatum Scarab of Bribing": {
   name: "Ultimatum Scarab of Bribing",
   regex: "dg",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9Ob3JtYWxTY2FyYWJVbHRpbWF0dW0iLCJ3IjoxLCJoIjoxLCJzY2FsZSI6MX1d/f7845be27d/NormalScarabUltimatum.png",
   description: "Ultimatum Monsters grant 150% increased Experience Ultimatum Encounters grant rewards as though you completed 2 additional Rounds",
   flavourText: "A trial is only as incorruptible as its judge.",
 },
 "Ultimatum Scarab of Catalysing": {
   name: "Ultimatum Scarab of Catalysing",
   regex: "egr",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9UaWVyNFNjYXJhYlVsdGltYXR1bSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/67b8b8b39c/Tier4ScarabUltimatum.png",
   description: "Ultimatum Encounters in Area will only offer Catalysts as Rewards to the Map Owner",
   flavourText: "Most regret finding out how the powder is made.",
 },
 "Ultimatum Scarab of Dueling": {
   name: "Ultimatum Scarab of Dueling",
   regex: "^v",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9HcmVhdGVyU2NhcmFiVWx0aW1hdHVtIiwidyI6MSwiaCI6MSwic2NhbGUiOjF9XQ/42d067c281/GreaterScarabUltimatum.png",
   description: "Ultimatum Encounters in Area will always lead to a Unique Boss if possible",
   flavourText: "Victory is meaningless, failure will be mocked.",
 },
 "Ultimatum Scarab of Inscription": {
   name: "Ultimatum Scarab of Inscription",
   regex: "ffo",
   icon: "https://web.poecdn.com/gen/image/WzI1LDE0LHsiZiI6IjJESXRlbXMvQ3VycmVuY3kvU2NhcmFicy9BbHRUaWVyNFNjYXJhYlVsdGltYXR1bSIsInciOjEsImgiOjEsInNjYWxlIjoxfV0/6c6731cf8d/AltTier4ScarabUltimatum.png",
   description: "Ultimatum Encounter rewards in Area offering Catalysts will offer Inscribed Ultimatums to the Map Owner instead",
   flavourText: "There is always a wager to be made... if you can afford it.",
 },
};